import {ActionBuilder} from "co-redux-builders";

export default ActionBuilder.build({
	sendFormResultToResolve: (id: string) => ({id}),
	clearFormResult: () => ({}),
	setNotificationId: (id: string) => ({id}),
	clearNotificationId: () => ({}),
	dispatchResolveNotification: () => ({}),
	openModal: () => ({}),
	closeModal: () => ({}),
	setObservations: (observations: string) => ({observations}),
	dispatchClearReducer: () => ({})
})