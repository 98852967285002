import Exploitation from "../model/Exploitation";

export function isBaseCompleted(exploitation: Exploitation | undefined): boolean {
    if (exploitation){
        return exploitation.business_type !== undefined && exploitation.zone !== undefined
            && !!exploitation.image && exploitation.head_of_service_user.length !== 0
            && exploitation.latitude !== undefined;
    }
    return false;
}

export function isCharacteristicsCompleted(exploitation: Exploitation | undefined): boolean {
    // No tiene campos obligatorios
    return true;
}

export function areDatesCompleted(exploitation: Exploitation | undefined): boolean {
    if (exploitation) {
        return exploitation.contract_end_date !== null  && exploitation.contract_signing_date !== null
            && exploitation.contract_end_date_alert != null;
    }
    return false;

}

export function isReversionCompleted(exploitation: Exploitation | undefined): boolean {
    // No tiene campos obligatorios
    return true;
}

export function areServicesCompleted(exploitation: Exploitation | undefined): boolean {
    // No tiene campos obligatorios
    return true;
}
