import React, { useState } from "react";
import { usePaginationRange, DOTS } from "../hooks/usePaginationRange";
import Row from "./Row";

const Pagination = ({
                        data,
                        RenderComponent,
                        title,
                        buttonConst,
                        contentPerPage,
                        siblingCount,
                        parentCallback,
                    }) => {
    const [totalPageCount] = useState(Math.ceil(data.length / contentPerPage));
    const [currentPage, setCurrentPage] = useState(1);

    const paginationRange = usePaginationRange({
        totalPageCount,
        contentPerPage,
        buttonConst,
        siblingCount,
        currentPage
    });

    function goToNextPage() {
        setCurrentPage((page) => page + 1);
        parentCallback((page) => page + 1);
    }
    function gotToPreviousPage() {
        setCurrentPage((page) => page - 1);
        parentCallback((page) => page - 1);
    }
    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        parentCallback(pageNumber);
        setCurrentPage(pageNumber);
    }
    const getPaginatedData = () => {
        const startIndex = currentPage * contentPerPage - contentPerPage;
        const endIndex = startIndex + contentPerPage;
        console.log(data);
        return data.slice(startIndex, endIndex);
    };

    return (
        <>
            {/* show the post 10 post at a time*/}
            <Row>
                <div className="dataContainer">
                    {getPaginatedData().map((dataItem, index) => (
                        <RenderComponent key={index} data={dataItem} />
                    ))}
                </div>
            </Row>
            {/* show the pagiantion
                it consists of next and previous buttons
                along with page numbers, in our case, 5 page
                numbers at a time */}
            <nav className={'row footer-pagination'}>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-b--10">
                    {/* previous button */}
                    <ul
                        className={'pagination'}
                    >
                        <li
                            className={` prev ${currentPage === 1 ? "disabled" : ""}`}
                        >
                            <a className="waves-effect" onClick={gotToPreviousPage}>&lt;</a>
                        </li>
                        {/* show paginated button group */}
                        {paginationRange && paginationRange.map((item, index) => {
                            if (item === DOTS) {
                                return (
                                    <li key={index} className={`paginationItem`}>
                                        &#8230;
                                    </li>
                                );
                            }
                            return (
                                <li
                                    key={index}
                                    className={`paginationItem ${
                                        currentPage === item ? "active" : null
                                    }`}
                                >
                                    <a className="waves-effect" onClick={changePage}>{item}</a>
                                </li>
                            );
                        })}
                        {/* next button */}
                        <li
                            className={`next ${currentPage === totalPageCount ? "disabled" : ""}`}
                        >
                            <a className="waves-effect" onClick={goToNextPage}>&gt;</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
};

export default Pagination;