import cookie from "react-cookies";
import Config from "../Config";
import {executeItemTask} from "./FormUtils";
import TaskUserPasswordEdit from "../ws/user/TaskUserPasswordEdit";
import I18nUtils from "../I18n/I18nUtils";
import {TR_CONTRASENA_MODIFICADA_CORRECTAMENTE} from "../I18n/constants";
import TaskResponse from "../ws/TaskResponse";

export default class AuthManager {

    private static COOKIE_NAME = Config.AUTH_COOKIE_APP_NAME;

    private static authToken: string;

    public static initialized() {
        this.authToken = cookie.load(this.COOKIE_NAME);
    }

    public static setAuthToken(authToken: string, rememberMe: boolean = false) {
        this.authToken = authToken;
        const cookieOptions: { path: string, maxAge?: number, domain: string} = {path: '/', domain:'.pavapark.com'};
        if (!rememberMe) {
            // tiempo de vida de 4 horas si no tenemos la opción de recordar
            cookieOptions.maxAge = 60 * 60 * 4;
        }
        cookie.save(this.COOKIE_NAME, authToken, cookieOptions);
    }

    public static getAuthToken() {
        return this.authToken;
    }

    public static login(authToken: string) {
        this.setAuthToken(authToken);
        this.authToken = authToken;
    }

    public static logout() {
        this.login('');
    }

    public static isLogged(): boolean {
        return this.authToken !== null && this.authToken !== undefined && this.authToken !== '';
    }

};

export function changeUserPassword(newPassword: string,
                                   userId?: string,
                                   onSuccess?: (response: TaskResponse<{}>) => void,
                                   token?: string
): void {
    executeItemTask(
        new TaskUserPasswordEdit({
            password: newPassword
        }, userId, token),
        onSuccess,
        I18nUtils.tr(TR_CONTRASENA_MODIFICADA_CORRECTAMENTE),
    )
}