import * as React from 'react';
import {Field, WrappedFieldArrayProps} from "redux-form";
import CardFieldArray from "../../../../components/card/CardFieldArray";
import FormInput from "../../../../components/form/FormInput";
import Col from "../../../../components/Col";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_GRAFICO, TR_PREDET, TR_URL} from "../../../../I18n/constants";
import {DashboardItemData} from "../../../../model/DashboardItem";

enum ItemDashboardFieldArrayFields {
    NAME = 'name',
    URL = 'url',
    DEFAULT = 'default'
}

type Props = WrappedFieldArrayProps<DashboardItemData>;

const colLabelCommonClasses = 'visible-md visible-sm visible-xs position-unset';
const colFieldCommonClasses = {lg: 5, md: 11, sm: 11, className: 'fields-margin-md position-unset'};

export default class ItemDashboardFieldArray extends React.Component<Props> {

    private onChangeDefaultItem = (newDefaultIndex: number): void => {
        const {fields} = this.props;

        fields.getAll().forEach((currentField, index) => {
            const isNewDefault = index === newDefaultIndex;
            if (currentField.default && !isNewDefault) {
                fields.remove(index);
                fields.insert(index, {
                    ...currentField,
                    default: false,
                });
            } else if (isNewDefault && !currentField.default) {
                fields.remove(index);
                fields.insert(index, {
                    ...currentField,
                    default: true,
                });
            }
        });
    };

    public render(): React.ReactNode {
        const {fields, meta} = this.props;

        const currentFields = this.props.fields.getAll();
        const hasDefaultField = !!currentFields && currentFields.find((field) => field.default);

        const fieldsHeader = (
            <>
                <Col sm={5} lg={5}>
                    <h6>{I18nUtils.tr(TR_GRAFICO)}</h6>
                </Col>
                <Col sm={5} lg={5}>
                    <h6>{I18nUtils.tr(TR_URL)}</h6>
                </Col>
                <Col sm={1} lg={1}>
                    <h6>{I18nUtils.tr(TR_PREDET)}</h6>
                </Col>
            </>
        );

        return (
            <CardFieldArray title={''}
                            addHandler={() => {
                                fields.push({
                                    default: !hasDefaultField,
                                    name: '',
                                    url: '',
                                    col: undefined,
                                    order: undefined,
                                })
                            }}
                            fields={fields}
                            meta={meta}
                            fieldsHeader={fieldsHeader}
                            hideRemoveWithOneOption>

                {fields.map((fieldName: string, index: number) => {

                    return (
                        <React.Fragment key={index}>
                            <Col md={1} sm={1} className={colLabelCommonClasses}>
                                <label className={'m-t-5'}>{I18nUtils.tr(TR_GRAFICO)}</label>
                            </Col>
                            <Field
                                name={`${fieldName}.${ItemDashboardFieldArrayFields.NAME}`}
                                component={FormInput}
                                col={colFieldCommonClasses}/>

                            <Col md={1} sm={1} className={colLabelCommonClasses}>
                                <label className={'m-t-5'}>{I18nUtils.tr(TR_URL)}</label>
                            </Col>
                            <Field
                                name={`${fieldName}.${ItemDashboardFieldArrayFields.URL}`}
                                component={FormInput}
                                col={colFieldCommonClasses}
                            />
                            <Col md={1} sm={1} className={colLabelCommonClasses}>
                                <label>{I18nUtils.tr(TR_PREDET)}</label>
                            </Col>
                            <Col lg={1} md={1} sm={1} className={'field-radio-lg'}>
                                <Field
                                    name={ItemDashboardFieldArrayFields.DEFAULT}
                                    type="radio"
                                    component="input"
                                    checked={fields.get(index).default}
                                    onClick={(event) => this.onChangeDefaultItem(index)}
                                />
                            </Col>
                        </React.Fragment>
                    )
                })}
            </CardFieldArray>)
    }
}

