import React, {Component, ReactNode} from "react";
import {compose} from "redux";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import {FieldArrayErrors, formStateAutoMapToPropsFactory} from "../../../../../utils/FormUtils";
import {connect} from "react-redux";
import I18nUtils from "../../../../../I18n/I18nUtils";
import {
    TR_APODERADOS,
    TR_CIF_INCORRECTO,
    TR_COMPANYIA,
    TR_COMPANYIA_CIF,
    TR_COMPANYIA_PORCENTAJE,
    TR_PORCENTAJE_INCORRECTO
} from "../../../../../I18n/constants";
import Row from "../../../../../components/Row";
import {CompanyType, CompanyTypeAutocompleteBuilder} from "../../../../../model/CompanyType";
import FormInput from "../../../../../components/form/FormInput";
import {goToRoute} from "../../../../../utils/Router";
import {ROUTE_ADMIN_EXPLOITATIONS} from "../../../../../routing/Routes";
import FormCardFooter from "../../../../../components/form/FormCardFooter";
import {isNifCompanyValid, isNifNiePersonValid} from "../../../../../utils/StringUtils";
import FormReduxSelect from "../../../../../components/form/FormReduxSelect";
import {AutocompleteOption} from "../../../../../components/form/FormAutocomplete";

const FORM_NAME = "ExploitationCharacteristicsCardFormAdmin";

interface ExploitationCharacteristicsCardFormAdminProps{
    companies: Array<CompanyType>
    initialValues?: Partial<ExploitationCharacteristicsCardFormAdminData>,
    onSubmit: (data: ExploitationCharacteristicsCardFormAdminData) => void,
    loading: boolean
}

export interface ExploitationCharacteristicsCardFormAdminData {
    companyType: AutocompleteOption<CompanyType> [],
    companyPercentage: number | undefined,
    companyCif: string,
    companyProxies: string
}

enum ExploitationCharacteristicsFields {
    COMPANY_TYPE = "companyType",
    COMPANY_PERCENTAGE = "companyPercentage",
    COMPANY_CIF= "companyCif",
    COMPANY_PROXIES = "companyProxies"
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationCharacteristicsCardFormAdminData>(FORM_NAME);

type Props = ExploitationCharacteristicsCardFormAdminProps & typeof mapFormStateToProps & InjectedFormProps<ExploitationCharacteristicsCardFormAdminData>;

class ExploitationCharacteristicsCardFormAdmin extends Component<Props> {
    public render(): ReactNode {
        const {onSubmit, handleSubmit, companies, initialValues, invalid, pristine} = this.props;
        const options = companies.map(company => CompanyTypeAutocompleteBuilder.buildOption(company));

        return (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Field
                            label={I18nUtils.tr(TR_COMPANYIA)}
                            name={ExploitationCharacteristicsFields.COMPANY_TYPE}
                            component={FormReduxSelect}
                            options={options}
                            blankOptionText={' '}
                            col={{md: 6, lg: 6}}
                        />

                        <Field
                            label={I18nUtils.tr(TR_COMPANYIA_PORCENTAJE)}
                            name={ExploitationCharacteristicsFields.COMPANY_PERCENTAGE}
                            component={FormInput}
                            max={100}
                            min={0}
                            type={"number"}
                            col={{md: 6, lg: 6}}
                        />
                    </Row>
                    <Row>
                        <Field
                            label={I18nUtils.tr(TR_COMPANYIA_CIF)}
                            name={ExploitationCharacteristicsFields.COMPANY_CIF}
                            component={FormInput}
                            type={"text"}
                            col={{md: 6, lg: 6}}
                        />

                        <Field
                            label={I18nUtils.tr(TR_APODERADOS)}
                            name={ExploitationCharacteristicsFields.COMPANY_PROXIES}
                            component={FormInput}
                            type={"text"}
                            col={{md: 6, lg: 6}}
                        />
                    </Row>

                    <FormCardFooter
                        invalid={invalid}
                        pristine={pristine}
                        isUpdate={!!initialValues}
                        cancelHandler={() => goToRoute(ROUTE_ADMIN_EXPLOITATIONS)}/>
                </form>
            </>
        );
    }
}

function validate(values: ExploitationCharacteristicsCardFormAdminData): FormErrors<ExploitationCharacteristicsCardFormAdminData, FieldArrayErrors> {
    const errors: FormErrors<ExploitationCharacteristicsCardFormAdminData, FieldArrayErrors> = {};

    if (values.companyCif && (!isNifNiePersonValid(values.companyCif) && !isNifCompanyValid(values.companyCif))){
        errors.companyCif = I18nUtils.tr(TR_CIF_INCORRECTO);
    }
    if (values.companyPercentage && (values.companyPercentage < 0 || values.companyPercentage > 100)){
        errors.companyPercentage = I18nUtils.tr(TR_PORCENTAJE_INCORRECTO);
    }

    return errors;
}

export default compose(
    reduxForm<ExploitationCharacteristicsCardFormAdminData, ExploitationCharacteristicsCardFormAdminProps, FieldArrayErrors>({
        form: FORM_NAME,
        validate,
        enableReinitialize: true
    }),
    connect(mapFormStateToProps as any)
)(ExploitationCharacteristicsCardFormAdmin);
 