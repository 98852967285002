import * as React from 'react';
import {RouteComponentProps} from "react-router";
import I18nUtils from "../../../../I18n/I18nUtils";
import {generateRoute} from "../../../../utils/Router";
import {connect} from "react-redux";
import DocumentFormAdminScreenReducer from "./DocumentFormAdminScreenReducer";
import DocumentCardFormAdmin, {DocumentFormAdminData} from "./DocumentFormAdmin";
import {MenuIcon} from "../../../../Config";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import DocumentFormAdminScreenActions from "./DocumentFormAdminScreenActions";
import {ExploitationAutocompleteBuilder} from "../../../../model/Exploitation";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import TaskDocumentDetail from "../../../../ws/document/TaskDocumentDetail";
import {DocumentModelData} from "../../../../model/Document";
import TaskDocumentEdit from "../../../../ws/document/TaskDocumentEdit";
import {ROUTE_ADMIN_DOCUMENT_ADD, ROUTE_ADMIN_DOCUMENT_DETAIL, ROUTE_ADMIN_DOCUMENTS} from "../../../../routing/Routes";
import TaskDocumentAdd from "../../../../ws/document/TaskDocumentAdd";
import {
    TR_DOCUMENTACION,
    TR_DOCUMENTO,
    TR_DOCUMENTO_CREADO_CORRECTAMENTE,
    TR_DOCUMENTO_MODIFICADO_CORRECTAMENTE,
    TR_NUEVO_DOCUMENTO
} from "../../../../I18n/constants";
import CardHeader from "../../../../components/card/CardHeader";
import CardBody from "../../../../components/card/CardBody";
import Card from "../../../../components/card/Card";
import {ReducerBuilder} from "co-redux-builders";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import {UserRoleValue} from "../../../../model/UserRole";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    DocumentFormAdminScreenReducer.autoMapToProps(),
    CurrentUserReducer.autoMapToProps(),
);
const mapActionsToProps = DocumentFormAdminScreenActions.autoMapToProps();

type ExploitationFormAdminScreenProps = RouteComponentProps<{ id: string }>

type Props = typeof mapStateToProps & ExploitationFormAdminScreenProps & typeof mapActionsToProps

class DocumentFormAdminScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskDocumentDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: DocumentFormAdminData): void => {
        const existDocument = this.props.match.params.id !== undefined;

        const submitData: DocumentModelData = {
            name: values.name,
            url: values.url[0],
            exploitation_id: values.exploitation[0].value,
            description: values.description,
        };

        if (existDocument) {
            executeItemTask(
                new TaskDocumentEdit(this.props.match.params.id, submitData),
                () => this.props.history.goBack(),
                I18nUtils.tr(TR_DOCUMENTO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskDocumentAdd(submitData),
                () => this.props.history.goBack(),
                I18nUtils.tr(TR_DOCUMENTO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_DOCUMENTACION),
            url: ROUTE_ADMIN_DOCUMENTS,
            icon: MenuIcon.DOCUMENTATION
        }];

        if (this.props.match.params.id) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_DOCUMENTO),
                url: generateRoute(ROUTE_ADMIN_DOCUMENT_DETAIL, {id: this.props.match.params.id}),
                icon: MenuIcon.DOCUMENTATION
            });
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_DOCUMENTO),
                url: ROUTE_ADMIN_DOCUMENT_ADD,
                icon: MenuIcon.DOCUMENTATION
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderForm()}
            </ScreenCard>
        );
    }

    private renderForm(): React.ReactNode {
        const {loading, document, currentUser, history} = this.props;
        const userRoles: string[] = [];
        currentUser && currentUser.roles.map((role) => userRoles.push(role.role.id));

        if (loading) {
            return null;
        }

        let initialValues: DocumentFormAdminData | undefined;
        let documentId: string | undefined;

        if (document) {
            initialValues = {
                name: document.name,
                url: [document.url],
                exploitation: document.exploitation ? [ExploitationAutocompleteBuilder.buildOption(document.exploitation)] : [],
                description: document.description,
            };
            documentId = this.props.match.params.id || '';
        }

        return (
            <Card loading={loading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_DOCUMENTO : TR_NUEVO_DOCUMENTO)}/>
                <CardBody>
                    <DocumentCardFormAdmin
                        documentId={documentId}
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        goBackHandler={history.goBack}
                        readonly={userRoles.includes(UserRoleValue.DIRECTOR)}
                    />
                </CardBody>
            </Card>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(DocumentFormAdminScreen as unknown as React.ComponentType<ExploitationFormAdminScreenProps>);
