import * as React from 'react';
import ExploitationListCardAdminReducer from "./ExploitationListCardAdminReducer";
import {connect} from "react-redux";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ELIMINAR_EXPLOTACIONES,
    TR_EXPLOTACIONES, TR_NO_EXISTEN_EXPLOTACIONES,
} from "../../../../I18n/constants";
import {ROUTE_ADMIN_EXPLOITATION_ADD} from "../../../../routing/Routes";
import ExploitationListTable from "./ExploitationListTable";
import CardList from "../../../../components/card/CardList";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import ExploitationListAdminScreenActions from "./ExploitationListcardAdminActions";
import TaskExploitationDelete from "../../../../ws/exploitation/TaskExploitationDelete";

const mapStateToProps = ExploitationListCardAdminReducer.autoMapToProps();
const mapActionsToProps = ExploitationListAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class ExploitationListCardAdmin extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render() {
        const {exploitations, error, loading, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_EXPLOTACIONES)}
                      sort={{column: 'business_type'}}
                      TaskList={TaskExploitationList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_EXPLOTACIONES)}}
                      data={exploitations}
                      error={error}
                      ItemsTable={ExploitationListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_EXPLOTACIONES),
                          Task: TaskExploitationDelete
                      }}
                      addRoute={ROUTE_ADMIN_EXPLOITATION_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ExploitationListCardAdmin as unknown as React.ComponentType<{}>);

