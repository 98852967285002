import * as React from 'react';
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_DATOS_DEL_FORMULARIO,
    TR_ELIMINAR_DATOS_DEL_FORMULARIO,
    TR_NO_EXISTEN_DATOS_DEL_FORMULARIO,
} from "../../../I18n/constants";
import {connect} from "react-redux";
import CardList from "../../../components/card/CardList";
import FormResultListTable from "./FormResultListTable";
import FormResultListCardActions from "./FormResultListCardActions";
import TaskFormResultList, {FormResultCustomCriteria} from "../../../ws/formResult/TaskFormResultList";
import FormResultListCardReducer from "./FormResultListCardReducer";
import {generateRoute} from "../../../utils/Router";
import {ROUTE_FORM_RESULT_ADD} from "../../../routing/Routes";
import TaskFormResultDelete from "../../../ws/formResult/TaskFormResultDelete";
import {ReducerBuilder} from "co-redux-builders";
import CurrentUserReducer from "../../../redux/CurrentUserReducer";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    FormResultListCardReducer.autoMapToProps(),
    CurrentUserReducer.autoMapToProps(),
);
const mapActionsToProps = FormResultListCardActions.autoMapToProps();

interface FormResultListCardProps {
    formId: string,
    isAdminMode: boolean,
}

type Props = typeof mapStateToProps & typeof mapActionsToProps & FormResultListCardProps

class FormResultListCard extends React.Component<Props> {
    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render() {
        const {formResults, error, loading, pager, formId, currentUser, isAdminMode} = this.props;

        const customCriteria: FormResultCustomCriteria = currentUser && !isAdminMode ? {
                user_id: currentUser.id,
            } :
            {};

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_DATOS_DEL_FORMULARIO)}
                      sort={{column: 'datetime'}}
                // @ts-ignore: los constructores no coinciden
                      TaskList={TaskFormResultList}
                      emptyListOptions={{
                          message: I18nUtils.tr(TR_NO_EXISTEN_DATOS_DEL_FORMULARIO)
                      }}
                      data={formResults}
                      error={error}
                      ItemsTable={FormResultListTable}
                      addRoute={isAdminMode ? undefined : generateRoute(ROUTE_FORM_RESULT_ADD, {formId})}
                      deleteOption={{
                          Task: TaskFormResultDelete,
                          title: I18nUtils.tr(TR_ELIMINAR_DATOS_DEL_FORMULARIO)
                      }}
                      customTaskParams={[formId]}
                      customCriteria={customCriteria}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(FormResultListCard as unknown as React.ComponentType<FormResultListCardProps>);