import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Dashboard from "../../model/Dashboard";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";

export default class TaskDashboardGet extends BaseGenericRequest<{}, TaskResponse<Dashboard>> {

    public constructor(dashboardId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_DASHBOARD_GET, {uuid: dashboardId}));
    }
}
