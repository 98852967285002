import Role from "../../../../../../model/Role";
import Exploitation from "../../../../../../model/Exploitation";
import {UserSimply} from "../../../../../../model/User";
import {ReducerBuilder} from "co-redux-builders";
import TaskExploitationList from "../../../../../../ws/exploitation/TaskExploitationList";
import TaskUserList from "../../../../../../ws/user/TaskUserList";
import TaskRoleList from "../../../../../../ws/role/TaskRoleList";

interface InitialState {
    permissionRoles: Role[],
    permissionExploitations: Exploitation[],
    permissionUsers: UserSimply[],
}

const INITIAL_STATE: InitialState = {
    permissionRoles: [],
    permissionExploitations: [],
    permissionUsers: []
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, permissionExploitations: payload.data}
    })
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, permissionUsers: payload.data}
    })
    .onEvent(TaskRoleList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, permissionRoles: payload.data}
    })
    .build()