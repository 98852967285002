import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import User from "../../model/User";
import Urls from "../Urls";
import {METHOD} from "co-generic-request";

export default class TaskUserCurrentDetail extends BaseGenericRequest<{}, TaskResponse<User>> {

    public constructor() {
        super(METHOD.METHOD_GET, Urls.URL_USER_CURRENT_DETAIL);
    }
}