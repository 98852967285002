import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import {FormResultCheckFrequencyResponse, FormResultDynamicVariables} from "../../model/FormResult";

export default class TaskFormResultCheckFrequency extends BaseGenericRequest<FormResultDynamicVariables, TaskResponse<FormResultCheckFrequencyResponse>> {

    private readonly data: FormResultDynamicVariables;

    public constructor(formResultId: string, data: FormResultDynamicVariables) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_FORM_RESULT_CHECK_FREQUENCY, {formId: formResultId}));
        this.data = data;
    }

    protected getRequest(): FormResultDynamicVariables {
        return this.data;
    }
}