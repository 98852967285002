import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import UserGroup, {UserGroupData} from "../../model/UserGroup";

export default class TaskUserGroupAdd extends BaseGenericRequest<UserGroupData, TaskResponse<UserGroup>> {

    private readonly data: UserGroupData;

    public constructor(data: UserGroupData) {
        super(METHOD.METHOD_POST, Urls.URL_USER_GROUP_ADD);
        this.data = data;
    }

    protected getRequest(): UserGroupData {
        return this.data;
    }
}