import * as React from 'react';
import {ROUTE_START_ALERT} from "../../../../routing/Routes";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ALERTAS} from "../../../../I18n/constants";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {MenuIcon} from "../../../../Config";
import AlertListHomeCard from "./AlertListHomeCard";

export default class AlertListAdminScreen extends React.Component<{}> {

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_ALERTAS),
                    url: ROUTE_START_ALERT,
                    icon: MenuIcon.ALERT,
                },
            ]}>
                <AlertListHomeCard/>
            </ScreenCard>
        )
    }
}