import {AutocompleteOption} from "../components/form/FormAutocomplete";

export default interface BusinessType {
    id: string,
    name: string,
    elk_value: string,
}

export interface BusinessTypeData {
    name: string,
    elk_value: string,
}

export class BusinessTypeAutocompleteBuilder {

    public static buildOption(businessType: BusinessType): AutocompleteOption<BusinessType> {
        return ({
            name: businessType.name,
            value: businessType.id,
            data: businessType
        });
    }

}
