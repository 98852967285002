import * as React from 'react';
import {TR_RESULTADOS_DE_FORMULARIOS} from "../../../I18n/constants";
import I18nUtils from "../../../I18n/I18nUtils";
import {MenuIcon} from "../../../Config";
import ScreenCard from "../../../components/screen/ScreenCard";
import {ROUTE_FORM_RESULT_ALL_LIST} from "../../../routing/Routes";
import FormResultAllListCard from "./FormResultAllListCard";

export default class FormResultAllListScreen extends React.Component<{}> {

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_RESULTADOS_DE_FORMULARIOS),
                    url: ROUTE_FORM_RESULT_ALL_LIST,
                    icon: MenuIcon.FORM,
                },
            ]}>
                <FormResultAllListCard/>
            </ScreenCard>
        )
    }
}