import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Exploitation, {ExploitationCharacteristicsRequest} from "../../model/Exploitation";

export default class TaskExploitationCharacteristicsEdit extends BaseGenericRequest<ExploitationCharacteristicsRequest, TaskResponse<Exploitation>> {

    private readonly data: ExploitationCharacteristicsRequest;

    public constructor(exploitationId: string, exploitationCharacteristicsData: ExploitationCharacteristicsRequest) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_EXPLOITATION_CHARACTERISTICS_EDIT, {uuid: exploitationId}));
        this.data = exploitationCharacteristicsData;
    }

    protected getRequest(): ExploitationCharacteristicsRequest {
        return this.data;
    }
}