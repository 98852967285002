import * as React from 'react';

export default class DropDownCustomToggle extends React.Component<any> {

    constructor(props: any, context: any) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
    }

    private handleClick(e: any) {
        e.preventDefault();
        this.props.onClick(e);
    }

    public render() {
        return (
            <a href="" onClick={this.handleClick}>
                {this.props.children}
            </a>
        );
    }
}

