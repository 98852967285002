import React, {Component, ReactNode} from "react";
import {Field, WrappedFieldArrayProps} from "redux-form";
import Col from "../../Col";
import CardFieldArray from "../../card/CardFieldArray";
import {AutocompleteOption} from "../FormAutocomplete";
import User from "../../../model/User";
import FormAsyncAutocomplete from "../FormAsyncAutocomplete";


interface UsersFieldArrayProps extends WrappedFieldArrayProps<AutocompleteOption<User> [] | undefined>{
    label: string,
    onUserSearch: (search: string) => void,
    options: Array<AutocompleteOption<User>>,
    loading: boolean,
    obligatory?: boolean
}

class UsersFieldArray extends Component<UsersFieldArrayProps> {
    public componentDidMount(): void {
        const {fields, obligatory} = this.props;
        if (fields.length === 0 && obligatory) {
            fields.push(undefined);
        }
    }

    private handleSelectHeadOfService = (index: number, user: AutocompleteOption<User> []) => {
        this.props.fields.remove(index);
        this.props.fields.insert(index, user);
    };


    public render(): ReactNode {
        const {fields, meta, loading, options, onUserSearch, label, obligatory} = this.props;

        const fieldHeaders = (
            <>
                <Col sm={6} md={6} lg={6} className={'m-b-0'}>
                    {label ? <label className='main-label'>{label || ''}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label> : null}
                </Col>
            </>
        );

        return (
            <CardFieldArray
                title={''}
                addHandler={() => this.props.fields.push(undefined)}
                fields={fields}
                meta={meta}
                fieldsHeader={fieldHeaders}
            >
                {
                    fields.map((field: string, index: number) => {
                        return (
                            <Field
                                key={index}
                                name={field}
                                labelKey={index}
                                component={FormAsyncAutocomplete}
                                options={options}
                                obligatory={obligatory}
                                isLoading={loading}
                                onSearch={(search: string) => {onUserSearch(search)}}
                                onSelected={(selected: Array<AutocompleteOption<User>>) => {
                                    if (selected && selected[0]){
                                        this.handleSelectHeadOfService(index, selected);
                                    }
                                }}
                                col={{md: 12, lg: 12, className: 'm-b--10 m-t--10'}}
                            />
                        );
                    })
                }

            </CardFieldArray>
        );
    }
}

export default UsersFieldArray as React.ComponentType<any>;
