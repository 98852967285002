import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import User, {LinkData} from "../../model/User";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";

export default class TaskUserEdit extends BaseGenericRequest<LinkData, TaskResponse<User>> {

    private readonly data: LinkData;

    public constructor(userId: string, userData: LinkData) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_USER_EDIT, {uuid: userId}));
        this.data = userData;
    }

    protected getRequest(): LinkData {
        return this.data;
    }
}