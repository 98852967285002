import * as React from 'react';
import {ROUTE_DOCUMENTATION} from "../../../routing/Routes";
import DocumentationListCard from "./DocumentationListCard";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_DOCUMENTACION} from "../../../I18n/constants";
import ScreenCard from "../../../components/screen/ScreenCard";
import {MenuIcon} from "../../../Config";

export default class DocumentationListScreen extends React.Component<{}> {

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_DOCUMENTACION),
                    url: ROUTE_DOCUMENTATION,
                    icon: MenuIcon.DOCUMENTATION,
                },
            ]}>
                <DocumentationListCard/>
            </ScreenCard>
        )
    }

}