import * as React from 'react';
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ALERTAS, TR_NO_EXISTEN_ALERTAS,} from "../../../../I18n/constants";
import {connect} from "react-redux";
import CardList from "../../../../components/card/CardList";
import AlertListHomeTable from "./AlertListHomeTable";
import AlertNotificationUserReducer from "../../../admin/alertnotificationuser/list/AlertNotificationUserReducer";
import AlertNotificationUserActions from "../../../admin/alertnotificationuser/list/AlertNotificationUserActions";
import TaskAlertNotificationUserList from "../../../../ws/alertnotificationuser/TaskAlertNotificationUserList";

const mapStateToProps = AlertNotificationUserReducer.autoMapToProps();
const mapActionsToProps = AlertNotificationUserActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class AlertListHomeCard extends React.Component<Props> {

    public render() {
        const {alertNotificationUser, errors, loading, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_ALERTAS)}
                      sort={{column: 'datetime'}}
                      TaskList={TaskAlertNotificationUserList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_ALERTAS)}}
                      data={alertNotificationUser}
                      error={errors}
                      ItemsTable={AlertListHomeTable}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(AlertListHomeCard as unknown as React.ComponentType<{}>);
