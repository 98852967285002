import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import FormModel from "../../model/Form";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";

interface FormCustomCriteria {
    fav?: boolean,
}

type FormCriteriaProps = TaskCriteriaRequest<FormCustomCriteria>

export default class TaskFormList extends BaseGenericRequest<FormCriteriaProps, TaskResponse<FormModel[]>> {

    private readonly data: FormCriteriaProps;

    public constructor(criteria: FormCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_FORM_LIST);
        this.data = criteria;
    }

    protected getRequest(): FormCriteriaProps {
        return this.data;
    }

}