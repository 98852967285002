import * as React from 'react';
import LogUserCardAdminReducer from "./LogUserCardAdminReducer";
import {connect} from "react-redux";
import I18nUtils from "../../../../I18n/I18nUtils";
import LogUserTable from "./LogUserTable";
import CardList from "../../../../components/card/CardList";
import LogUserAdminScreenActions from "./LogUserCardAdminActions";
import {
    TR_LOG,
    TR_NO_EXISTEN_LOGS,
} from "../../../../I18n/constants";
import TaskLogList, {LogCriteria} from "../../../../ws/log/TaskLogList";

const mapStateToProps = LogUserCardAdminReducer.autoMapToProps();
const mapActionsToProps = LogUserAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class LogUserCardAdmin extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render() {
        const {logs, error, loading, pager, user} = this.props;

        const parentCriteria: LogCriteria = {
            user_ids: user ? [user.id] : undefined
        };

        return user ? (
                <CardList loading={loading}
                          pager={pager}
                          title={`${I18nUtils.tr(TR_LOG)} de ${user.name} ${user.last_name}`}
                          subtitle={user.email}
                          sort={{column: 'date', desc: true}}
                          TaskList={TaskLogList}
                          emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_LOGS)}}
                          data={logs}
                          error={error}
                          ItemsTable={LogUserTable}
                          customCriteria={parentCriteria}
                />
            ) :
            null
    }
}

export default connect(mapStateToProps, mapActionsToProps)(LogUserCardAdmin as unknown as React.ComponentType<{}>);

