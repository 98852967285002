import * as React from 'react';
import {Component, CSSProperties, ReactNode} from 'react';
import classNames from 'classnames';

export interface ColProps {
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
    className?: string,
    style?: CSSProperties,
}

export default class Col extends Component<ColProps> {

    public render(): ReactNode {
        const {xs, sm, md, lg, className, style} = this.props;
        const classComponent = classNames({
            [`col-xs-${xs}`]: xs,
            [`col-sm-${sm}`]: sm,
            [`col-md-${md}`]: md,
            [`col-lg-${lg}`]: lg,
            [`${className}`]: className,
        });
        return (
            <div className={classComponent} style={style}>
                {this.props.children}
            </div>
        );
    }

}

