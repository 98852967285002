
import Urls from "../Urls";
import AuthManager from "../../utils/AuthManager";
import {generateRoute} from "../../utils/Router";

export const DowloadDocument = async (documentId: string): Promise<any> => {
    return fetch(
        generateRoute(Urls.URL_DOWLOAD_DOCUMENT, {uuid: documentId}),{
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${AuthManager.getAuthToken()}`
            }
        });
}

