import React, {Component, ReactNode} from "react";
import LinkCreateAdminReducer from "./LinkCreateAdminReducer";
import LinkCreateAdminActions from "./LinkCreateAdminActions";
import LinkCreateAdminView from "./LinkCreateAdminView";
import {connect} from "react-redux";
import {LinkFormData} from "../../../../components/linkform/LinkForm";
import {LinkData} from "../../../../model/Link";
import TaskLinkAdd from "../../../../ws/links/TaskLinkAdd";
import AlertComponent from "../../../../base/alerts/AlertComponent";
import {TR_ERROR_NUEVO_LINK, TR_SUCCESS_NUEVO_LINK} from "../../../../I18n/constants";
import I18nUtils from "../../../../I18n/I18nUtils";
import {goToRoute} from "../../../../utils/Router";
import {ROUTE_LINKS} from "../../../../routing/Routes";

const mapStateToProps = LinkCreateAdminReducer.autoMapToProps();
const mapActionsToProps = LinkCreateAdminActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class LinkCreateAdminDataContainer extends Component<Props> {
    private handleCreateLink = (newLinkData: LinkFormData) => {
        const newLink: LinkData = {
            name: newLinkData.name,
            image: newLinkData.image[0],
            url: newLinkData.url
        };

        new TaskLinkAdd(newLink)
            .onFail(() => setTimeout(() => {
                AlertComponent.error(I18nUtils.tr(TR_ERROR_NUEVO_LINK))
            },1))

            .onSuccess(() => setTimeout(() => {
                AlertComponent.success(I18nUtils.tr(TR_SUCCESS_NUEVO_LINK));
                goToRoute(ROUTE_LINKS);
            },1))

            .execute()
    };

    public render(): ReactNode {
        const {loading, errors} = this.props;
        return (
            <LinkCreateAdminView
                onCreate={this.handleCreateLink}
                loading={loading}
                errors={errors}
            />
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(LinkCreateAdminDataContainer as unknown as React.ComponentType<{}>);