import {ReducerBuilder} from "co-redux-builders";
import TaskFormDetail from "../../../../ws/form/TaskFormDetail";
import FormModel from "../../../../model/Form";
import FormFormAdminScreenActions from "./FormFormAdminScreenActions";

interface InitialState {
    loading: boolean,
    form?: FormModel,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    form: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskFormDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskFormDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskFormDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, form: payload.data};
    })
    .onEvent(TaskFormDetail.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload};
    })

    .onEvent(FormFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();