import React, {Component, ReactNode} from "react";
import Exploitation from "../../../../model/Exploitation";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_EXPLOTACION} from "../../../../I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import Col from "../../../../components/Col";
import Card from "../../../../components/card/Card";
import {MenuIcon} from "../../../../Config";
import LoadingView from "../../../../base/loading/LoadingView";

interface ExploitationFormAdminViewProps{
    currentFormName: string,
    form: ReactNode,
    exploitation: Exploitation | undefined,
    loading: boolean,
    tabs: Array<{
        name: string,
        header: string,
        completed: boolean,
        onTabSelected: () => void
    }>
}

export default class ExploitationFormAdminView extends Component<ExploitationFormAdminViewProps> {
    public render(): ReactNode {
        const {currentFormName, form, exploitation, tabs, loading} = this.props;
        return (
            <Card>
                <CardHeader title={exploitation && exploitation.name ? exploitation.name :I18nUtils.tr(TR_EXPLOTACION)}/>
                <CardBody>
                    <Row>
                        <Col xs={12} sm={3} md={3} lg={3} className={"exploitation-form-tabs"}>
                            {
                                tabs.map((tab, index) => {
                                    return (
                                        <div key={index}
                                             className={currentFormName === tab.name ? "tab selected" : "tab"}
                                             onClick={tab.onTabSelected}
                                        >
                                            <p>{tab.header}</p>

                                            {this.renderTabIcon(tab.completed)}

                                        </div>
                                    );
                                })
                            }
                        </Col>
                        <Col xs={12} sm={9} md={9} lg={9}>
                            {loading ? <LoadingView loading={loading}/> : form}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    private renderTabIcon(completed: boolean): ReactNode {
        const {loading} = this.props;
        if (!loading){

            return (
                completed ? <span className={"material-icons completed"}>{MenuIcon.COMPLETED}</span>
                    : <span className={"material-icons uncompleted"}>{MenuIcon.UNCOMPLETED}</span>
            )
        }
        
        return null;
    }
}
 