import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import UserAction from "../../model/UserAction";

export default class TaskUserActionsList extends BaseGenericRequest<{}, TaskResponse<UserAction []>> {
    public constructor() {
        super(METHOD.METHOD_GET, Urls.URL_USER_ACTION);
    }
}