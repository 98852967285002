import * as React from 'react';
import GroupFieldArrayReducer from "./GroupFieldArrayReducer";
import {Field, WrappedFieldArrayProps} from "redux-form";
import {connect} from "react-redux";
import CardFieldArray from "../../../../../../components/card/CardFieldArray";
import I18nUtils from "../../../../../../I18n/I18nUtils";
import {TR_GRUPOS} from "../../../../../../I18n/constants";
import FormSelect from "../../../../../../components/form/FormSelect";
import TaskUserGroupList from "../../../../../../ws/userGroup/TaskUserGroupList";
import Col from "../../../../../../components/Col";

const mapStateToProps = GroupFieldArrayReducer.autoMapToProps();

type Props = WrappedFieldArrayProps<string> & typeof mapStateToProps;

class GroupFieldArray extends React.Component<Props> {

    public componentWillMount(): void {
        new TaskUserGroupList().execute();
    }

    public render(): React.ReactNode {
        const {fields, groupList, meta} = this.props;

        const groupOptions = groupList.map((group) => {
            return {
                name: group.name,
                value: group.id
            }
        });

        return (
            <CardFieldArray
                title={''}
                fields={fields}
                meta={meta}
                fieldsHeader={this.renderHeader()}
                addHandler={() => {
                    if (groupOptions.length > 0 && groupOptions[0].value) {
                        fields.push(groupOptions[0].value)
                    }
                }}>
                {fields.map((fieldName: string, index: number) => {
                    return (
                        <Field
                            key={index}
                            name={`${fieldName}`}
                            component={FormSelect}
                            options={groupOptions}
                            col={{className: ''}}
                        />
                    )
                })}
            </CardFieldArray>
        )
    }

    private renderHeader = (): React.ReactNode => {
        return (
            <Col className={'m-b-0 m-l-15'}>
                <label>{I18nUtils.tr(TR_GRUPOS)}</label>
            </Col>
        )
    }
}

export default connect(mapStateToProps)(GroupFieldArray) as unknown as React.ComponentType<any>;