import * as React from 'react';
import {Component, ReactNode} from 'react';
import Icon from "../Icon";
import classNames from 'classnames';

export interface ThProps {
    text: string,
    columnName?: string,
    sort?: {
        column: string,
        asc: boolean,
    },
    onSort?: (asc: boolean, column: string) => void,
    center? : boolean
}

export default class Th extends Component<ThProps> {

    public render(): ReactNode {
        const classTh = classNames({active: this.props.sort && this.props.columnName === this.props.sort.column,
            'align-center': this.props.center
        });

        return (
            <th className={classTh}>{this.renderTh()}</th>
        );
    }

    private renderTh(): ReactNode {
        if (this.props.onSort && this.props.sort && this.props.columnName) {
            // @ts-ignore
            return <a onClick={() => this.props.onSort(
                // @ts-ignore
                this.props.columnName === this.props.sort.column ? !this.props.sort.asc : true, this.props.columnName)}>
                {this.props.text}<Icon icon={'unfold_more'}/></a>;
        }
        return this.props.text;
    }

}
