import * as React from 'react';
import {Field, FieldArray, FormErrors, getFormSyncErrors, InjectedFormProps, reduxForm} from "redux-form";
import FormDetailCardAdminReducer from "./FormDetailCardAdminReducer";
import {connect} from "react-redux";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import CardBody from "../../../../components/card/CardBody";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ALCANCE,
    TR_ALCANCE_OBLIGATORIO,
    TR_DEBE_EMPEZAR_POR_UN_CARACTER_VALIDO,
    TR_DESCRIPCION,
    TR_DESCRIPCION_OBLIGATORIA,
    TR_EL_IDENTIFICADOR_NO_DEBE_CONTENER_ESPACIOS,
    TR_EL_IDENTIFICADOR_NO_SE_PUEDE_REPETIR,
    TR_ENUNCIADO_DEL_CAMPO_OBLIGATORIO,
    TR_ES_NECESARIO_INCLUIR_COMO_MINIMO_UNA_OPCION,
    TR_HABILITADO,
    TR_IDENTIFICADOR_DEL_FORMULARIO,
    TR_IDENTIFICADOR_OBLIGATORIO,
    TR_LA_URL_NO_TIENE_EL_FORMATO_ADECUADO,
    TR_NO_EXISTEN_TIPOS_DE_CAMPOS_DE_FORMULARIO, TR_NO_HAY_CAMPOS_DEFINIDOS,
    TR_NOMBRE_DEL_FORMULARIO,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVO_FORMULARIO,
    TR_PERIODICIDAD,
    TR_PERIODICIDAD_OBLIGATORIA,
    TR_TEXTO_OBLIGATORIO,
    TR_TIPO_OBLIGATORIO,
    TR_WEBHOOK
} from "../../../../I18n/constants";
import Row from "../../../../components/Row";
import TaskFormFieldTypeList from "../../../../ws/formFieldType/TaskFormFieldTypeList";
import FormFieldCreatorFieldArray from "./FormFieldCreatorFieldArray";
import {compose} from "redux";
import {ReducerBuilder} from "co-redux-builders";
import FormInput from "../../../../components/form/FormInput";
import {FormFieldTypeValue} from "../../../../model/FormFieldType";
import {FieldArrayErrors, NestedFieldArrayErrors} from "../../../../utils/FormUtils";
import FormPermissionCardAdmin, {
    ExploitationAutocompleteOptions,
    FormPermissionCardAdminHelpers,
    FormPermissionSectionData,
} from "./cards/permission/FormPermissionSectionAdmin";
import FormSwitch from "../../../../components/form/FormSwitch";
import {UserAutocompleteOptions} from "../../userGroups/form/UserGroupCardFormAdmin";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {goToRoute} from "../../../../utils/Router";
import {ROUTE_ADMIN_FORMS} from "../../../../routing/Routes";
import {UserRoleFieldArrayOption} from "../../../../model/UserRole";
import {RoleError} from "../../userGroups/form/ExploitationRoleFieldArray";
import Role from "../../../../model/Role";
import {FormFieldCreatorFormData} from "./FormFieldCreator";
import FormSelect from "../../../../components/form/FormSelect";
import TaskFormFrequencyTypeList from "../../../../ws/formfrequencytype/TaskFormFrequencyTypeList";
import TaskFormScopeTypeList from "../../../../ws/formscopetype/TaskFormScopeTypeList";
import {isValidURL} from "../../../../utils/StringUtils";
import FormTextArea from "../../../../components/form/FormTextArea";

interface SynchronousError {
    users: string[],
    roles: RoleError[],
    exploitations: string[],
}

export interface FormCardDetailAdminData extends FormPermissionSectionData {
    formKey: string,
    formName: string,
    formDescription: string,
    formFields: FormFieldCreatorFormData[],
    formEnabled: boolean,
    formWebhook: string,
    roles: UserRoleFieldArrayOption[],
    exploitations: ExploitationAutocompleteOptions[],
    users: UserAutocompleteOptions[],
    formFrequency: string,
    formScope: string
}

enum FormDetailCardAdminFields {
    FORM_KEY = "formKey",
    FORM_NAME = "formName",
    FORM_DESCRIPTION = "formDescription",
    FORM_FIELDS = "formFields",
    FORM_ENABLED = "formEnabled",
    FORM_FREQUENCY = "formFrequency",
    FORM_SCOPE = "formScope",
    FORM_WEBHOOK = "formWebhook"
}

interface FormCardDetailAdminExtProps {
    onSubmit: (data: FormCardDetailAdminData) => void,
    initialValues?: Partial<FormCardDetailAdminData>,
    roleList: Role[],
    parentLoading: boolean,
}

interface FormCardDetailAdminProps extends InjectedFormProps<FormCardDetailAdminData> {
    onSubmit: (data: FormCardDetailAdminData) => void,
    initialValues: Partial<FormCardDetailAdminData>,
    formValues: { values: FormCardDetailAdminData },
    synchronousError: SynchronousError,
}

const mapStateToPropsGeneric = (state: any) => {
    return {
        formState: state.form[FormDetailCardAdmin.name],
        synchronousError: getFormSyncErrors(FormDetailCardAdmin.name)(state)
    }
};

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(mapStateToPropsGeneric,
    FormDetailCardAdminReducer.autoMapToProps());

type Props = FormCardDetailAdminProps & typeof mapStateToProps & FormCardDetailAdminExtProps;

class FormDetailCardAdmin extends React.Component<Props> {

    public componentWillMount(): void {
        new TaskFormFieldTypeList().execute();
        new TaskFormFrequencyTypeList().execute();
        new TaskFormScopeTypeList().execute();
    }

    private hasErrors(fieldArrayErrors: string[]): boolean {
        const filteredFieldArrayErrors = fieldArrayErrors.filter((error) => error);
        return !!filteredFieldArrayErrors.find((error) => error.length !== 0);
    };

    private validatePermissionSection = (): boolean => {
        const {synchronousError} = this.props;

        const usersHasErrors = synchronousError.users ?
            this.hasErrors(synchronousError.users) : false;

        const roleTypesHasErrors = synchronousError.roles ?
            this.hasErrors(synchronousError.roles.map((role) => role.role_id)) : false;

        const roleExploitationsHasErrors = synchronousError.roles ?
            this.hasErrors(synchronousError.roles.map((role) => role.exploitation)) : false;

        const exploitationsHasErrors = synchronousError.exploitations ?
            this.hasErrors(synchronousError.exploitations) : false;

        return usersHasErrors || roleTypesHasErrors || roleExploitationsHasErrors || exploitationsHasErrors;
    };

    public render(): React.ReactNode {
        const {
            handleSubmit, formFieldTypeLoading, formFieldTypeOptions, pristine, invalid, initialValues, parentLoading,
            onSubmit, formFrequencyOptions, formScopesOptions
        } = this.props;

        if ((!formFieldTypeOptions || formFieldTypeOptions.length === 0) && !formFieldTypeLoading) {
            return <h3
                className={'text-center m-t-30 m-b-30'}>{I18nUtils.tr(TR_NO_EXISTEN_TIPOS_DE_CAMPOS_DE_FORMULARIO)}</h3>;
        }

        return (
            <Card loading={formFieldTypeLoading || parentLoading}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CardHeader title={I18nUtils.tr(TR_NUEVO_FORMULARIO)}
                                otherActions={<Field
                                    name={FormDetailCardAdminFields.FORM_ENABLED}
                                    component={FormSwitch}
                                    labelDisabled={I18nUtils.tr(TR_HABILITADO)}
                                    col={{md: 6, lg: 6, className: 'm-t--20 p-r-0 m-r-0 m-b-0 align-right'}}
                                    disabled={false}
                                />}>
                    </CardHeader>
                    <CardBody>

                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_IDENTIFICADOR_DEL_FORMULARIO)}
                                name={FormDetailCardAdminFields.FORM_KEY}
                                component={FormInput}
                                col={{md: 12, lg: 12}}
                                maxLength={12}
                            />
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE_DEL_FORMULARIO)}
                                name={FormDetailCardAdminFields.FORM_NAME}
                                component={FormInput}
                                col={{md: 12, lg: 12, className: 'm-t--15 p-t-0'}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_DESCRIPCION)}
                                name={FormDetailCardAdminFields.FORM_DESCRIPTION}
                                component={FormTextArea}
                                rows={4}
                                col={{md: 12, lg: 12}}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_PERIODICIDAD)}
                                name={FormDetailCardAdminFields.FORM_FREQUENCY}
                                component={FormSelect}
                                col={{md: 6, lg: 6}}
                                options={formFrequencyOptions}
                                blankOptionText={' '}
                            />
                            <Field
                                label={I18nUtils.tr(I18nUtils.tr(TR_ALCANCE))}
                                name={FormDetailCardAdminFields.FORM_SCOPE}
                                component={FormSelect}
                                col={{md: 6, lg: 6}}
                                options={formScopesOptions}
                                blankOptionText={' '}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_WEBHOOK)}
                                name={FormDetailCardAdminFields.FORM_WEBHOOK}
                                col={{md: 12, lg: 12}}
                                component={FormInput}
                                placeholder={"https://beeparktest.cuatroochenta.com:8000/annualbudget/import"}
                            />
                        </Row>
                        <Row className={'m-b-25 m-r-0 m-l-0'}>
                            <FieldArray
                                name={FormDetailCardAdminFields.FORM_FIELDS}
                                // @ts-ignore
                                component={FormFieldCreatorFieldArray}
                            />
                        </Row>

                        <FormPermissionCardAdmin
                            invalid={this.validatePermissionSection()}
                        />

                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_FORMS)}/>
                    </CardBody>
                </form>

            </Card>
        )
    }
}

function validateSelectValues(field: FormFieldCreatorFormData): NestedFieldArrayErrors[] {
    const selectFieldValuesErrors: NestedFieldArrayErrors[] = [];
    field.formFieldValues.forEach((fieldValue, indexFieldValue: number) => {
        if (!selectFieldValuesErrors[indexFieldValue]) {
            selectFieldValuesErrors[indexFieldValue] = {};
        }
        if (!field.formFieldValues[indexFieldValue].name || field.formFieldValues[indexFieldValue].name.length === 0) {
            selectFieldValuesErrors[indexFieldValue].name = I18nUtils.tr(TR_TEXTO_OBLIGATORIO);
        }
    });
    return selectFieldValuesErrors;
}

type FormFieldError = {
    [P in keyof FormFieldCreatorFormData]: FieldArrayErrors
}

function validateFormFields(values: FormCardDetailAdminData): FormFieldError[] {
    const fieldErrors: FormFieldError[] = [];
    values.formFields.forEach((field, index) => {
        fieldErrors[index] = {
            inputKey: '',
            type: '',
            inputStatement: '',
            required: '',
            defaultValue: '',
            maxLength: '',
            max: '',
            min: '',
            formFieldValues: [],
            switchFirstOption: '',
            switchSecondOption: '',
        };

        if (!field.inputKey || field.inputKey.length === 0) {
            fieldErrors[index].inputKey = I18nUtils.tr(TR_IDENTIFICADOR_OBLIGATORIO);
        } else {
            if (field.inputKey.indexOf(' ') !== -1) {
                fieldErrors[index].inputKey = I18nUtils.tr(TR_EL_IDENTIFICADOR_NO_DEBE_CONTENER_ESPACIOS);
            }
            if (!field.inputKey.match(/^[a-zA-Z]/)) {
                fieldErrors[index].inputKey = I18nUtils.tr(TR_DEBE_EMPEZAR_POR_UN_CARACTER_VALIDO);
            }
            values.formFields.forEach((findField, findIndex) => {
                if (index !== findIndex) {
                    if (field.inputKey === findField.inputKey) {
                        fieldErrors[index].inputKey = I18nUtils.tr(TR_EL_IDENTIFICADOR_NO_SE_PUEDE_REPETIR);
                    }
                }
            })
        }

        if (!field.type || field.type === FormFieldTypeValue.NONE) {
            fieldErrors[index].type = I18nUtils.tr(TR_TIPO_OBLIGATORIO);
        }
        if (!field.inputStatement || field.inputStatement.length === 0) {
            fieldErrors[index].inputStatement = I18nUtils.tr(TR_ENUNCIADO_DEL_CAMPO_OBLIGATORIO);
        }

        if (field.formFieldValues) {
            if (field.formFieldValues.length === 0) {
                fieldErrors[index].formFieldValues = {_error: I18nUtils.tr(TR_ES_NECESARIO_INCLUIR_COMO_MINIMO_UNA_OPCION)}
            } else {
                fieldErrors[index].formFieldValues = validateSelectValues(field);
            }
        }

        if (field.type === FormFieldTypeValue.SWITCH) {
            if (!field.switchFirstOption) {
                fieldErrors[index].switchFirstOption = I18nUtils.tr(TR_TEXTO_OBLIGATORIO);
            }
            if (!field.switchSecondOption) {
                fieldErrors[index].switchSecondOption = I18nUtils.tr(TR_TEXTO_OBLIGATORIO);
            }
        }
    });
    return fieldErrors;
}

function validate(values: FormCardDetailAdminData, props: FormCardDetailAdminExtProps) {
    const errors: FormErrors<FormCardDetailAdminData, FieldArrayErrors> = {
        ...FormPermissionCardAdminHelpers.validate(values, props)
    };

    if (!values.formKey || values.formKey.length === 0) {
        errors.formKey = I18nUtils.tr(TR_IDENTIFICADOR_OBLIGATORIO);
    } else {
        if (values.formKey.indexOf(' ') !== -1) {
            errors.formKey = I18nUtils.tr(TR_EL_IDENTIFICADOR_NO_DEBE_CONTENER_ESPACIOS);
        }
        if (!values.formKey.match(/^[a-zA-Z]/)) {
            errors.formKey = I18nUtils.tr(TR_DEBE_EMPEZAR_POR_UN_CARACTER_VALIDO);
        }
    }
    if (!values.formName || values.formName.length === 0) {
        errors.formName = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.formDescription) {
        errors.formDescription = I18nUtils.tr(TR_DESCRIPCION_OBLIGATORIA);
    }
    if (!values.formFields || values.formFields.length === 0) {
        errors.formFields = {_error: I18nUtils.tr(TR_NO_HAY_CAMPOS_DEFINIDOS)};
    }
    if (values.formFields){
        errors.formFields = validateFormFields(values);
    }
    if (!values.formFrequency || values.formFrequency === "-1") {
        errors.formFrequency = I18nUtils.tr(TR_PERIODICIDAD_OBLIGATORIA);
    }
    if (!values.formScope || values.formScope === "-1") {
        errors.formScope = I18nUtils.tr(TR_ALCANCE_OBLIGATORIO);
    }
    if (values.formWebhook && !isValidURL(values.formWebhook)) {
        errors.formWebhook = I18nUtils.tr(TR_LA_URL_NO_TIENE_EL_FORMATO_ADECUADO);
    }
    return errors;
}

export default compose(
    reduxForm<FormCardDetailAdminData, FormCardDetailAdminExtProps, FieldArrayErrors>({
        form: FormDetailCardAdmin.name,
        validate,
        enableReinitialize: true
    }),
    connect(mapStateToProps),
)(FormDetailCardAdmin) as React.ComponentType<FormCardDetailAdminExtProps>;