import React, {Component, ReactNode} from "react";
import LinkForm, {LinkFormData} from "../../../../components/linkform/LinkForm";

interface LinkCreateAdminViewProps{
    onCreate: (newLink: LinkFormData) => void,
    loading: boolean,
    errors: string
}


export default class LinkCreateAdminView extends Component<LinkCreateAdminViewProps> {
    public render(): ReactNode {
        const {onCreate, loading, errors} = this.props;
        return (
            <LinkForm
                onSubmit={onCreate}
                loading={loading}
                errors={errors}
            />
        );
    }
}
 