import BaseGenericRequest from "../BaseGenericRequest";
import {DashboardCompareChartsRequest, DashboardChartCompared} from "../../model/Dashboard";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import TaskResponse from "../TaskResponse";
import {generateRoute} from "../../utils/Router";

export default class TaskDashboardCompare extends BaseGenericRequest<DashboardCompareChartsRequest, TaskResponse<DashboardChartCompared>> {

    private readonly data: DashboardCompareChartsRequest;

    public constructor(dashboardId: string, itemId: string, data: DashboardCompareChartsRequest) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_DASHBOARD_COMPARE, {uuid: dashboardId, itemUuid: itemId}));
        this.data = data;
    }

    protected getRequest(): DashboardCompareChartsRequest {
        return this.data;
    }
}