import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import Exploitation from "../../model/Exploitation";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";

type ExploitationCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskExploitationList extends BaseGenericRequest<ExploitationCriteriaProps, TaskResponse<Exploitation[]>> {

    private readonly data: ExploitationCriteriaProps;

    public constructor(criteria: ExploitationCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_EXPLOITATION_LIST);
        this.data = criteria
    }

    protected getRequest(): ExploitationCriteriaProps {
        return this.data;
    }
}
