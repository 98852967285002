import * as React from 'react';
import {Component, ReactNode} from 'react';
import BreadcrumbManager from "../breadcrumb/BreadcrumbManager";
import Breadcrumb from "../breadcrumb/Breadcrumb";

export interface ScreenContentProps {
    breadcrumbManager: BreadcrumbManager,
}

export default class ScreenContent extends Component<ScreenContentProps> {

    public render(): ReactNode {
        return (
            <section className="content">
                <div className="container-fluid">
                    <Breadcrumb breadcrumbManager={this.props.breadcrumbManager}/>
                    {this.props.children}
                </div>
            </section>
        );
    }

}

