import React, { useState } from 'react';
import FormCol, { FormColProps } from './FormCol';
import Button from "../Button"
import { WrappedFieldProps } from 'redux-form';
import TaskFormResultList from "../../ws/formResult/TaskFormResultList";
import TaskFormResultDetail from '../../ws/formResult/TaskFormResultDetail';
import I18nUtils from "../../I18n/I18nUtils";
import { TR_EXPLOTACION_OBLIGATORIO, TR_FECHA_OBLIGATORIA, TR_NO_EXISTEN_DATOS_DIA } from "../../I18n/constants";
import LoadingView from '../../base/loading/LoadingView';
import { default as AlertView} from 'react-s-alert';

export interface FormCheckDayProps extends WrappedFieldProps {
    formId: string,
    col: FormColProps,
    inputAndText: string,
}

const renderText = (inputAndText: string) => {
    let parameters = inputAndText.split(":");
    let text = parameters[0];
    return text
}
const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
const checkForm = async (inputAndText: string, formId: string, setLoading: any) => {
    try {
        setLoading(true);
        //
        let parameters = inputAndText.split(":");
        let inputName = parameters[1];

        let input = document.getElementsByName(inputName)[0] as HTMLInputElement;
        let exploitationSelect = document.getElementsByName("exploitationId")[0] as HTMLInputElement;
        //
        // Verifico que el input no sea undefined
        if (input === undefined){
            setLoading(false);
            return;
        }
        //
        // Asigno el nodo padre dle input de la fecha
        let inputParent = input.parentElement;
        //
        // si el nodo padre no es null
        if (inputParent === null){
            setLoading(false);
            return;
        }
        //
        // Obtengo el input donde mostraré si hay error
        let inputError = inputParent.nextSibling as HTMLLabelElement;
        //
        // Si el valor de la fecha es vacío o -1, muestro error por pantalla
        //
        if (!input.value || input.value === "-1")
            inputError.innerHTML = I18nUtils.tr(TR_FECHA_OBLIGATORIA);
        //
        // Ahora debo verificar que el valor del select de explotación no esté vacío
        if (exploitationSelect === undefined){
            setLoading(false);
            return;
        }
        //
        // Asigno el elemento para mostrar el error por pantalla
        let exploitationError = exploitationSelect.nextSibling as HTMLLabelElement;
        //
        // Leo el valor seleccionado en el select de explotación
        let exploitationId = exploitationSelect.value;
        //
        // Si el valor es vacío o -1, entonces muestro error por pantalla
        if (!exploitationId || exploitationId === "-1")
            exploitationError.innerHTML = I18nUtils.tr(TR_EXPLOTACION_OBLIGATORIO);
        //
        // Obtengo la fecha en que se busca el formulario
        let date = new Date(input.value);
        //
        let TaskList = new TaskFormResultList({}, formId)
        let forms = await TaskList.executeAsPromise();
        //
        /*Recorrer los formularios para ver los que tienen el mismo dia*/
        let form = forms.data.filter(val => { 
            if(val.values != undefined)
                if(val.values[inputName] === formatDate(date))
                    return val;
        });
        /*Si encontramos un formulario el mismo dia cogemos el primero*/
        if(form.length > 0){
            let TaskForm = new TaskFormResultDetail(form[0].id, formId);
            await TaskForm.executeAsPromise();
        }else{
            AlertView.warning(I18nUtils.tr(TR_NO_EXISTEN_DATOS_DIA));
        }
        setLoading(false);

    } catch(err) {
        console.log(err);
    }
}

const FormCheckDay = ( { col, inputAndText, formId } ) => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <FormCol {...col}>
                <div className="form-group">
                    <Button className="btn-primary" text={renderText(inputAndText)} onClick={() => checkForm(inputAndText, formId, setLoading)} />
                </div>
            </FormCol>
            <LoadingView loading={loading || false}/>
        </>
    )
}

export default FormCheckDay;