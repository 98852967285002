import * as React from "react";
import Th from "./Th";

interface SelectThProps {
    title: string,
    showSelect: boolean,
}

export default class SelectTh extends React.Component<SelectThProps> {
    public render() {
        return this.props.showSelect ?
            (<Th text={this.props.title}
                 columnName={'select'}
            />) :
            null
    }
}