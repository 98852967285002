import * as React from 'react';
import {Component, ReactNode} from 'react';

export interface LabelProps {
    text: string,
    color: string,
    textColor: string,
}

export default class Label extends Component<LabelProps> {

    public render(): ReactNode {
        return (
            <span className="label" style={{ backgroundColor: this.props.color, color: this.props.textColor}}>{this.props.text}</span>
        );
    }

}

