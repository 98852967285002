import React from 'react';
import FormCol from './FormCol';
import { connect } from 'react-redux'

const getTotal = (label, form) => {
    let arrayFields = label.split(':');
    let title = arrayFields[0].trim();
    let ids = arrayFields[1].trim();
    let sum = 0;

    if (form === undefined || form.FormResultCardForm === undefined || Object.keys(form.FormResultCardForm.values).length < 1 )
        return `${title}: 0`;
    
    let fields = form.FormResultCardForm.values;
    
    for (const id of ids.split('+'))
    {
        let fieldValue = Number(fields[id]) || 0;
        sum += fieldValue;
    }

    let result = sum.toString();

        if (result.indexOf('.') !== -1)
            result = parseFloat(result).toFixed(2);

    return `${title}: ${result}`;
}

const FormTotalizer = ({ label, col, fields }) => {
    return (
        <FormCol {...col} >
            <div className={'form-group text-right'}>
                {label ? <label className='main-label' style={{ color: 'green' }}>{getTotal(label, fields) || ''}</label> : null}
            </div>
        </FormCol>
    )
}

const mapStateToProps = state => ({ fields: state.form })

export default connect(mapStateToProps)(FormTotalizer);
