import * as React from 'react';
import {isEqual, debounce} from 'lodash';
import Pager, {PagerBackendProps} from "../Pager";
import {OnSortHandler, SortState, dynamicSort } from "../../utils/Sort";
import BaseGenericRequest, {TaskGenericList} from "../../ws/BaseGenericRequest";
import Card from "./Card";
import CardHeader from "./CardHeader";
import CardBody from "./CardBody";
import {DropDownOption} from "../dropdown/DropDown";
import Config from "../../Config";
import CardOkCancelFooter from "./CardOkCancelFooter";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_ANADIDOS_A_FAVORITOS_CORRECTAMENTE,
    TR_ANADIR_A_FAVORITOS,
    TR_ELIMINADOS_CORRECTAMENTE,
    TR_ELIMINAR, TR_QUITADOS_DE_FAVORITOS_CORRECTAMENTE,
    TR_QUITAR_DE_FAVORITOS
} from "../../I18n/constants";
import {TaskCriteriaRequest} from "../../ws/TaskCriteriaRequest";
import {executeItemTask} from "../../utils/FormUtils";
import {goToRoute} from "../../utils/Router";
import SelectTh from "../table/SelectTh";
import {Checkbox} from "@material/react-checkbox";
import {SyntheticEvent} from "react";
import Icon from "../Icon";
import TaskFavoriteAdd from "../../ws/favorite/TaskFavoriteAdd";
import TaskFavoriteRemove from "../../ws/favorite/TaskFavoriteRemove";
import TaskDashboardList from "../../ws/dashboard/TaskDashboardList";
import TaskFormList from "../../ws/form/TaskFormList";
import TaskDocumentList from "../../ws/document/TaskDocumentList";
import {FavoriteEntity} from "../../model/Favorite";

enum SelectAction {
    NONE = 1,
    DELETE = 2,
    ADD_FAVORITE = 3,
    REMOVE_FAVORITE = 4,
}

export interface EmptyListOptions {
    message: string,
    addHandler?: () => void,
}

interface CardListProps {
    loading: boolean,
    title: string,
    TaskList: TaskGenericList | BaseGenericRequest,
    emptyListOptions: EmptyListOptions,
    data: any[],
    error: string,
    ItemsTable: React.ComponentType<{
        data: any[],
        error: string,
        onSort: OnSortHandler,
        sort: SortState,
        renderSelectTd?: (itemId: string) => React.ReactNode,
        addToFavorites?: boolean,
        removeFromFavorites?: boolean,
        refreshListHandler?: () => void,
    }>
    sort: {
        column: string,
        desc?: boolean,
    }
    subtitle?: string,
    pager: PagerBackendProps,
    headerOptions?: DropDownOption[],
    addRoute?: string,
    addToFavorites?: boolean,
    removeFromFavorites?: boolean,
    favoritesManagerByList?: boolean,
    deleteOption?: {
        // no puedo tipar este caso por problemas al interpretar el constructor
        Task: any,
        title: string,
    },
    customCriteria?: { [criteria: string]: any },
    customTaskParams?: Array<string | number | boolean>,
    hideSearcher?: boolean,
}

interface State {
    currentPage: number,
    headerSearch: string,
    search: string,
    sort: SortState,
    currentSelectAction: SelectAction,
    selectedItemIds: string[],
}

export default class CardList extends React.Component<CardListProps, State> {
    public state: State = {
        headerSearch: '',
        search: '',
        sort: {
            column: this.props.sort.column,
            asc: !this.props.sort.desc,
        },
        currentPage: 1,
        currentSelectAction: SelectAction.NONE,
        selectedItemIds: [],
    };

    public componentWillMount(): void {
        this.getItems();
    }

    public UNSAFE_componentWillUpdate(nextProps: Readonly<CardListProps>,
                                      nextState: Readonly<State>,
                                      nextContext: any): void {
        const currentCriteria = this.getCriteria();
        const nextCriteria = this.getCriteria(nextState, nextProps);
        if (!isEqual(currentCriteria, nextCriteria)) {
            this.getItems(nextCriteria);
        }
    }

    public componentWillUnmount(): void {
        this.onSearchDebounced.cancel();
    }

    private getCriteria = (nextState?: State, nextProps?: CardListProps) => {
        const {currentPage, search, sort} = nextState || this.state;
        const {customCriteria} = nextProps || this.props;

        let parentCriteriaHasChanges = false;

        if (nextProps && !isEqual(this.props.customCriteria, nextProps.customCriteria)) {
            parentCriteriaHasChanges = true;
        }

        return {
            limit: Config.PAGER.elementsPage,
            page: parentCriteriaHasChanges ? 1 : currentPage,
            search,
            sort_column: {
                name: sort.column,
                asc: sort.asc,
            },
            ...customCriteria,
        }
    };

    private getItems = (customCriteria?: TaskCriteriaRequest<{}>): void => {
        const {TaskList, customTaskParams = []} = this.props;
        const criteria = customCriteria || this.getCriteria();
        // @ts-ignore, el tipo de constructor es diferente entre clase padre e hijo
        new TaskList(criteria, ...customTaskParams).execute();
    };

    private onRefresh = (): void => {
        this.getItems();
    };

    private onSetPage = (pageNumber: number): void => {
        this.setState({
            currentPage: pageNumber
        });
    };

    private onHeaderSearch = (headerSearch: string): void => {
        this.setState({headerSearch});
        this.onSearchDebounced(headerSearch);
    };

    private onSearch = (search: string): void => {
        this.setState({search, currentPage: 1})
    };

    private onSearchDebounced = debounce(this.onSearch, 1000);

    private onSort: OnSortHandler = (asc, columnName) => {
        this.setState({
            sort: {
                column: columnName,
                asc,
            }
        })
    };

    private onShowDelete = (): void => {
        this.setState({currentSelectAction: SelectAction.DELETE})
    };

    private onShowAddFavourite = (): void => {
        this.setState({currentSelectAction: SelectAction.ADD_FAVORITE})
    };

    private onShowRemoveFavourite = (): void => {
        this.setState({currentSelectAction: SelectAction.REMOVE_FAVORITE})
    };

    private clearCurrentAction = (): void => {
        this.setState({
            currentSelectAction: SelectAction.NONE,
            selectedItemIds: []
        })
    };

    private addToSelectedItemIds = (newItemId: string): void => {
        const newSelectedItemIds: string[] = [...this.state.selectedItemIds];
        newSelectedItemIds.push(newItemId);
        this.setState({selectedItemIds: newSelectedItemIds})
    };

    private removeFromSelectedItemIds = (toRemoveItemId: string): void => {
        this.setState({
            selectedItemIds: this.state.selectedItemIds.filter(
                (selectedItemId) => selectedItemId !== toRemoveItemId)
        })
    };

    private onDeleteSelectedItems = (): void => {
        const {deleteOption, customTaskParams = []} = this.props;
        const {selectedItemIds} = this.state;
        if (deleteOption) {
            executeItemTask(
                // @ts-ignore, el tipo de constructor es diferente entre clase padre e hijo
                new deleteOption.Task(selectedItemIds, ...customTaskParams),
                () => this.getItems(undefined),
                I18nUtils.tr(TR_ELIMINADOS_CORRECTAMENTE));

        }
        this.clearCurrentAction();
    };

    private onChangeFavoriteSelectedItems = (): void => {
        const {TaskList} = this.props;
        const {selectedItemIds, currentSelectAction} = this.state;

        let currentEntity: FavoriteEntity | undefined;

        if (Object.is(TaskList, TaskDashboardList)) {
            currentEntity = 'dashboards'
        }
        if (Object.is(TaskList, TaskFormList)) {
            currentEntity = 'forms'
        }
        if (Object.is(TaskList, TaskDocumentList)) {
            currentEntity = 'documents'
        }

        if (!currentEntity) {
            throw new Error('current entity is not defined')
        }

        switch (currentSelectAction) {
            case SelectAction.ADD_FAVORITE:
                executeItemTask(
                    new TaskFavoriteAdd(selectedItemIds, currentEntity),
                    undefined,
                    I18nUtils.tr(TR_ANADIDOS_A_FAVORITOS_CORRECTAMENTE));
                break;
            case SelectAction.REMOVE_FAVORITE:
                executeItemTask(
                    new TaskFavoriteRemove(selectedItemIds, currentEntity),
                    () => this.getItems(),
                    I18nUtils.tr(TR_QUITADOS_DE_FAVORITOS_CORRECTAMENTE));
                break;
        }
        this.clearCurrentAction();
    };

    public render(): React.ReactNode {
        const {
            loading, title, subtitle, headerOptions = [],
            deleteOption, addRoute,
            addToFavorites, removeFromFavorites, children,
            favoritesManagerByList = true, hideSearcher = false
        } = this.props;

        const {headerSearch} = this.state;

        const allOptions: DropDownOption[] = [];

        if (favoritesManagerByList && addToFavorites) {
            allOptions.push({
                text: I18nUtils.tr(TR_ANADIR_A_FAVORITOS),
                onClick: this.onShowAddFavourite,
            })
        }
        if (favoritesManagerByList && removeFromFavorites) {
            allOptions.push({
                text: I18nUtils.tr(TR_QUITAR_DE_FAVORITOS),
                onClick: this.onShowRemoveFavourite,
            })
        }

        allOptions.push(...headerOptions);

        if (deleteOption) {
            allOptions.push({
                    text: I18nUtils.tr(deleteOption.title),
                    onClick: this.onShowDelete,
                }
            )
        }
        return (
            <Card loading={loading}>
                <CardHeader
                    title={title}
                    subtitle={subtitle}
                    searchText={headerSearch}
                    onSearchChange={(newSearch) => this.onHeaderSearch(newSearch)}
                    onRefresh={this.onRefresh}
                    options={allOptions.length === 0 ? undefined : allOptions}
                    onAdd={addRoute ? () => goToRoute(addRoute) : undefined}
                    hideSearcher={hideSearcher}
                >
                    {children}
                </CardHeader>
                <CardBody className={'table-responsive'}>
                    {this.renderCardBody()}
                </CardBody>
            </Card>
        )
    }

    private renderCardBody = (): React.ReactNode => {
        const {data, error, emptyListOptions, ItemsTable, pager, loading, addToFavorites, removeFromFavorites} = this.props;
        const {sort, currentSelectAction} = this.state;

        if ((!data || data.length === 0) && !loading) {
            return (
                <div className={'empty-msg-cnt'}>
                    <h4 className={'text-center m-t-30 m-b-30'}>{emptyListOptions.message}</h4>
                    {emptyListOptions.addHandler && <a
                        onClick={emptyListOptions.addHandler}>
                        <Icon
                            className={'empty-msg-icon'}
                            icon={'add_circle'}/>
                    </a>
                    }
                </div>
            );
        }
        if (error) {
            return <h4 className={'table-error'}>{error}</h4>;
        }

        const showSelect = currentSelectAction !== SelectAction.NONE;

        let selectActionTitle: string = '';
        let okHandler: () => void;

        switch (currentSelectAction) {
            case SelectAction.DELETE:
                selectActionTitle = I18nUtils.tr(TR_ELIMINAR);
                okHandler = this.onDeleteSelectedItems;
                break;
            case SelectAction.ADD_FAVORITE:
                selectActionTitle = I18nUtils.tr(TR_ANADIR_A_FAVORITOS);
                okHandler = this.onChangeFavoriteSelectedItems;
                break;
            case SelectAction.REMOVE_FAVORITE:
                selectActionTitle = I18nUtils.tr(TR_QUITAR_DE_FAVORITOS);
                okHandler = this.onChangeFavoriteSelectedItems;
                break;
            default:
                selectActionTitle = 'None';
                okHandler = this.clearCurrentAction;
        }

        let orderedData = data.sort(dynamicSort(sort.asc, sort.column));

        return (
            <React.Fragment>
                <ItemsTable
                    data={orderedData}
                    error={error}
                    sort={sort}
                    onSort={this.onSort}
                    renderSelectTd={this.renderSelectTd}
                    addToFavorites={addToFavorites}
                    removeFromFavorites={removeFromFavorites}
                    refreshListHandler={this.getItems}
                >
                    <SelectTh title={''} showSelect={showSelect}/>
                </ItemsTable>
                <Pager
                    data={pager}
                    onPage={(page) => this.onSetPage(page)}
                />
                {showSelect &&
                <CardOkCancelFooter okTitle={selectActionTitle}
                                    okHandler={okHandler}
                                    cancelHandler={this.clearCurrentAction}/>
                }
            </React.Fragment>
        )
    };

    private renderSelectTd = (itemId: string): React.ReactNode => {
        const {selectedItemIds, currentSelectAction} = this.state;

        const showSelect = currentSelectAction !== SelectAction.NONE;

        const selected = selectedItemIds.includes(itemId);

        const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
            event.stopPropagation();
            if (selected) {
                return this.removeFromSelectedItemIds(itemId)
            } else {
                return this.addToSelectedItemIds(itemId)
            }
        };

        return showSelect ? (
                <td className='td-checkbox'>
                    {/*
                    // @ts-ignore */}
                    <Checkbox
                        initRipple={() => null}
                        unbounded={false}
                        checked={selected}
                        onChange={onChangeHandler}
                        onClick={(event: SyntheticEvent<HTMLInputElement>) => event.stopPropagation()}
                    />
                </td>
            ) :
            null
    }
}