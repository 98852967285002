import * as React from 'react';
import {Component, ReactNode} from 'react';
import Label from "./Label";

export interface ActiveLabelProps {
    text: string
    active: boolean,
}

export default class ActiveLabel extends Component<ActiveLabelProps> {

    public render(): ReactNode {
        return (
            <Label
                text={this.props.text}
                color={this.props.active ? 'rgb(119, 119, 119)' : 'rgb(227, 227, 227)'}
                textColor={this.props.active ? 'rgb(255, 255, 255)' : 'rgb(158, 158, 158)'}
            />
        );
    }

}

