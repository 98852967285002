import * as React from 'react';
import {RouteComponentProps} from "react-router";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_NUEVO_USUARIO,
    TR_USUARIO,
    TR_USUARIO_CREADO_CORRECTAMENTE,
    TR_USUARIO_MODIFICADO_CORRECTAMENTE,
    TR_USUARIOS
} from "../../../../I18n/constants";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {
    ROUTE_ADMIN_CURRENT_USER_DETAIL,
    ROUTE_ADMIN_USER_ADD,
    ROUTE_ADMIN_USER_DETAIL,
    ROUTE_ADMIN_USERS
} from "../../../../routing/Routes";
import {connect} from "react-redux";
import UserFormScreenAdminReducer from "./UserFormScreenAdminReducer";
import UserCardFormAdmin, {UserCardFormAdminData} from "../formdetail/UserCardFormAdmin";
import User, {LinkData} from "../../../../model/User";
import TaskUserDetail from "../../../../ws/user/TaskUserDetail";
import TaskUserAdd from "../../../../ws/user/TaskUserAdd";
import TaskUserEdit from "../../../../ws/user/TaskUserEdit";
import UserRole, {UserRoleAutocompleteBuilder} from "../../../../model/UserRole";
import UserFormAdminScreenActions from "./UserFormAdminScreenActions";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import {MenuIcon} from "../../../../Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {UserPermissionSectionAdminHelpers} from "../formdetail/cards/permission/UserPermissionSectionAdmin";
import {ReducerBuilder} from "co-redux-builders";
import ExploitationRoleFieldArrayReducer from "../../userGroups/form/ExploitationRoleFieldArrayReducer";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    UserFormScreenAdminReducer.autoMapToProps(),
    ExploitationRoleFieldArrayReducer.autoMapToProps(),
);
const mapActionsToProps = UserFormAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class UserFormAdminScreen extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.state = {modal: false};
    }

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskUserDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: UserCardFormAdminData): void => {
        const userId = this.props.match.params.id;

        let submitData: LinkData = {
            ...UserPermissionSectionAdminHelpers.getModelData(values),
            name: values.name,
            last_name: values.last_name,
            email: values.email,
        };

        if (userId) {
            executeItemTask(
                new TaskUserEdit(userId, submitData),
                () => goToRoute(ROUTE_ADMIN_USERS),
                I18nUtils.tr(TR_USUARIO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            submitData = {
                ...submitData,
                password: values.password,
            };
            executeItemTask(new TaskUserAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_USERS),
                I18nUtils.tr(TR_USUARIO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const userId = this.props.match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_USUARIOS),
            url: ROUTE_ADMIN_USERS,
            icon: MenuIcon.USER
        }];

        if (userId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_USUARIO),
                url: generateRoute(ROUTE_ADMIN_USER_DETAIL, {id: userId}),
                icon: MenuIcon.USER
            });
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_USUARIO),
                url: ROUTE_ADMIN_USER_ADD,
                icon: MenuIcon.USER
            });
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderUserForm()}
            </ScreenCard>
        );

    }

    private renderUserForm(): React.ReactNode {
        const {userFormScreenLoading, user, match, roleListLoading, roleList} = this.props;

        let initialValues: UserCardFormAdminData | undefined;
        let userId: string | undefined;

        if (user) {
            const userData: User = user;

            initialValues = {
                name: userData.name ? userData.name : '',
                last_name: userData.last_name ? userData.last_name : '',
                email: userData.email ? userData.email : '',
                password: userData.password ? userData.password : '',
                roles: userData.roles ? userData.roles.map(
                    (userRole: UserRole) => UserRoleAutocompleteBuilder.buildFieldArrayOption(userRole)) : [],
                groups: userData.groups ? userData.groups.map((group) => group.id) : []
            };
            userId = this.props.match.params.id ? this.props.match.params.id : '';
        }

        return (
            <UserCardFormAdmin
                userId={userId}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                isCurrentUser={match.path === ROUTE_ADMIN_CURRENT_USER_DETAIL}
                roleList={roleList}
                parentLoading={roleListLoading || userFormScreenLoading}
                needsPassword={!user}
            />)
    }
}

export default connect(mapStateToProps, mapActionsToProps)(UserFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
