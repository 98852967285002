import {ReducerBuilder} from "co-redux-builders";
import CompareGraphicsActions from "./CompareGraphicsActions";
import {Chart, DashboardDetail} from "../../model/Dashboard";
import TaskDashboardDetail from "../../ws/dashboard/TaskDashboardDetail";
import TaskDashboardCompare from "../../ws/dashboard/TaskDashboardCompare";

interface CompareGraphicsReducerState {
	dashboard?: DashboardDetail,
	charts : Array<Chart>,
	loadingCharts: boolean,
	loading: boolean,
	errors: string,
}

const INITIAL_STATE: CompareGraphicsReducerState = {
	dashboard: undefined,
	charts: [],
	loadingCharts: false,
	loading: false,
	errors: '',
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
	.onEvent(CompareGraphicsActions.dispatchClearReducer, () => INITIAL_STATE)

	.onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
	.onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
	.onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) =>
		({...oldState, errors: payload}))

	.onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
		return {...oldState, dashboard: payload.data, errors: ''}
	})

	.onEvent(TaskDashboardCompare.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
		const newState = {...oldState};

		const charts: Chart [] = [];

		charts.push(payload.data["1"], payload.data["2"])

		newState.charts = charts;

		return newState;
	})

	.build()