import {AutocompleteOption} from "../components/form/FormAutocomplete";

export interface CompanyType {
    id: string,
    name: string
}

export class CompanyTypeAutocompleteBuilder {

    public static buildOption(company: CompanyType): AutocompleteOption<CompanyType> {
        return ({
            name: company.name,
            value: company.id,
            data: company
        })
    }
}