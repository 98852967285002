import * as React from 'react';
import {compose} from "redux";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../components/card/Card";
import CardHeader from "../../../components/card/CardHeader";
import I18nUtils from "../../../I18n/I18nUtils";
import CardBody from "../../../components/card/CardBody";
import Row from "../../../components/Row";
import {connect} from "react-redux";
import FormResultFormScreenReducer from "./FormResultFormScreenReducer";
import {goToRoute} from "../../../utils/Router";
import FormCardFooter from "../../../components/form/FormCardFooter";
import {
    TR_CARACTERES,
    TR_EL_MAIL_NO_ES_VALIDO,
    TR_EL_TEXTO_NO_DEBE_CONTENER_MAS_DE,
    TR_EL_VALOR_NO_DEBE_SER_MAYOR_DE,
    TR_EL_VALOR_NO_DEBE_SER_MENOR_DE,
    TR_EXPLOTACION,
    TR_EXPLOTACION_OBLIGATORIO,
    TR_FECHA_OBLIGATORIA,
    TR_LA_URL_NO_TIENE_EL_FORMATO_ADECUADO,
    TR_OBLIGATORIO,
    TR_YA_EXISTE_UNA_RESPUESTA_EN_ESTA_FECHA,
} from "../../../I18n/constants";
import FormInput from "../../../components/form/FormInput";
import {FormResultDynamicVariables, FormResultValue} from "../../../model/FormResult";
import FormField from "../../../model/FormField";
import {FormFieldTypeValue} from "../../../model/FormFieldType";
import {isEmailValid, isValidURL} from "../../../utils/StringUtils";
import {FormColProps} from "../../../components/form/FormCol";
import FormTextArea from "../../../components/form/FormTextArea";
import FormDragFile from "../../../components/form/FormDragFile";
import FormSelect from "../../../components/form/FormSelect";
import FormCheckbox from "../../../components/form/FormCheckbox";
import FormCheckboxGroup from "../../../components/form/FormCheckboxGroup";
import FormRadioGroup from "../../../components/form/FormRadioGroup";
import FormSwitch from "../../../components/form/FormSwitch";
import FormDate from "../../../components/form/FormDate";
import DateFormatter from "../../../utils/DateFormatter";
import TaskFormResultCheckFrequency from "../../../ws/formResult/TaskFormResultCheckFrequency";
import {FormFrequencies} from "../../../model/FormFrequency";
import {DateHelpers} from "../../../utils/DateUtils";
import {ReducerBuilder} from "co-redux-builders";
import CurrentUserReducer from "../../../redux/CurrentUserReducer";
import FormInputOption from "../../../components/form/FormInputOption";
import FormCurrency from "../../../components/form/FormCurrency";
import FormTotalizer from '../../../components/form/FormTotalizer';
import FormCheckDay from "../../../components/form/FormCheckDay"

const FORM_NAME = 'FormResultCardForm';
//
// Hago una variable para almacenar el nombre del input con la fecha
let inputDate = "";
//
type FormResultCardFormDataValue = FormResultValue | string[]

enum FormResultCardFormFields {
    formFieldDate = "formDate",
    formExploitationId = "exploitationId",
}

export interface FormResultCardFormData {
    [formFieldKey: string]: FormResultCardFormDataValue,

    formDate: string,
    exploitationId: string,
}

interface FormCardFormProps {
    readonly?: boolean,
    onSubmit: (data: FormResultCardFormData) => void,
    initialValues: Partial<FormResultCardFormData>,
    goBackRoute: string,
}

interface FormResultCardFormState {
    selectFieldInitiated: boolean,
    exploitationOptions: FormInputOption[],
    dateError: string | undefined,
    totals: string[]
}

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    FormResultFormScreenReducer.autoMapToProps(),
    CurrentUserReducer.autoMapToProps()
);

type Props = FormCardFormProps & typeof mapStateToProps & InjectedFormProps<FormResultCardFormData>

class FormResultCardForm extends React.Component<Props, FormResultCardFormState> {
    private fieldCounter = 0;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            selectFieldInitiated: false,
            exploitationOptions: [],
            dateError: undefined,
            totals: []
        }
        this.handleChange = this.handleChange.bind(this);
    }

    public componentDidMount() {
        const {formModel} = this.props;

        if (formModel && formModel.form_frequency.id === FormFrequencies.ONCE) {
            const submitData: FormResultDynamicVariables = {
                exploitation_id: formModel.id,
            };

            this.handleCheckFrequency(formModel.id, submitData);
        }
    }

    private setDateError = (message?: string): void => {
        this.setState({dateError: message})
    };

    private handleCheckFrequency(id: string, submitData: FormResultDynamicVariables): void {
        new TaskFormResultCheckFrequency(id, submitData)
            .onSuccess(
                (response) => {
                    if (response.data.has_been_written) {
                        this.setDateError(I18nUtils.tr(TR_YA_EXISTE_UNA_RESPUESTA_EN_ESTA_FECHA));
                    } else {
                        this.setDateError();
                    }
                }
            )
            .execute();
    }

    private handleOnDateChange = (date: Date | string) => {
        const {formModel} = this.props;

        if (formModel) {
            const submitData: FormResultDynamicVariables = {
                exploitation_id: formModel.id,
                datetime_value: typeof (date) !== "string" ? DateFormatter.formatInputDate(date) : date
            };

            this.handleCheckFrequency(formModel.id, submitData);
        }
    };

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, invalid, loading, pristine, formModel, formResult, goBackRoute
        } = this.props;

        const {dateError} = this.state;

        const title = formModel ? formModel.name : '';

        this.fieldCounter = 0;

        return (
            <Card loading={loading}>
                <CardHeader
                    title={title}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            {this.renderExploitationField()}
                        </Row>
                        <Row>
                            {this.renderDateField()}
                        </Row>
                        {formModel && formModel.form_fields.map((formField, index) => this.renderField(formField))}
                        <FormCardFooter
                            invalid={invalid || !!dateError}
                            pristine={pristine}
                            isUpdate={!!formResult}
                            cancelHandler={() => goToRoute(goBackRoute)} />
                    </form>

                </CardBody>
            </Card>
        )
    }

    private renderExploitationField(): React.ReactNode {
        const {formModel} = this.props;

        if (formModel) {
            const {currentUser} = this.props;

            if (currentUser && currentUser.roles) {
                let exploitationOptions: FormInputOption [] = [];

                currentUser.roles.map(role => {
                    if (role.exploitation) {
                        const id = role.exploitation.id;
                        const name = role.exploitation.name;
                        const idNotExist = exploitationOptions.filter(option => option.value === id).length === 0;

                        if (idNotExist) {
                            exploitationOptions.push({
                                value: id,
                                name: name
                            })
                        }
                    }
                });

                if (exploitationOptions.length > 0) {
                    return (
                        <Field
                            name={FormResultCardFormFields.formExploitationId}
                            label={I18nUtils.tr(TR_EXPLOTACION)}
                            component={FormSelect}
                            options={exploitationOptions}
                            col={{md: 12, lg: 12}}
                            blankOptionText={' '}
                        />
                    )
                }
            }
        }

        return null;
    }

    private renderDateField = (): React.ReactNode => {
        const {formModel} = this.props;

        if (formModel && formModel.form_frequency) {
            const label = formModel.form_frequency.name;

            switch (formModel.form_frequency.id) {
                case FormFrequencies.NONE:
                    return null;
                case FormFrequencies.ONCE:
                    return null;
                case FormFrequencies.DAILY:
                    return this.renderDatePickerField(label);
                case FormFrequencies.WEEKLY:
                    return this.renderDatePickerField(label);
                case FormFrequencies.MONTHLY:
                    return this.renderDatePickerField(label);
                case FormFrequencies.QUARTERLY:
                    return this.renderQuarterlyField(label);
                case FormFrequencies.YEARLY:
                    return this.renderInputDateField(label);
                default:
                    return null;
            }
        }

        return null;
    };

    private renderDatePickerField(label: string): React.ReactNode {
        const {dateError} = this.state;

        return (
            <Field
                label={label}
                name={FormResultCardFormFields.formFieldDate}
                component={FormDate}
                onDateChange={(value: Date | undefined) => {
                    if (value) {
                        this.handleOnDateChange(value);
                    }
                }}
                col={{md: 6, lg: 6}}
                forceErrorMessage={dateError}
            />
        );
    }

    private renderInputDateField(label: string): React.ReactNode {
        const {initialValues} = this.props;

        if (initialValues.formDate) {
            const year = new Date(initialValues.formDate).getFullYear();
            this.props.change(FormResultCardFormFields.formFieldDate, year);
        }

        return (
            <Field
                label={label}
                name={FormResultCardFormFields.formFieldDate}
                component={FormInput}
                type={"number"}
                col={{md: 6, lg: 6}}
            />
        )
    }

    private renderQuarterlyField(label: string): React.ReactNode {
        const {initialValues} = this.props;

        const firstQuarterly = DateHelpers.getFirstDayOfTrimester(new Date().getFullYear(), 1);
        const secondQuarterly = DateHelpers.getFirstDayOfTrimester(new Date().getFullYear(), 2);
        const thirdQuarterly = DateHelpers.getFirstDayOfTrimester(new Date().getFullYear(), 3);
        const fourthQuarterly = DateHelpers.getFirstDayOfTrimester(new Date().getFullYear(), 4);

        if (initialValues.formDate && !this.state.selectFieldInitiated) {
            this.setState({selectFieldInitiated: true})
            const date = DateFormatter.formatDate(initialValues.formDate);
            const month = Number(date.substring(0, 2));

            switch (month) {
                case 1:
                    this.props.change(FormResultCardFormFields.formFieldDate, firstQuarterly);
                    break;
                case 4:
                    this.props.change(FormResultCardFormFields.formFieldDate, secondQuarterly);
                    break;
                case 7:
                    this.props.change(FormResultCardFormFields.formFieldDate, thirdQuarterly);
                    break;
                case 10:
                    this.props.change(FormResultCardFormFields.formFieldDate, fourthQuarterly);
                    break;
            }
        }

        return (
            <Field
                label={label}
                name={FormResultCardFormFields.formFieldDate}
                component={FormSelect}
                options={[
                    {name: "Primer trimestre", value: firstQuarterly},
                    {name: "Segundo trimestre", value: secondQuarterly},
                    {name: "Tercer trimestre", value: thirdQuarterly},
                    {name: "Cuarto trimestre", value: fourthQuarterly},
                ]}
                onChange={(event: any) => {
                    const value = event.target.value;
                    if (value !== "-1") this.handleOnDateChange(value);
                }}
                blankOptionText={' '}
                col={{md: 6, lg: 6}}
            />
        );
    }

    private handleChange(event) {
        let totals = { ...this.state.totals }
        if (event.target !== undefined) {
            totals[event.target.name] = event.target.value;
            this.setState({ totals });
        }
    }

    private renderField = (formField: FormField): React.ReactNode => {
        if (formField.form_field_type.id === FormFieldTypeValue.NONE) {
            throw new Error('field None is not supported')
        }
        let FieldComponent: React.ComponentType<any> = FormInput;
        let columnConfig: FormColProps = {md: 6, lg: 6};
        const customProps: { [prop: string]: any } = {};
        customProps.obligatory = formField.required;

        switch (formField.form_field_type.id) {
            case FormFieldTypeValue.TEXT:
                if (formField.validator.maxLength) {
                    customProps.maxLength = formField.validator.maxLength;
                }
                break;
            case FormFieldTypeValue.TEXTAREA:
                FieldComponent = FormTextArea;
                if (formField.validator.maxLength) {
                    customProps.maxLength = formField.validator.maxLength;
                }
                break;
            case FormFieldTypeValue.CURRENCY:
                FieldComponent = FormCurrency;
                customProps.type = 'currency';
                customProps.onChange= this.handleChange
                break;
            case FormFieldTypeValue.NUMBER:
                customProps.type = 'number';
                if (formField.validator.min) {
                    customProps.min = formField.validator.min;
                }
                if (formField.validator.max) {
                    customProps.max = formField.validator.max;
                }
                customProps.onChange= this.handleChange
                break;
            case FormFieldTypeValue.EMAIL:
                customProps.type = 'email';
                break;
            case FormFieldTypeValue.URL:
                customProps.type = 'url';
                break;
            case FormFieldTypeValue.DATE:
                inputDate = formField.key;
                customProps.type = 'date';
                break;
            case FormFieldTypeValue.TIME:
                customProps.type = 'time';
                break;
            case FormFieldTypeValue.DATETIME:
                customProps.type = 'datetime-local';
                break;
            case FormFieldTypeValue.IMAGE:
                FieldComponent = FormDragFile;
                customProps.fileTypes = [
                    'image/*'
                ];
                customProps.multiple = true;
                customProps.fileIcon = 'image';
                columnConfig = {md: 12, lg: 12};
                break;
            case FormFieldTypeValue.VIDEO:
                FieldComponent = FormDragFile;
                customProps.fileTypes = [
                    'video/*'
                ];
                customProps.multiple = true;
                customProps.fileIcon = 'movie';
                columnConfig = {md: 12, lg: 12};
                break;
            case FormFieldTypeValue.AUDIO:
                FieldComponent = FormDragFile;
                customProps.fileTypes = [
                    'audio/*'
                ];
                customProps.multiple = true;
                customProps.fileIcon = 'audiotrack';
                columnConfig = {md: 12, lg: 12};
                break;
            case FormFieldTypeValue.FILE:
                FieldComponent = FormDragFile;
                customProps.fileTypes = [
                    "text/plain",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ".csv",
                    "text/csv",
                    "application/vnd.ms-excel",
                    "application/csv",
                    "text/x-csv",
                    "application/x-csv",
                    "text/comma-separated-values",
                    "text/x-comma-separated-values"
                ];
                customProps.multiple = false;
                customProps.fileIcon = 'attach_file';
                columnConfig = {md: 12, lg: 12};
                break;
            case FormFieldTypeValue.SELECT:
                FieldComponent = FormSelect;
                customProps.options = formField.form_field_values;
                customProps.blankOptionText = ' ';
                break;
            case FormFieldTypeValue.CHECKBOX:
                FieldComponent = FormCheckbox;
                break;
            case FormFieldTypeValue.CHECKBOXGROUP:
                FieldComponent = FormCheckboxGroup;
                customProps.options = formField.form_field_values;
                break;
            case FormFieldTypeValue.RADIOGROUP:
                FieldComponent = FormRadioGroup;
                customProps.options = formField.form_field_values;
                break;
            case FormFieldTypeValue.SWITCH:
                FieldComponent = FormSwitch;
                if (formField.form_field_values) {
                    customProps.labelEnabled = formField.form_field_values[0].name;
                    customProps.labelDisabled = formField.form_field_values[1].name;
                }
                break;
            case FormFieldTypeValue.TOTALIZER:
                FieldComponent = FormTotalizer;
                customProps.totals = this.state.totals;
                break;
            case FormFieldTypeValue.CHECKDAY:
                FieldComponent = FormCheckDay;
                customProps.inputAndText = formField.label;
                //
                // Verifico si el valor del input de la fecha ya se ha rederizado
                if ( inputDate !== "" ){
                    //
                    // Si tiene valor, paso el nombre del input a checkDay
                    customProps.fieldKey = inputDate;
                    //
                } else {
                    //
                    customProps.fieldKey = formField.key;
                    //
                }
                //
                console.log(`form_field_values: ${formField.form_field_values}`);
                console.log(`formModel: ${this.props.formModel}`);
                console.log(`formFieldKey: ${formField.key}`);
                //
                if (this.props.formModel !== undefined){
                    console.log(`key: ${this.props.formModel.key}`);
                    customProps.formId = this.props.formModel.id || 0;
                }
            default:
                break;
        }

        const countThisField = formField.form_field_type.id !== FormFieldTypeValue.CHECKBOX && formField.form_field_type.id !== FormFieldTypeValue.TOTALIZER && formField.form_field_type.id !== FormFieldTypeValue.CHECKDAY;

        if (countThisField) {
            this.fieldCounter += 1;
        }

        return (
                <Row key={formField.key} className={'question'}>
                    <Field
                        {...customProps}
                        label={countThisField ?
                            `${this.fieldCounter}. ${formField.label}` :
                            formField.label
                        }
                        name={formField.key}
                        component={FieldComponent}
                        col={columnConfig}
                    />
                </Row>
        )
    };
}

function validateFormField(formField: FormField, value: FormResultCardFormDataValue): string {
    let error = '';

    if (value) {
        switch (formField.form_field_type.id) {
            case FormFieldTypeValue.TEXT:
            case FormFieldTypeValue.TEXTAREA:
                if (typeof value === 'string' && formField.validator.maxLength && value.length > Number(formField.validator.maxLength)) {
                    error = `${I18nUtils.tr(TR_EL_TEXTO_NO_DEBE_CONTENER_MAS_DE)} ${formField.validator.maxLength} ${I18nUtils.tr(TR_CARACTERES)}`
                }
                break;
            case FormFieldTypeValue.NUMBER:
                if (value && formField.validator.max && Number(value) > Number(formField.validator.max)) {
                    error = `${I18nUtils.tr(TR_EL_VALOR_NO_DEBE_SER_MAYOR_DE)} ${formField.validator.max}`
                }
                if (value && formField.validator.min && Number(value) < Number(formField.validator.min)) {
                    error = `${I18nUtils.tr(TR_EL_VALOR_NO_DEBE_SER_MENOR_DE)} ${formField.validator.min}`
                }
                break;
            case FormFieldTypeValue.IMAGE:
            case FormFieldTypeValue.VIDEO:
            case FormFieldTypeValue.AUDIO:
            case FormFieldTypeValue.CHECKBOXGROUP:
                if (formField.required && (!Array.isArray(value) || value.length === 0)) {
                    error = I18nUtils.tr(TR_OBLIGATORIO);
                }
                break;
            case FormFieldTypeValue.SELECT:
                if (formField.required && value === '-1') {
                    error = I18nUtils.tr(TR_OBLIGATORIO);
                }
                break;
            case FormFieldTypeValue.EMAIL:
                if (!isEmailValid(String(value))) {
                    error = I18nUtils.tr(TR_EL_MAIL_NO_ES_VALIDO);
                }
                break;
            case FormFieldTypeValue.URL:
                if (!isValidURL(String(value))) {
                    error = I18nUtils.tr(TR_LA_URL_NO_TIENE_EL_FORMATO_ADECUADO);
                }
                break;
            default:
                break;
        }
    } else if (formField.required) {
        const formFieldTypeId = formField.form_field_type.id;

        if (formFieldTypeId !== FormFieldTypeValue.CHECKBOX && formFieldTypeId !== FormFieldTypeValue.SWITCH) {
            error = I18nUtils.tr(TR_OBLIGATORIO);
        }
    }

    return error;
}

function validate(values: FormResultCardFormData, props: FormCardFormProps & typeof mapStateToProps) {
    const {formModel} = props;
    const errors: FormErrors<FormResultCardFormData> = {};

    if (formModel) {
        formModel.form_fields.forEach((formField) => {
            errors[formField.key] = validateFormField(formField, values[formField.key]);
        })
    }
    if (!values.formDate || values.formDate === "-1") {
        errors.formDate = I18nUtils.tr(TR_FECHA_OBLIGATORIA);
    }
    if (!values.exploitationId || values.exploitationId === "-1") {
        errors.exploitationId = I18nUtils.tr(TR_EXPLOTACION_OBLIGATORIO);
    }
    return errors;
}

export default compose(
    connect(mapStateToProps),
    reduxForm<FormResultCardFormData, FormCardFormProps & typeof mapStateToProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    })
)(FormResultCardForm as any) as unknown as React.ComponentType<FormCardFormProps>;
