import * as React from 'react';
import LoggedScreen from "../../../../base/screens/LoggedScreen";
import {RouteComponentProps} from "react-router";
import I18nUtils from "../../../../I18n/I18nUtils";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {connect} from "react-redux";
import ScreenContent from "../../../../components/screen/ScreenContent";
import Row from "../../../../components/Row";
import Col from "../../../../components/Col";
import BusinessTypeFormAdminScreenReducer from "./BusinessTypeFormAdminScreenReducer";
import BusinessTypeCardFormAdmin, {BusinessTypeCardFormAdminData} from "./BusinessTypeCardFormAdmin";
import {MenuIcon} from "../../../../Config";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import BusinessTypeFormAdminScreenActions from "./BusinessTypeFormAdminScreenActions";
import {
    TR_NUEVO_TIPO_DE_EXPLOTACION,
    TR_TIPO_DE_EXPLOTACION,
    TR_TIPO_DE_EXPLOTACION_CREADO_CORRECTAMENTE,
    TR_TIPO_DE_EXPLOTACION_MODIFICADO_CORRECTAMENTE,
    TR_TIPOS_DE_EXPLOTACION
} from "../../../../I18n/constants";
import {
    ROUTE_ADMIN_BUSINESS_TYPE_ADD,
    ROUTE_ADMIN_BUSINESS_TYPE_DETAIL,
    ROUTE_ADMIN_BUSINESS_TYPES
} from "../../../../routing/Routes";
import TaskBusinessTypeDetail from "../../../../ws/businessType/TaskBusinessTypeDetail";
import {BusinessTypeData} from "../../../../model/BusinessType";
import TaskBusinessTypeEdit from "../../../../ws/businessType/TaskBusinessTypeEdit";
import TaskBusinessTypeAdd from "../../../../ws/businessType/TaskBusinessTypeAdd";

const mapStateToProps = BusinessTypeFormAdminScreenReducer.autoMapToProps();
const mapActionsToProps = BusinessTypeFormAdminScreenActions.autoMapToProps();

type ZoneFormAdminScreenProps = RouteComponentProps<{ id: string }>

type Props = typeof mapStateToProps & ZoneFormAdminScreenProps & typeof mapActionsToProps

class BusinessTypeFormAdminScreen extends LoggedScreen<Props> {

    constructor(props: Props, context: any) {
        super(props, context);
        this.addBreadcrumbScreen(I18nUtils.tr(TR_TIPOS_DE_EXPLOTACION), ROUTE_ADMIN_BUSINESS_TYPES, MenuIcon.BUSINESS_TYPE);
        if (this.props.match.params.id) {
            this.addBreadcrumbScreen(I18nUtils.tr(TR_TIPO_DE_EXPLOTACION), generateRoute(
                ROUTE_ADMIN_BUSINESS_TYPE_DETAIL,
                {id: this.props.match.params.id}),
                MenuIcon.BUSINESS_TYPE);
        } else {
            this.addBreadcrumbScreen(I18nUtils.tr(TR_NUEVO_TIPO_DE_EXPLOTACION), ROUTE_ADMIN_BUSINESS_TYPE_ADD, MenuIcon.BUSINESS_TYPE)
        }
    }

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskBusinessTypeDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: BusinessTypeCardFormAdminData): void => {
        const existBusinessType = this.props.match.params.id !== undefined;

        const submitData: BusinessTypeData = {
            name: values.name,
            elk_value: values.elk_value,
        };

        if (existBusinessType) {
            executeItemTask(
                new TaskBusinessTypeEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_ADMIN_BUSINESS_TYPES),
                I18nUtils.tr(TR_TIPO_DE_EXPLOTACION_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskBusinessTypeAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_BUSINESS_TYPES),
                I18nUtils.tr(TR_TIPO_DE_EXPLOTACION_CREADO_CORRECTAMENTE)
            )
        }
    };

    public renderScreen(): React.ReactNode {
        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        {this.renderBusinessTypeForm()}
                    </Col>
                </Row>
            </ScreenContent>
        );
    }

    private renderBusinessTypeForm(): React.ReactNode {
        const {businessTypeFormScreenLoading, businessType} = this.props;

        if (businessTypeFormScreenLoading) {
            return null;
        }

        let initialValues: BusinessTypeCardFormAdminData | undefined;
        let businessTypeId: string | undefined;

        if (businessType) {
            initialValues = {
                name: businessType.name ? businessType.name : '',
                elk_value: businessType.elk_value ? businessType.elk_value : '',
            };
            businessTypeId = this.props.match.params.id ? this.props.match.params.id : '';
        }

        return (
            <BusinessTypeCardFormAdmin
                businessTypeId={businessTypeId}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
            />)
    }
}

export default connect(mapStateToProps, mapActionsToProps)(BusinessTypeFormAdminScreen as unknown as React.ComponentType<ZoneFormAdminScreenProps>);
