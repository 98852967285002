import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import BusinessType from "../../model/BusinessType";

type BusinessTypeCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskBusinessTypeList extends BaseGenericRequest<BusinessTypeCriteriaProps, TaskResponse<BusinessType[]>> {

    private readonly data: BusinessTypeCriteriaProps;

    public constructor(criteria: BusinessTypeCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_BUSINESS_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): BusinessTypeCriteriaProps {
        return this.data;
    }

}