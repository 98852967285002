import * as React from 'react';
import {ChangeEventHandler, Component, FocusEventHandler, ReactNode} from 'react';
import classNames from "classnames";
import FormInputOption from "./form/FormInputOption";

export interface SelectProps {
    label: string,
    value: string,
    options: FormInputOption[],
    name?: string,
    error?: string,
    disabled?: boolean,
    onChange?: ChangeEventHandler<HTMLSelectElement>,
    onFocus?: FocusEventHandler<HTMLSelectElement>,
    onBlur?: FocusEventHandler<HTMLSelectElement>,
    classFormGroup?: string,
    classFormLine?: string,
    classSelect?: string,
    emptyOption?: boolean,
}

export default class Select extends Component<SelectProps> {

    public render(): ReactNode {
        const classFormGroup = classNames('form-group', this.props.classFormGroup);
        const classFormLine = classNames('form-line', this.props.classFormLine);
        const classSelectComponent = classNames('form-control', 'show-tick', this.props.classSelect);
        return (
            <div className={classFormGroup}>
                <div className={classFormLine}>
                    <label>{this.props.label}</label>
                    <select
                        className={classSelectComponent}
                        name={this.props.name}
                        disabled={this.props.disabled}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        onFocus={this.props.onFocus}
                        onBlur={this.props.onBlur}
                    >
                        {this.props.emptyOption && <option key={0} value={0}/>}
                        {this.props.options.map((option: FormInputOption) => <option key={option.value} value={option.value}>{option.name}</option>)}
                    </select>
                </div>
                <label className="error">{this.props.error || ''}</label>
            </div>
        );
    }

}

