import * as React from "react";
import createHistory from "history/createBrowserHistory";
import {routerMiddleware} from "react-router-redux";
import {ReduxStorageUtils} from "co-redux-builders";
import {applyMiddleware, createStore, compose} from "redux";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {History} from "history";
import AuthManager from "./utils/AuthManager";
import CombinedReducers from "./redux/CombinedReducers";
import Routing from "./routing/Routing";
import IdleTimer from 'react-idle-timer';
import "./App.scss";
import {createRef} from "react";
import Config from "./Config";
import {goToRoute} from "./utils/Router";
import {ROUTE_LOGIN} from "./routing/Routes";

class App extends React.Component<{}> {

    private idleTimerRef = createRef<IdleTimer>();

    protected history: History;

    public componentWillMount() {
        this.history = createHistory();
        const middleware = routerMiddleware(this.history);

        // Apoyo visual para el desarrollo
        // @ts-ignore

        ReduxStorageUtils.initialize(createStore(CombinedReducers,compose(applyMiddleware(thunk, middleware), window.devToolsExtension ? window.devToolsExtension() : f => f)));//((((applyMiddleware(thunk, middleware)))))));
        AuthManager.initialized();
    }

    public onIdle = (event) => {
        AuthManager.logout();
        goToRoute(ROUTE_LOGIN);
        // recargamos por temas de seguridad
        window.location.reload();
    };

    public render() {
        return (
            <>
                <IdleTimer
                    ref={this.idleTimerRef}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={1000 * 60 * Config.INACTIVITY_TIMEOUT_MIN}/>
                <Provider store={ReduxStorageUtils.getStorage()}>
                    <Routing history={this.history}/>
                </Provider>
            </>
        );
    }

}

export default App;
