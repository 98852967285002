import * as React from 'react';
import {compose} from "redux";
import {Field, FieldArray, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import {ReducerBuilder} from "co-redux-builders";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_AL_MENOS_DEBE_TENER_UN_ROL_ASIGNADO,
    TR_GRUPO_DE_USUARIOS,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVO_GRUPO_DE_USUARIOS,
    TR_SELECCIONA_UN_USUARIO,
    TR_YA_HA_SIDO_ANADIDO_ESTE_USUARIO,
} from "../../../../I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import {connect} from "react-redux";
import Col from "../../../../components/Col";
import {FieldArrayErrors, formStateAutoMapToPropsFactory} from "../../../../utils/FormUtils";
import {UserSimply} from "../../../../model/User";
import {UserRoleFieldArrayOption} from "../../../../model/UserRole";
import UserGroupFormAdminScreenReducer from "./UserGroupFormAdminScreenReducer";
import UserFieldArray from "./UserFieldArray";
import TaskUserList from "../../../../ws/user/TaskUserList";
import {AutocompleteOption} from "../../../../components/form/FormAutocomplete";
import ExploitationRoleFieldArray, {ExploitationRoleFieldArrayHelpers} from "./ExploitationRoleFieldArray";
import {goToRoute} from "../../../../utils/Router";
import {ROUTE_ADMIN_USER_GROUPS} from "../../../../routing/Routes";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import Role from "../../../../model/Role";

const FORM_NAME = 'UserGroupCardFormAdmin';

export type UserAutocompleteOptions = Array<AutocompleteOption<UserSimply>>

export interface UserGroupCardFormAdminData {
    name: string,
    roles: UserRoleFieldArrayOption[],
    users: UserAutocompleteOptions[],
}

enum UserGroupCardFormAdminFields {
    NAME = "name",
    ROLES = "roles",
    USERS = "users",
}

interface UserGroupCardFormAdminProps {
    userGroupId?: string,
    readonly?: boolean,
    onSubmit: (data: UserGroupCardFormAdminData) => void,
    initialValues?: Partial<UserGroupCardFormAdminData>,
    roleList: Role[],
    parentLoading: boolean,
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<UserGroupCardFormAdminData>(FORM_NAME);

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    mapFormStateToProps,
    UserGroupFormAdminScreenReducer.autoMapToProps(),
);

type Props = UserGroupCardFormAdminProps & typeof mapStateToProps & InjectedFormProps<UserGroupCardFormAdminData>

class UserGroupCardFormAdmin extends React.Component<Props> {

    public UNSAFE_componentWillMount(): void {
        new TaskUserList().execute();
    }

    public render(): React.ReactNode {
        const {
            parentLoading, handleSubmit, onSubmit, initialValues, invalid, userGroupFormScreenLoading, pristine
        } = this.props;

        return (
            <Card loading={parentLoading || userGroupFormScreenLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_GRUPO_DE_USUARIOS : TR_NUEVO_GRUPO_DE_USUARIOS)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={UserGroupCardFormAdminFields.NAME}
                                // @ts-ignore
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <Row>
                            <Col md={4} lg={4}>
                                <FieldArray
                                    name={UserGroupCardFormAdminFields.USERS}
                                    component={UserFieldArray}
                                />
                            </Col>
                            <Col md={8} lg={8} className={'right-field'}>
                                <FieldArray
                                    name={UserGroupCardFormAdminFields.ROLES}
                                    component={ExploitationRoleFieldArray}
                                    hideAdminRoles={false}
                                />
                            </Col>
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_USER_GROUPS)}/>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validateUsersArray(values: UserGroupCardFormAdminData): string[] {
    const processedUserIds: string[] = [];

    const allUsersErrors: string[] = [];

    values.users.forEach((userOptions, userIndex) => {
        if (userOptions && userOptions[0]) {

            const selectedUser = userOptions[0];

            if (processedUserIds.includes(selectedUser.value)) {
                allUsersErrors[userIndex] = I18nUtils.tr(TR_YA_HA_SIDO_ANADIDO_ESTE_USUARIO)
            } else {
                processedUserIds.push(selectedUser.value);
            }
        } else {
            allUsersErrors[userIndex] = I18nUtils.tr(TR_SELECCIONA_UN_USUARIO)
        }
    });
    return allUsersErrors;
}

function validate(values: UserGroupCardFormAdminData, props: UserGroupCardFormAdminProps) {
    const errors: FormErrors<UserGroupCardFormAdminData, FieldArrayErrors> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (Array.isArray(values.users)) {
        errors.users = validateUsersArray(values);
    }
    if (!values.roles || values.roles.length === 0) {
        errors.roles = {_error: I18nUtils.tr(TR_AL_MENOS_DEBE_TENER_UN_ROL_ASIGNADO)};
    } else {
        errors.roles = ExploitationRoleFieldArrayHelpers.validate(values, props);
    }
    return errors;
}

export default compose(
    reduxForm<UserGroupCardFormAdminData, UserGroupCardFormAdminProps, FieldArrayErrors>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(UserGroupCardFormAdmin);