import {ReducerBuilder} from "co-redux-builders";
import {PagerBackendProps} from "../../../components/Pager";
import NotificationListCardAdminActions from "./NotificationListCardAdminActions";
import TaskAlertNotificationList from "../../../ws/alertNotification/TaskAlertNotificationList";
import {AlertNotification, AlertNotificationRequest} from "../../../model/AlertNotification";

interface InitialState {
    loading: boolean,
    notifications: AlertNotification[],
    error: string,
    pager: PagerBackendProps,
    criteria: Partial<AlertNotificationRequest>,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    notifications: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1
    },
    criteria: {},
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskAlertNotificationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskAlertNotificationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskAlertNotificationList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskAlertNotificationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            notifications: payload.data,
            pager: payload.pager || oldState.pager
        }
    })

    .onEvent(NotificationListCardAdminActions.onSetCriteria, (oldState, payload) => ({
        ...oldState,
        criteria: payload.newCriteria
    }))

    .onEvent(NotificationListCardAdminActions.clearNotificationListCardAdminReducer, () => INITIAL_STATE)

    .build()