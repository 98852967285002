import {ReducerBuilder} from "co-redux-builders";
import {Link} from "../../../../model/Link";
import {PagerBackendProps} from "../../../../components/Pager";
import TaskLinkList from "../../../../ws/links/TaskLinkList";
import LinkListAdminActions from "./LinkListAdminActions";

interface LinksAdminReducerState {
    links: Link [],
    loading: boolean,
    errors: string,
    pager: PagerBackendProps
}

const INITIAL_STATE: LinksAdminReducerState = {
    links: [],
    loading: false,
    errors: '',
    pager: {
        current: 1,
        total_pages: 1
    }
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskLinkList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
    .onEvent(TaskLinkList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
    .onEvent(TaskLinkList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, errors: payload}))
    .onEvent(TaskLinkList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState,payload) =>
        ({...oldState, links: payload.data, errors: '', pager: payload.pager || oldState.pager}))

    // ComponentWillUnmount
    .onEvent(LinkListAdminActions.dispatchClearReducer, () => INITIAL_STATE)

    .build()