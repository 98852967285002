import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import {AlertNotificationUser} from "../../model/AlertNotificationUser";

interface CustomCriteria {
    solved?: boolean
}

type TaskAlertNotificationUserListCriteria = TaskCriteriaRequest<CustomCriteria>

export default class TaskAlertNotificationUserList extends BaseGenericRequest<TaskAlertNotificationUserListCriteria, TaskResponse<AlertNotificationUser[]>> {

    private readonly data: TaskAlertNotificationUserListCriteria;

    public constructor(criteria: TaskAlertNotificationUserListCriteria = {limit: 0}) {
        super(METHOD.METHOD_POST, Urls.URL_ALERT_NOTIFICATION_USER_LIST);
        this.data = criteria;
    }

    protected getRequest(): TaskAlertNotificationUserListCriteria {
        return this.data;
    }
}
