import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import {AlertNotification} from "../../model/AlertNotification";

export default class TaskAlertNotificationDetail extends BaseGenericRequest<{}, TaskResponse<AlertNotification>> {

    public constructor(userId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_ALERT_NOTIFICATION_DETAIL, {uuid: userId}));
    }
}
