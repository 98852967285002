import * as React from 'react';
import UserGroupListCardAdminReducer from "./UserGroupListCardAdminReducer";
import {connect} from "react-redux";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ELIMINAR_GRUPOS_DE_USUARIOS,
    TR_GRUPOS_DE_USUARIOS,
    TR_NO_EXISTEN_GRUPOS_DE_USUARIOS,
} from "../../../../I18n/constants";
import {ROUTE_ADMIN_USER_GROUP_ADD} from "../../../../routing/Routes";
import TaskUserGroupList from "../../../../ws/userGroup/TaskUserGroupList";
import UserGroupListTable from "./UserGroupListTable";
import CardList from "../../../../components/card/CardList";
import UserGroupListAdminScreenActions from "./UserGroupListCardAdminActions";
import TaskUserGroupDelete from "../../../../ws/userGroup/TaskUserGroupDelete";

const mapStateToProps = UserGroupListCardAdminReducer.autoMapToProps();
const mapActionsToProps = UserGroupListAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class UserGroupListCardAdmin extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render() {
        const {userGroups, error, loading, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_GRUPOS_DE_USUARIOS)}
                      sort={{column: 'name'}}
                      TaskList={TaskUserGroupList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_GRUPOS_DE_USUARIOS)}}
                      data={userGroups}
                      error={error}
                      ItemsTable={UserGroupListTable}
                      addRoute={ROUTE_ADMIN_USER_GROUP_ADD}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_GRUPOS_DE_USUARIOS),
                          Task: TaskUserGroupDelete,
                      }}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(UserGroupListCardAdmin as unknown as React.ComponentType<{}>);

