import BaseGenericRequest from "../BaseGenericRequest";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import {TaskDeleteResponse} from "../TaskDeleteResponse";
import {TaskDeleteMultipleRequest} from "../TaskDeleteMultipleRequest";

export default class TaskUserGroupDelete extends BaseGenericRequest<TaskDeleteMultipleRequest, TaskDeleteResponse> {
    private readonly data: TaskDeleteMultipleRequest;

    public constructor(itemIdOrIds: string | string[]) {
        if (typeof itemIdOrIds === 'string') {
            super(METHOD.METHOD_DELETE, generateRoute(Urls.URL_USER_GROUP_DELETE, {uuid: itemIdOrIds}));
        } else {
            super(METHOD.METHOD_DELETE, generateRoute(Urls.URL_USER_GROUP_DELETE_MULTIPLE));
            this.data = {ids: itemIdOrIds}
        }

    }

    protected getRequest(): TaskDeleteMultipleRequest {
        return this.data;
    }
}