import * as React from 'react';
import {Field, FormErrors, getFormSyncErrors, InjectedFormProps, reduxForm} from "redux-form";
import {ReducerBuilder} from "co-redux-builders";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_APELLIDO, TR_APELLIDO_OBLIGATORIO, TR_CAMBIAR_CONTRASENA,
    TR_CONTRASENA,
    TR_CONTRASENA_OBLIGATORIA,
    TR_EMAIL, TR_EMAIL_INCORRECTO, TR_EMAIL_OBLIGATORIO, TR_LA_CONTRASENA_TIENE_POCOS_CARACTERES,
    TR_NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA,
    TR_NOMBRE, TR_NOMBRE_OBLIGATORIO,
    TR_NUEVO_USUARIO,
    TR_USUARIO
} from "../../../../I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import {connect} from "react-redux";
import Button from "../../../../components/Button";
import Col from "../../../../components/Col";
import EditPasswordModal, {EditPasswordModalData} from "./EditPasswordModal";
import AlertComponent from "../../../../base/alerts/AlertComponent";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {goToRoute} from "../../../../utils/Router";
import {ROUTE_ADMIN_USERS} from "../../../../routing/Routes";
import UserPermissionSectionAdmin, {
    UserPermissionSectionAdminData,
    UserPermissionSectionAdminHelpers
} from "./cards/permission/UserPermissionSectionAdmin";
import {compose} from "redux";
import {FieldArrayErrors} from "../../../../utils/FormUtils";
import Role from "../../../../model/Role";
import Config from "../../../../Config";
import {RoleError} from "../../userGroups/form/ExploitationRoleFieldArray";
import {changeUserPassword} from "../../../../utils/AuthManager";
import {isEmailValid} from "../../../../utils/StringUtils";

const FORM_NAME = 'UserCardFormAdmin';

interface SynchronousError {
    groups: string[],
    roles: RoleError[],
}

export interface UserCardFormAdminData extends UserPermissionSectionAdminData {
    name: string,
    last_name: string,
    email: string,
    password: string,
}

interface UserCardFormExtProps {
    userId?: string,
    initialValues?: Partial<UserCardFormAdminData>,
    onSubmit: (data: UserCardFormAdminData) => void,
    isCurrentUser: boolean,
    roleList: Role[],
    parentLoading: boolean,
    needsPassword: boolean,
}

enum UserCardFormAdminFields {
    NAME = "name",
    LAST_NAME = "last_name",
    EMAIL = "email",
    PASSWORD = "password",
}

interface UserCardFormAdminProps extends InjectedFormProps<UserCardFormAdminData> {
    onSubmit: (data: UserCardFormAdminData) => void,
    initialValues: Partial<UserCardFormAdminData>,
    formState: { values: UserCardFormAdminData },
    synchronousError: SynchronousError,
}

const mapStateToPropsGeneric = (state: any) => {
    return {
        formState: state.form[FORM_NAME],
        synchronousError: getFormSyncErrors(FORM_NAME)(state)
    }
};
const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    mapStateToPropsGeneric,
);

interface UserFormAdminScreenState {
    modal: boolean,
}

type Props = UserCardFormAdminProps & UserCardFormExtProps & typeof mapStateToProps;

class UserCardFormAdmin extends React.Component<Props, UserFormAdminScreenState> {

    public constructor(props: Props) {
        super(props);

        this.state = {modal: false};
    }

    private onCloseModal = (): void => {
        this.setState({modal: !this.state.modal});
    };

    private onEditPassword = (values: EditPasswordModalData): void => {
        if (this.props.userId) {
            changeUserPassword(values.password, this.props.userId);
        } else {
            AlertComponent.error(I18nUtils.tr(TR_NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA));
        }
        this.onCloseModal();
    };

    private hasErrors(fieldArrayErrors: string[]): boolean {
        const filteredFieldArrayErrors = fieldArrayErrors.filter((error) => error);
        return !!filteredFieldArrayErrors.find((error) => error.length !== 0);
    };

    private validatePermissionSection = (): boolean => {
        const {synchronousError} = this.props;

        const groupsHasErrors = synchronousError.groups ?
            this.hasErrors(synchronousError.groups) : false;

        const roleTypesHasErrors = synchronousError.roles ?
            this.hasErrors(synchronousError.roles.map((role) => role.role_id)) : false;

        const exploitationsHasErrors = synchronousError.roles ?
            this.hasErrors(synchronousError.roles.map((role) => role.exploitation)) : false;

        return groupsHasErrors || roleTypesHasErrors || exploitationsHasErrors;
    };

    public render(): React.ReactNode {
        const {
            parentLoading, handleSubmit, onSubmit, initialValues, invalid, pristine, isCurrentUser
        } = this.props;
        return (
            <React.Fragment>
                <Card loading={parentLoading}>
                    <CardHeader title={I18nUtils.tr(initialValues ? TR_USUARIO : TR_NUEVO_USUARIO)}/>
                    <CardBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Field
                                    label={I18nUtils.tr(TR_NOMBRE)}
                                    name={UserCardFormAdminFields.NAME}
                                    component={FormInput}
                                    col={{md: 6, lg: 6}}
                                />
                                <Field
                                    label={I18nUtils.tr(TR_APELLIDO)}
                                    name={UserCardFormAdminFields.LAST_NAME}
                                    component={FormInput}
                                    col={{md: 6, lg: 6}}
                                />
                            </Row>
                            <Row>
                                <Field
                                    label={I18nUtils.tr(TR_EMAIL)}
                                    name={UserCardFormAdminFields.EMAIL}
                                    component={FormInput}
                                    col={{md: 6, lg: 6}}
                                    disabled={isCurrentUser}
                                />
                                {!initialValues &&
                                <Field
                                    label={I18nUtils.tr(TR_CONTRASENA)}
                                    name={UserCardFormAdminFields.PASSWORD}
                                    component={FormInput}
                                    type="password"
                                    col={{md: 6, lg: 6}}
                                />}
                            </Row>
                            <Row>
                                <Col md={3} lg={3}>
                                    {initialValues &&
                                    <Button
                                        text={I18nUtils.tr(TR_CAMBIAR_CONTRASENA)}
                                        onClick={this.onCloseModal}
                                        type={'button'}
                                        block={true}
                                        className={'btn-lg btn-primary'}
                                    />}
                                </Col>
                            </Row>

                            {!isCurrentUser && <UserPermissionSectionAdmin
                                invalid={this.validatePermissionSection()}
                            />}
                            <FormCardFooter
                                invalid={invalid}
                                pristine={pristine}
                                isUpdate={!!initialValues}
                                cancelHandler={() => goToRoute(ROUTE_ADMIN_USERS)}/>
                        </form>
                    </CardBody>
                </Card>
                {initialValues &&
                <EditPasswordModal
                    show={this.state.modal}
                    onSubmit={this.onEditPassword}
                    onClose={this.onCloseModal}
                />}
            </React.Fragment>
        )
    }
}

function validate(values: UserCardFormAdminData, props: UserCardFormExtProps) {
    const errors: FormErrors<UserCardFormAdminData, FieldArrayErrors> = {
        ...UserPermissionSectionAdminHelpers.validate(values, props)
    };

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.last_name || values.last_name.length === 0) {
        errors.last_name = I18nUtils.tr(TR_APELLIDO_OBLIGATORIO);
    }
    if (!values.email || values.email.length === 0) {
        errors.email = I18nUtils.tr(TR_EMAIL_OBLIGATORIO);
    }
    if(!isEmailValid(values.email)){
        errors.email = I18nUtils.tr(TR_EMAIL_INCORRECTO);
    }
    if (props.needsPassword) {
        if (!values.password || values.password.length === 0) {
            errors.password = I18nUtils.tr(TR_CONTRASENA_OBLIGATORIA);
        } else if (values.password.length < Config.MIN_PASSWORD_LENGTH) {
            errors.password = I18nUtils.tr(TR_LA_CONTRASENA_TIENE_POCOS_CARACTERES)
        }
    }
    return errors;
}

export default compose(
    reduxForm<UserCardFormAdminData, UserCardFormExtProps, FieldArrayErrors>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true
    }),
    connect(mapStateToProps)
)(UserCardFormAdmin) as React.ComponentType<UserCardFormExtProps>;