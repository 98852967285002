import * as React from 'react';
import LogListCardAdminReducer from "./LogListCardAdminReducer";
import {connect} from "react-redux";
import I18nUtils from "../../../../I18n/I18nUtils";
import LogListTable from "./LogListTable";
import CardList from "../../../../components/card/CardList";
import LogListAdminScreenActions from "./LogListCardAdminActions";
import {
    TR_BUSCANDO, TR_BUSCAR,
    TR_FECHA_FIN, TR_FECHA_INICIO,
    TR_LOGS,
    TR_NO_EXISTEN_LOGS,
    TR_NO_SE_HAN_ENCONTRADO_RESULTADOS,
    TR_TIPO,
    TR_USUARIO
} from "../../../../I18n/constants";
import TaskLogList, {LogCriteria} from "../../../../ws/log/TaskLogList";
import Row from "../../../../components/Row";
import FormCol from "../../../../components/form/FormCol";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import TaskLogTypeList from "../../../../ws/logType/TaskLogTypeList";
import User, {UserAutocompleteBuilder} from "../../../../model/User";
import FormInputOption from "../../../../components/form/FormInputOption";
import TaskUserList from "../../../../ws/user/TaskUserList";
import {AutocompleteOption} from "../../../../components/form/FormAutocomplete";

const mapStateToProps = LogListCardAdminReducer.autoMapToProps();
const mapActionsToProps = LogListAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

interface State {
    currentCriteria: LogCriteria,
}

class LogListCardAdmin extends React.Component<Props, State> {

    public state: State = {
        currentCriteria: {}
    };

    public UNSAFE_componentWillMount(): void {
        new TaskLogTypeList().execute();
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onUserSelect = (selectedUser?: AutocompleteOption<User>): void => {
        const newCriteria = {
            ...this.state.currentCriteria,
            user_ids: selectedUser ? [selectedUser.value] : undefined
        };
        this.setState({currentCriteria: newCriteria});
    };

    private onTypeSelect = (selectedTypeId: string): void => {
        const newCriteria: LogCriteria = {
            ...this.state.currentCriteria,
            log_type_ids: (selectedTypeId !== '-1') ? [selectedTypeId] : undefined
        };
        this.setState({currentCriteria: newCriteria});
    };

    private onStartDateSelect = (date?: Date): void => {
        const newCriteria: LogCriteria = {
            ...this.state.currentCriteria,
            start_date: date ? date.toISOString() : undefined,
        };
        this.setState({currentCriteria: newCriteria});
    };

    private onEndDateSelect = (date?: Date): void => {
        const newCriteria: LogCriteria = {
            ...this.state.currentCriteria,
        };
        if (date) {
            date.setDate(date.getDate() + 1);
            newCriteria.end_date = date.toISOString();
        } else {
            delete newCriteria.end_date;
        }
        this.setState({currentCriteria: newCriteria});
    };

    private onUserSearch = (newSearch: string): void => {
        new TaskUserList({
            ...this.state.currentCriteria,
            search: newSearch
        }).execute();
    };

    public render() {
        const {logs, error, loading, pager} = this.props;
        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_LOGS)}
                      sort={{column: 'date', desc: true}}
                      TaskList={TaskLogList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_LOGS)}}
                      data={logs}
                      error={error}
                      ItemsTable={LogListTable}
                      customCriteria={this.state.currentCriteria}
            >
                {this.renderHeaderContent()}
            </CardList>
        )
    }

    private renderHeaderContent = (): React.ReactNode => {
        const {usersLoading, users, logTypes} = this.props;

        const searchPlaceholder = I18nUtils.tr(TR_BUSCAR);
        const userOptions = users.map((user) => UserAutocompleteBuilder.buildOption(user));

        const logTypeOptions: FormInputOption[] = logTypes.map((logType) => ({value: logType.id, name: logType.name}));

        return (
            <Row>
                <FormCol md={3} lg={3}>
                    <div className={'form-group'}>
                        <div className={'form-line'}>
                            <label>{I18nUtils.tr(TR_USUARIO)}</label>
                            <AsyncTypeahead
                                placeholder={searchPlaceholder}
                                promptText={searchPlaceholder}
                                options={userOptions}
                                onChange={(selected) => {
                                        this.onUserSelect(selected[0])
                                }}
                                onInputChange={
                                    (newText: string, event: Event) => newText === '' && this.onUserSelect(undefined)
                                }
                                labelKey={'name'}
                                filterBy={() => true}
                                isLoading={false}
                                onSearch={this.onUserSearch}
                                searchText={usersLoading ?
                                    I18nUtils.tr(TR_BUSCANDO) :
                                    I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                            />
                        </div>
                    </div>
                </FormCol>
                <FormCol md={3} lg={3}>
                    <div className={'form-group'}>
                        <label>{I18nUtils.tr(TR_TIPO)}</label>
                        <select
                            className={'form-control show-tick bootstrap-select'}
                            onChange={(e) => this.onTypeSelect(e.target.value)}
                        >
                            <option key={'-1'} value={'-1'}/>
                            {logTypeOptions.map((option: FormInputOption, index) => {
                                return (<option key={index} value={option.value}>{option.name}</option>);
                            })}
                        </select>
                    </div>
                </FormCol>
                <FormCol md={3} lg={3}>
                    <div className={'form-group'}>
                        <div className={'form-line'}>
                            <label>{I18nUtils.tr(TR_FECHA_INICIO)}</label>
                            <input type={'date'} className="form-control"
                                   onChange={(e) => this.onStartDateSelect(e.target.valueAsDate)}/>
                        </div>
                    </div>
                </FormCol>
                <FormCol md={3} lg={3}>
                    <div className={'form-group'}>
                        <div className={'form-line'}>
                            <label>{I18nUtils.tr(TR_FECHA_FIN)}</label>
                            <input type={'date'} className="form-control"
                                   onChange={(e) => this.onEndDateSelect(e.target.valueAsDate)}/>
                        </div>
                    </div>
                </FormCol>
            </Row>
        )
    };
}

export default connect(mapStateToProps, mapActionsToProps)(LogListCardAdmin as unknown as React.ComponentType<{}>);

