import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import DocumentModel, {DocumentModelData} from "../../model/Document";

export default class TaskDocumentAdd extends BaseGenericRequest<DocumentModelData, TaskResponse<DocumentModel>> {

    private readonly data: DocumentModelData;

    public constructor(data: DocumentModelData) {
        super(METHOD.METHOD_POST, Urls.URL_DOCUMENT_ADD);
        this.data = data;
    }

    protected getRequest(): DocumentModelData {
        return this.data;
    }
}