import * as React from 'react';
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_INTRODUZCA_OBSERVACIONES_PERTINENTES,
    TR_RESOLVER,
    TR_RESOLVER_NOTIFICACIONES_USUARIO,
    TR_VER
} from "../../../../I18n/constants";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import Row from "../../../../components/Row";
import Col from "../../../../components/Col";
import Icon from "../../../../components/Icon";
import Button from "../../../../components/Button";
import {AlertStateValues} from "../../../../model/AlertState";
import DateFormatter from "../../../../utils/DateFormatter";
import AlertNotificationUserReducer from "../../../admin/alertnotificationuser/list/AlertNotificationUserReducer";
import AlertNotificationUserActions from "../../../admin/alertnotificationuser/list/AlertNotificationUserActions";
import {connect} from "react-redux";
import {AlertNotificationUser} from "../../../../model/AlertNotificationUser";
import FormResultFormScreenActions from "../../../form/formResult/FormResultFormScreenActions";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {ROUTE_ADMIN_ALERT_DETAIL, ROUTE_FORM_RESULT_ADD} from "../../../../routing/Routes";
import SolveNotificationUserModal from "../../../../components/modal/SolveNotificationUserModal";

interface AlertListHomeTableProps {
    data: AlertNotificationUser[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

interface AlertListHomeTableState {
    showSolveModal: boolean,
    formResultId?: string,
    id?: string
}

const mapStateToProps = AlertNotificationUserReducer.autoMapToProps();
const mapActionsToProps = AlertNotificationUserActions.autoMapToProps();

type Props = AlertListHomeTableProps & typeof mapStateToProps & typeof mapActionsToProps;

class AlertListHomeTable extends React.Component<Props, AlertListHomeTableState> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            showSolveModal: false,
            formResultId: undefined,
            id: undefined
        }
    }

    public render(): React.ReactNodeArray {
        const {data} = this.props;

        return (
            [data && data.map((alert: AlertNotificationUser) => {
                const existLink = alert.alert_notification.alert.link;

                return (
                    <Row key={alert.id} className={alert.alert_notification.alert.id !== AlertStateValues.ACTIVE ? "resolved-alert" : "unresolved-alert"}>

                        <Col md={9} lg={9}>
                            <p className="title-alert"><b>{alert.alert_notification.alert.name}</b></p>
                        </Col>

                        <Col md={3} lg={3} className="time-alert align-right">
                            <Icon icon="query_builder"/>
                            {DateFormatter.formatDatetime(alert.alert_notification.alert.datetime)}
                        </Col>

                        <Col md={9} lg={9} className="message-alert">
                            <p className="message-alert">{alert.alert_notification.alert.description}</p>
                        </Col>

                        <Col className="align-right" md={3} lg={3}>
                            <Row>
                                {
                                    alert.alert_notification.solved &&
                                    <Col md={6} lg={6}/>
                                }
                                {
                                    existLink &&
                                    <Col md={6} lg={6}>
                                        <Button
                                            text={I18nUtils.tr(TR_VER).toUpperCase()}
                                            onClick={() => goToRoute(ROUTE_ADMIN_ALERT_DETAIL, {id: alert.alert_notification.alert.id})}
                                            type={'button'}
                                            block={true}
                                            className={'btn-sm'}
                                        />
                                    </Col>
                                }
                                {
                                    !existLink &&
                                    <Col md={6} lg={6}/>
                                }
                                {
                                    !alert.alert_notification.solved &&
                                    <Col md={6} lg={6}>
                                        <Button
                                            text={I18nUtils.tr(TR_RESOLVER).toUpperCase()}
                                            onClick={() => {
                                                const linkedForm = alert.alert_notification.alert.form;
                                                this.props.setNotificationId(alert.id);

                                                if (linkedForm) {
                                                    FormResultFormScreenActions.goToRouteHomeScreen.asConnectedAction();
                                                    goToRoute(generateRoute(ROUTE_FORM_RESULT_ADD, {formId: linkedForm.id}));
                                                } else {
                                                    this.props.openModal();
                                                }
                                            }}
                                            type={'button'}
                                            block={true}
                                            className={'btn-sm btn-primary'}
                                        />
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                )
            }),
                <SolveNotificationUserModal
                    title={I18nUtils.tr(TR_RESOLVER_NOTIFICACIONES_USUARIO)}
                    message={I18nUtils.tr(TR_INTRODUZCA_OBSERVACIONES_PERTINENTES)}
                    textConfirmButton={I18nUtils.tr(TR_RESOLVER)}
                    show={this.props.showResolveModal}
                    onClose={() => this.props.closeModal()}
                />
            ]
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(AlertListHomeTable as unknown as React.ComponentType<AlertListHomeTableProps>)
