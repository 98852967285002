import * as React from "react";
import {Component, ReactNode} from "react";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../I18n/I18nUtils";
import {PagerBackendProps} from "../../../../components/Pager";
import {AlertNotificationUser} from "../../../../model/AlertNotificationUser";
import AlertNotificationUserTable from "./AlertNotificationUserTable";
import TaskAlertNotificationUserList from "../../../../ws/alertnotificationuser/TaskAlertNotificationUserList";

interface AlertNotificationUserViewProps {
	alertNotificationUser: AlertNotificationUser[],
	loading: boolean,
	errors: string,
	pager: PagerBackendProps
}

export default class AlertNotificationUserView extends Component<AlertNotificationUserViewProps> {
	public render(): ReactNode {
		const {alertNotificationUser, loading, errors, pager} = this.props;

		return (
			<CardList loading={loading}
					  pager={pager}
					  title={I18nUtils.tr("Notificaciones de usuario")}
					  sort={{column: 'creation_date'}}
					  TaskList={TaskAlertNotificationUserList}
					  emptyListOptions={{message: I18nUtils.tr("No existen notificaciones de usuario")}}
					  data={alertNotificationUser}
					  error={errors}
					  ItemsTable={AlertNotificationUserTable}
			/>
		)
	}
}