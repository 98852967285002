import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import Row from "../Row";
import Col from "../Col";
import Button from "../Button";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_CANCELAR} from "../../I18n/constants";
import {BaseModalProps} from "../../base/modal/BaseModal";
import FormCol from "../form/FormCol";
import {connect} from "react-redux";
import AlertNotificationUserReducer from "../../modules/admin/alertnotificationuser/list/AlertNotificationUserReducer";
import AlertNotificationUserActions from "../../modules/admin/alertnotificationuser/list/AlertNotificationUserActions";

interface SolveNotificationUserModalProps extends BaseModalProps{
    title: string,
    message: string,
    textConfirmButton: string,
}

const mapStateToProps = AlertNotificationUserReducer.autoMapToProps();
const mapActionsToProps = AlertNotificationUserActions.autoMapToProps();

type Props = SolveNotificationUserModalProps & typeof mapStateToProps & typeof mapActionsToProps;

class SolveNotificationUserModal extends Component<Props> {

    private handleClearObservations () {
        this.setState({observations: ''})
    }

    public render(): React.ReactNode {
        const {onClose, show, title, message, textConfirmButton, dispatchResolveNotification} = this.props;

        return (
            <Modal onHide={onClose} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <FormCol>
                            <div className={"form-group"}>
                                <div className={'form-line'}>
                                    <label className={"main-label"}>{message}</label>
                                    <textarea
                                    rows={4}
                                    className={'form-control no-resize'}
                                    value={this.props.observations}
                                    onChange={(e) => this.props.setObservations(e.target.value)}
                                />
                                </div>
                            </div>
                        </FormCol>
                    </Row>

                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col xs={0} sm={0} md={6} lg={6}/>
                        <Col sm={6} md={3} lg={3}>
                            <Button
                                text={I18nUtils.tr(TR_CANCELAR).toUpperCase()}
                                onClick={() => {
                                    onClose();
                                    this.handleClearObservations();
                                }}
                                block={true}
                                className={'btn-lg'}
                            />
                        </Col>
                        <Col sm={6} md={3} lg={3}>
                            <Button
                                text={textConfirmButton.toUpperCase()}
                                onClick={() => {
                                    dispatchResolveNotification();
                                    onClose();
                                    this.handleClearObservations();
                                }}
                                block={true}
                                className={'btn-lg btn-primary'}
                                disabled={this.props.observations.length === 0}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(SolveNotificationUserModal as unknown as React.ComponentType<SolveNotificationUserModalProps>);
 