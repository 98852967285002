import * as React from 'react';
import {ROUTE_FORM, ROUTE_FORM_RESULT} from "../../../routing/Routes";
import FormResultListCard from "./FormResultListCard";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_DATOS_DEL_FORMULARIO, TR_FORMULARIOS} from "../../../I18n/constants";
import {MenuIcon} from "../../../Config";
import ScreenCard from "../../../components/screen/ScreenCard";
import {RouteComponentProps} from "react-router";
import {generateRoute} from "../../../utils/Router";

type Props = RouteComponentProps<{formId: string }>

export default class FormResultListScreen extends React.Component<Props> {

    public render() {

        const formId = this.props.match.params.formId;

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_FORMULARIOS),
                    url: ROUTE_FORM,
                    icon: MenuIcon.FORM,
                },
                {
                    name: I18nUtils.tr(TR_DATOS_DEL_FORMULARIO),
                    url: generateRoute(ROUTE_FORM_RESULT, {formId}),
                    icon: MenuIcon.FORM,
                },
            ]}>
                <FormResultListCard formId={formId} isAdminMode={false}/>
            </ScreenCard>
        )
    }
}
