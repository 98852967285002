import {METHOD, ReduxAwareGenericRequest} from "co-generic-request";
import {ReduxStorageUtils} from "co-redux-builders";
import Urls from "../Urls";
import {sha256} from "js-sha256";
import TaskResponse from "../TaskResponse";

interface TaskLoginRequest {
    email: string,
    password: string,
}

export default class TaskLogin extends ReduxAwareGenericRequest<TaskLoginRequest, TaskResponse<string>> {

    private readonly email: string;
    private readonly password: string;

    public constructor(email: string, password: string) {
        super(METHOD.METHOD_POST, Urls.URL_LOGIN, 'error genérico', ReduxStorageUtils.dispatch);

        this.email = email;
        this.password = password;

        this.addHeader("Accept", "application/json");
        this.addHeader("Content-type", "application/json");
    }

    protected getRequest(): TaskLoginRequest {
        return {
            email: this.email,
            password: sha256(this.password)
        }
    }
}