import React, {Component, ReactNode} from "react";
import {connect} from "react-redux";
import LinkDetailAdminReducer from "./LinkDetailAdminReducer";
import LinkDetailAdminActions from "./LinkDetailAdminActions";
import TaskLinkDetail from "../../../../ws/links/TaskLinkDetail";
import LinkDetailAdminView from "./LinkDetailAdminView";
import {LinkData} from "../../../../model/Link";
import {LinkFormData} from "../../../../components/linkform/LinkForm";
import TaskLinkEdit from "../../../../ws/links/TaskLinkEdit";
import AlertComponent from "../../../../base/alerts/AlertComponent";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_FAIL_EDITAR_LINK, TR_SUCCES_EDITAR_LINK} from "../../../../I18n/constants";
import {goToRoute} from "../../../../utils/Router";
import {ROUTE_LINKS} from "../../../../routing/Routes";

interface LinkDetailAdminDataContainerProps{
    id: string
}

const mapStateToProps = LinkDetailAdminReducer.autoMapToProps();
const mapActionsToProps = LinkDetailAdminActions.autoMapToProps();

type Props = LinkDetailAdminDataContainerProps & typeof mapStateToProps & typeof mapActionsToProps;

class LinkDetailAdminDataContainer extends Component<Props> {
    public componentDidMount(): void {
        new TaskLinkDetail(this.props.id)
            .execute()
    }

    public componentWillUnmount(): void {
        this.props.dispatchClearReducer();
    }

    private handleOnEditLink = (link: LinkFormData) => {
        const {id} = this.props;

        const editedLink: LinkData = {
            name: link.name,
            url: link.url,
            image: link.image[0]
        };

        new TaskLinkEdit(id, editedLink)
            .onFail(() => setTimeout(() => {
                AlertComponent.error(I18nUtils.tr(TR_FAIL_EDITAR_LINK));
            },1))

            .onSuccess(() => setTimeout(() => {
                AlertComponent.success(I18nUtils.tr(TR_SUCCES_EDITAR_LINK));
                goToRoute(ROUTE_LINKS);
            },1))

            .execute();
    };

    public render(): ReactNode {
        const {link, loading, errors} = this.props;

        return (
            <LinkDetailAdminView
                onEdit={this.handleOnEditLink}
                link={link}
                loading={loading}
                errors={errors}
            />
        );
    }
}


export default connect(mapStateToProps, mapActionsToProps)(LinkDetailAdminDataContainer as unknown as React.ComponentType<LinkDetailAdminDataContainerProps>);