import * as React from 'react';
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {ROUTE_FORM_RESULT_ALL_LIST} from "../../../routing/Routes";
import {
    TR_DESCRIPCION,
    TR_EXPLOTACION,
    TR_FECHA_SUBIDA_FORMULARIO,
    TR_LINK, TR_NOMBRE,
    TR_RESULTADO_DE_FORMULARIO, TR_USUARIO
} from "../../../I18n/constants";
import FormTextArea from "../../../components/form/FormTextArea";
import I18nUtils from "../../../I18n/I18nUtils";
import FormInput from "../../../components/form/FormInput";
import CardBody from "../../../components/card/CardBody";
import CardHeader from "../../../components/card/CardHeader";
import {goToRoute} from "../../../utils/Router";
import Card from "../../../components/card/Card";
import FormCardFooter from "../../../components/form/FormCardFooter";
import Row from "../../../components/Row";

const FORM_NAME = 'FormResultForm';

export interface FormResultAllCardFormData {
    name: string,
    description: string,
    exploitation: string,
    updateDate: string,
    user: string,
    formLink: string
}

interface FormResultCardFormExtProps {
    initialValues: Partial<FormResultAllCardFormData>,
    parentLoading: boolean,
}

enum FormResultCardFormFields {
    NAME = "name",
    DESCRIPTION = "description",
    EXPLOITATION = "exploitation",
    UPDATE_DATE = "updateDate",
    USER = "user",
    FORM_LINK = "formLink",
}

interface FormResultCardFormProps extends InjectedFormProps<FormResultAllCardFormData> {
    initialValues: Partial<FormResultAllCardFormData>,
}

type Props = FormResultCardFormProps & FormResultCardFormExtProps;

class FormResultAllCardForm extends React.Component<Props> {

    public render(): React.ReactNode {
        const {parentLoading} = this.props;

        return (
            <Card loading={parentLoading}>
                <CardHeader title={I18nUtils.tr(TR_RESULTADO_DE_FORMULARIO)}/>
                <CardBody>
                    <form>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={FormResultCardFormFields.NAME}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled={true}
                            />
                            <Field
                                label={I18nUtils.tr(TR_EXPLOTACION)}
                                name={FormResultCardFormFields.EXPLOITATION}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled={true}
                            />
                            <Field
                                label={I18nUtils.tr(TR_FECHA_SUBIDA_FORMULARIO)}
                                name={FormResultCardFormFields.UPDATE_DATE}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled={true}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_USUARIO)}
                                name={FormResultCardFormFields.USER}
                                component={FormInput}
                                col={{md: 4, lg: 4}}
                                disabled={true}
                            />
                            <Field
                                label={I18nUtils.tr(TR_LINK)}
                                name={FormResultCardFormFields.FORM_LINK}
                                component={FormInput}
                                col={{md: 8, lg: 8}}
                                disabled={true}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_DESCRIPCION)}
                                name={FormResultCardFormFields.DESCRIPTION}
                                component={FormTextArea}
                                col={{md: 6, lg: 6}}
                                disabled={true}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={false}
                            pristine={false}
                            cancelHandler={() => goToRoute(ROUTE_FORM_RESULT_ALL_LIST)}
                            readOnly={true}
                            isUpdate={false}
                        />
                    </form>
                </CardBody>
            </Card>
        )
    }
}

export default reduxForm<FormResultAllCardFormData, FormResultCardFormExtProps>({
        form: FORM_NAME,
        enableReinitialize: true
    }
)(FormResultAllCardForm as any) as React.ComponentType<FormResultCardFormExtProps>;