import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import Checkbox from "../Checkbox";

export interface FormCheckboxProps extends WrappedFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps;
}

export default class FormCheckbox extends Component<FormCheckboxProps> {

    constructor(props: FormCheckboxProps & WrappedFieldProps) {
        super(props);
    }

    public render(): ReactNode {
        const {input, name, meta, label, disabled, col} = this.props;
        return (
            <FormCol {...col} >
                <Checkbox
                    {...input}
                    checked={input.value}
                    disabled={disabled}
                    label={label}
                    name={name}
                />
                <label className="error">{meta.touched ? meta.error : ""}</label>
            </FormCol>
        );
    }

}

