import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Alert, {AlertRequest} from "../../model/Alert";

export default class TaskAlertEdit extends BaseGenericRequest<AlertRequest, TaskResponse<Alert>> {

    private readonly data: AlertRequest;

    public constructor(alertId: string, alertData: AlertRequest) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_ALERT_EDIT, {uuid: alertId}));
        this.data = alertData;
    }

    protected getRequest(): AlertRequest {
        return this.data;
    }
}