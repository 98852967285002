import React from 'react';
import Col from "../components/Col";
import Row from "../components/Row";

const IFrame = (props) => {
    let title = props.title || (props.match ? props.match.params.title : "");
    title = decodeURIComponent(title);
    let url = props.url || (props.match ? props.match.params.url : "");
    url = decodeURIComponent(url);
    url = url.replace(/\[/g, '%5B').replace(/\]/g, '%5D').replace(/{/g, '%7B').replace(/}/g, '%7D').replace(/"/g, '%22').replace(/\+/g, '%2B').replace(/#/g, '%23').replace('%23', '#');
    return (
        <>
            <Row>
                <Col md={12}>
                    <h3>{title}</h3>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <iframe
                        title={title}
                        src={url}
                        height={'400px'}
                        width={'1000px'}
                        //scrolling={"no"}
                        />
                </Col>
            </Row>
        </>
    )
}

export default IFrame;