import {ReducerBuilder} from "co-redux-builders";

const fakePendings = [{
   id: 0,
   estado: 'Pendiente',
   formulario: 'formdetail - 01'
}, {
    id: 1,
    estado: 'Pendiente',
    formulario: 'formdetail - 02'
}, {
    id: 2,
    estado: 'Pendiente',
    formulario: 'formdetail - 03'
}];

interface InitialState {
    loading: boolean,
    pendings: any[],
    errorPendings: string,
    error: string,
    search: string,
    sort: {
        column: string,
        asc: boolean,
    },
    pager: {
        data: any[],
        current: number,
        total_pages: number,
    }
}

const INITIAL_STATE: InitialState = {
    loading: false,
    pendings: fakePendings,
    errorPendings: '',
    error: '',
    search: '',
    sort: {
        column: 'formulario',
        asc: true
    },
    pager: {
        data: fakePendings,
        current: 1,
        total_pages: 1
    }
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
.build()