import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import Log from "../../model/Log";

interface LogCustomCriteria {
    start_date?: string,
    end_date?: string,
    log_type_ids?: string[],
    user_ids?: string[],
}

export type LogCriteria = TaskCriteriaRequest<LogCustomCriteria>

export default class TaskLogList extends BaseGenericRequest<LogCriteria, TaskResponse<Log[]>> {

    private readonly data: LogCriteria;

    public constructor(criteria: LogCriteria = {}) {
        super(METHOD.METHOD_POST, Urls.URL_LOG_LIST);
        this.data = criteria;
    }

    protected getRequest(): LogCriteria {
        return this.data;
    }

}