import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import FormCol, {FormColProps} from "./FormCol";
import classNames from "classnames";

export interface FormTextAreaProps {
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps
    autoComplete?: string
    rows: number,
    obligatory?: boolean
    classNameInput?: string,
}

export default class FormTextArea extends React.Component<FormTextAreaProps & WrappedFieldProps> {

    constructor(props: FormTextAreaProps & WrappedFieldProps) {
        super(props);
    }

    public render(): React.ReactNode {

        const {input, meta, label, placeholder, disabled, rows, col, className, obligatory, classNameInput} = this.props;
        const classComponent = classNames('form-group', className);

        return (
            <FormCol {...col} >
                <div className={classComponent}>
                    <div className={'form-line'}>
                        {label ? <label className='main-label'>{label || ''}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label> : null}
                        <textarea rows={rows} className={`form-control no-resize ${classNameInput}`} {...input} disabled={disabled || false } placeholder={placeholder}> </textarea>
                    </div>
                    <label className="error">{meta.touched ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }
}

