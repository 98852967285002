import * as React from 'react';
import I18nUtils from "../../../I18n/I18nUtils";
import {MenuIcon} from "../../../Config";
import ScreenCard from "../../../components/screen/ScreenCard";
import {TR_NOTIFICACIONES} from "../../../I18n/constants";
import {ROUTE_ADMIN_ALERT_NOTIFICATIONS} from "../../../routing/Routes";
import NotificationListCardAdmin from "./NotificationListCardAdmin"

export default class NotificationListAdminScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_NOTIFICACIONES),
                    url: ROUTE_ADMIN_ALERT_NOTIFICATIONS,
                    icon: MenuIcon.ALERT
                }
            ]}>
                <NotificationListCardAdmin/>
            </ScreenCard>
        )
    }
}