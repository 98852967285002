import * as React from "react";
import Th from "../../../components/table/Th";
import I18nUtils from "../../../I18n/I18nUtils";
import {OnSortHandler, SortState} from "../../../utils/Sort";
import {TR_FORMULARIO} from "../../../I18n/constants";
import FormModel from "../../../model/Form";
import {generateRoute, goToRoute} from "../../../utils/Router";
import {ROUTE_FORM_RESULT} from "../../../routing/Routes";

interface FormListTableProps {
    data: FormModel[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class FormListUserTable extends React.Component<FormListTableProps> {

    private gotoItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_FORM_RESULT, {formId: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((form: FormModel) => {
                    return (
                        <tr key={form.id}
                            onClick={() => this.gotoItemDetail(form.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(form.id) : null}
                            {/*<td>*/}
                            {/*    <span className={"up-to-date"}/>*/}
                            {/*    <small>{formdetail.enabled}</small>*/}
                            {/*</td>*/}
                            <td>{form.name}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                {/*<Th text={I18nUtils.tr(TR_ESTADO)}*/}
                {/*    columnName={'estado'}*/}
                {/*    {...commonProps}*/}
                {/*/>*/}
                <Th text={I18nUtils.tr(TR_FORMULARIO)}
                    columnName={'estado'}
                    {...commonProps}
                />
            </tr>
        )
    }
}