import * as React from 'react';
import {CSSProperties, FunctionComponent, useState} from 'react';
import Icon from '../components/Icon';

export interface CardSectionCollapsedProps {
    title: string
    initialCollapsed?: boolean
    invalid?: boolean
    onCollapse?: (collapsed: boolean) => void
    className?: string
    style?: CSSProperties
}

export const CardSectionCollapsed: FunctionComponent<CardSectionCollapsedProps> = (
    {
        title,
        invalid = false,
        initialCollapsed = true,
        children,
        className = '',
        style
    }
) => {
    const [collapsed, setCollapsed] = useState(initialCollapsed);
    const classError = collapsed && invalid ? 'card-collapsed-error' : '';

    return (
        <div className={`fields-card m-b-25 p-t-5 p-b-5 ${classError} ${className}`} style={style}>
            <h5 className={'p-t-5 p-b-5'}>
                {title}
                <a onClick={() => setCollapsed(!collapsed)}>
                    <Icon
                        className={'right'}
                        icon={collapsed ? 'expand_more' : 'expand_less'}
                    />
                </a>
            </h5>
            <div className={collapsed ? 'display-none' : ''}>
                {children}
            </div>
        </div>
    );
};
