import * as React from 'react';
import {Field, WrappedFieldArrayProps} from "redux-form";
import {AutocompleteOption} from "../../../../components/form/FormAutocomplete";
import {connect} from "react-redux";
import FormAsyncAutocomplete from "../../../../components/form/FormAsyncAutocomplete";
import CardFieldArray from "../../../../components/card/CardFieldArray";
import ExploitationRoleFieldArrayReducer from "./ExploitationRoleFieldArrayReducer";
import Exploitation, {ExploitationAutocompleteBuilder} from "../../../../model/Exploitation";
import FormSelect from "../../../../components/form/FormSelect";
import TaskRoleList from "../../../../ws/role/TaskRoleList";
import FormInputOption from "../../../../components/form/FormInputOption";
import Col from "../../../../components/Col";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_EXPLOTACION, TR_EXPLOTACION_OBLIGATORIO,
    TR_PERMISOS,
    TR_ROL, TR_ROL_OBLIGATORIO, TR_YA_HAS_ASIGNADO_ESTE_PERMISO,
} from "../../../../I18n/constants";
import {UserRoleFieldArrayOption} from "../../../../model/UserRole";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import BaseFormFieldsHelpers from "../../../../base/form/BaseFormFieldsHelpers";
import Role from "../../../../model/Role";

const mapStateToProps = ExploitationRoleFieldArrayReducer.autoMapToProps();

interface ExploitationRoleFieldArrayProps {
    showTitle?: boolean,
    hideAdminRoles?: boolean,
}

type Props = WrappedFieldArrayProps<UserRoleFieldArrayOption>
    & typeof mapStateToProps
    & ExploitationRoleFieldArrayProps;

class ExploitationRoleFieldArray extends React.Component<Props> {

    public componentDidMount(): void {
        new TaskRoleList().execute();
    }

    private onSearchExploitation = (search: string): void => {
        new TaskExploitationList({
            search,
            limit: 0
        }).execute();
    };

    private selectExploitation = (index: number, newExploitation: Exploitation): void => {
        const currentFieldValue = this.props.fields.get(index);

        this.props.fields.remove(index);

        this.props.fields.insert(index, {
            exploitation: [ExploitationAutocompleteBuilder.buildOption(newExploitation)],
            role_id: currentFieldValue.role_id
        });
    };

    private selectRole = (index: number, newRoleId: string): void => {
        const currentFieldValue = this.props.fields.get(index);

        this.props.fields.remove(index);

        this.props.fields.insert(index, {
            role_id: newRoleId,
            exploitation: currentFieldValue.exploitation
        });
    };

    public render(): React.ReactNode {

        const {fields, meta, roleList, exploitationList, exploitationListLoading, showTitle, hideAdminRoles = true} = this.props;

        const exploitationOptions = exploitationList.map(
            (exploitation) => ExploitationAutocompleteBuilder.buildOption(exploitation)
        );

        const filteredRoleList = hideAdminRoles ?
            roleList.filter((role) => role.exploitation_required) :
            roleList;

        const roleOptions: FormInputOption[] = filteredRoleList.map((role) => ({
            value: role.id,
            name: role.name,
        }));

        return (
            <CardFieldArray fields={fields}
                            meta={meta}
                            title={showTitle ? I18nUtils.tr(TR_PERMISOS) : ''}
                            fieldsHeader={this.renderHeader()}
                            addHandler={() => fields.push({
                                role_id: '',
                                // @ts-ignore
                                exploitation: [],
                            })}
            >
                {fields.map((fieldName: string, index: number) => {
                    const currentRoleId = fields.get(index).role_id;
                    const currentRole = roleList.find((role) => role.id === currentRoleId);
                    const needsExploitation = currentRole && currentRole.exploitation_required;

                    return (
                        <React.Fragment key={index}
                        >
                            <Field
                                name={`${fieldName}.role_id`}
                                component={FormSelect}
                                options={roleOptions}
                                onSelected={(selected: FormInputOption[]) => {
                                    if (selected && selected[0]) {
                                        this.selectRole(index, selected[0].value);
                                    }
                                }}
                                blankOptionText={' '}
                                col={{sm: 6, md: 6, lg: 6, className: 'm-b--10 m-t--15 p-r-10'}}
                                disabled={false}
                            />
                            {/*
                            // @ts-ignore*/}
                            {needsExploitation && <Field
                                name={`${fieldName}.exploitation`}
                                component={FormAsyncAutocomplete}
                                isLoading={exploitationListLoading}
                                options={exploitationOptions}
                                onSearch={(search) => this.onSearchExploitation(search)}
                                onSelected={(selected: Array<AutocompleteOption<Exploitation>>) => {
                                    if (selected && selected[0]) {
                                        this.selectExploitation(index, selected[0].data);
                                    }
                                }}
                                col={{sm: 6, md: 6, lg: 6, className: 'm-b--10 m-t--15 p-r-0'}}
                                disabled={false}
                            />}
                        </React.Fragment>
                    )
                })}
            </CardFieldArray>
        )
    }

    private renderHeader = (): React.ReactNode => {
        return (
            <>
                <Col sm={6} md={6} lg={6} className={'m-b-0'}>
                    <label>{I18nUtils.tr(TR_ROL)}</label>
                </Col>
                <Col sm={6} md={6} lg={6} className={'m-b-0'}>
                    <label>{I18nUtils.tr(TR_EXPLOTACION)}</label>
                </Col>
            </>
        )
    };
}

export default connect(mapStateToProps)(ExploitationRoleFieldArray) as unknown as React.ComponentType<any>;

export type RoleError = {
    [P in keyof UserRoleFieldArrayOption]: string
}

export class ExploitationRoleFieldArrayHelpers extends BaseFormFieldsHelpers {
    public static validate(values: { roles: UserRoleFieldArrayOption[] }, props: { roleList: Role[] }): RoleError[] {
        const processedPairIds: Array<{
            roleId: string,
            exploitationId: string | undefined
        }> = [];

        const allRoleErrors: RoleError[] = [];

        values.roles.forEach((userRole, userRoleIndex) => {
            allRoleErrors[userRoleIndex] = {
                role_id: '',
                exploitation: '',
            };

            if (!userRole.role_id || userRole.role_id === '-1') {
                allRoleErrors[userRoleIndex].role_id = I18nUtils.tr(TR_ROL_OBLIGATORIO);
            } else {
                const currentRole = props.roleList.find((role) => role.id === userRole.role_id);
                const needsExploitation = currentRole && currentRole.exploitation_required;

                if (needsExploitation && (!Array.isArray(userRole.exploitation) || userRole.exploitation.length < 1)) {
                    allRoleErrors[userRoleIndex].exploitation = I18nUtils.tr(TR_EXPLOTACION_OBLIGATORIO);
                } else {
                    const selectedExploitation = userRole.exploitation && userRole.exploitation[0];

                    const currentPair = {
                        roleId: userRole.role_id,
                        exploitationId: selectedExploitation && selectedExploitation.value,
                    };
                    if (processedPairIds.some(
                        (pairIds) => (pairIds.roleId === currentPair.roleId &&
                            pairIds.exploitationId === currentPair.exploitationId))) {
                        allRoleErrors[userRoleIndex].role_id = I18nUtils.tr(TR_YA_HAS_ASIGNADO_ESTE_PERMISO);
                    } else {
                        processedPairIds.push(currentPair);
                    }
                }
            }
        });
        return allRoleErrors;
    }

    public static validateWithoutExploitations(values: { roles: UserRoleFieldArrayOption[] }, props: { roleList: Role[] }): RoleError[] {
        const processedPairIds: Array<{
            roleId: string,
            exploitationId: string | undefined
        }> = [];

        const allRoleErrors: RoleError[] = [];

        values.roles.forEach((userRole, userRoleIndex) => {
            allRoleErrors[userRoleIndex] = {
                role_id: '',
                exploitation: '',
            };

            if (!userRole.role_id || userRole.role_id === '-1') {
                allRoleErrors[userRoleIndex].role_id = I18nUtils.tr(TR_ROL_OBLIGATORIO);
            }

            const selectedExploitation = userRole.exploitation && userRole.exploitation[0];

            const currentPair = {
                roleId: userRole.role_id,
                exploitationId: selectedExploitation && selectedExploitation.value,
            };
            if (processedPairIds.some(
                (pairIds) => (pairIds.roleId === currentPair.roleId &&
                    pairIds.exploitationId === currentPair.exploitationId))) {
                allRoleErrors[userRoleIndex].role_id = I18nUtils.tr(TR_YA_HAS_ASIGNADO_ESTE_PERMISO);
            } else {
                processedPairIds.push(currentPair);
            }
        });

        return allRoleErrors;
    }
}