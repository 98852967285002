import * as React from 'react';
import LoggedScreen from "../../base/screens/LoggedScreen";
import ScreenContent from "./ScreenContent";
import Row from "../Row";
import Col from "../Col";
import {BreadcrumbItem} from "../breadcrumb/BreadcrumbManager";

interface ScreenCardProps {
    children: React.ReactNode,
    breadcrumbs: BreadcrumbItem[],
}

export default class ScreenCard extends LoggedScreen<ScreenCardProps> {

    constructor(props: ScreenCardProps, context: any) {
        super(props, context);
        const {breadcrumbs} = this.props;
        breadcrumbs.forEach((breadcrumb) => {
            const {name, url, icon} = breadcrumb;
            this.addBreadcrumbScreen(name, url, icon);
        });
    }

    public renderScreen(): React.ReactNode {
        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
                <Row clearfix>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        {this.props.children}
                    </Col>
                </Row>
            </ScreenContent>
        );
    }
}