import * as React from 'react';
import {ROUTE_FORM} from "../../../routing/Routes";
import FormListCard from "./FormListCard";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_FORMULARIOS} from "../../../I18n/constants";
import {MenuIcon} from "../../../Config";
import ScreenCard from "../../../components/screen/ScreenCard";

export default class FormListScreen extends React.Component<{}> {

    public render() {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_FORMULARIOS),
                    url: ROUTE_FORM,
                    icon: MenuIcon.FORM,
                }
            ]}>
                <FormListCard />
            </ScreenCard>
        )
    }
}
