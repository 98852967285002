import {ReducerBuilder} from "co-redux-builders";
import TaskFormFieldTypeList from "../../../../ws/formFieldType/TaskFormFieldTypeList";
import FormFieldType from "../../../../model/FormFieldType";
import FormInputOption from "../../../../components/form/FormInputOption";
import TaskFormFrequencyTypeList from "../../../../ws/formfrequencytype/TaskFormFrequencyTypeList";
import TaskFormScopeTypeList from "../../../../ws/formscopetype/TaskFormScopeTypeList";

interface InitialState {
    formFieldTypeLoading: boolean,
    formFieldTypeOptions: FormInputOption[],
    formFieldTypeError: string,
    formFrequencyOptions: FormInputOption[],
    formScopesOptions: FormInputOption[]
}

const INITIAL_STATE: InitialState = {
    formFieldTypeLoading: false,
    formFieldTypeOptions: [],
    formFieldTypeError: '',
    formFrequencyOptions: [],
    formScopesOptions: []
};


export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskFormFieldTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, formFieldTypeLoading: true}
    })
    .onEvent(TaskFormFieldTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, formFieldTypeLoading: false}
    })
    .onEvent(TaskFormFieldTypeList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, formFieldTypeError: payload}
    })
    .onEvent(TaskFormFieldTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        const formFieldTypes: FormInputOption[] = payload.data.map((formFieldType: FormFieldType) => {
            return {
                name: formFieldType.name,
                value: formFieldType.id,
            }
        });
        return {...oldState, formFieldTypeOptions: formFieldTypes}
    })

    .onEvent(TaskFormFrequencyTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        const frequencies: FormInputOption[] = payload.data.map(frequency => {
            return {
                name: frequency.name,
                value: frequency.id
            }
        })
        return {...oldState, formFrequencyOptions: frequencies}
    })


    .onEvent(TaskFormScopeTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        const scopes: FormInputOption[] = payload.data.map(scope => {
            return {
                name: scope.name,
                value: scope.id
            }
        })
        return {...oldState, formScopesOptions: scopes}
    })

    .build()