import * as React from "react";
import {Component, ReactNode} from "react";
import {Link} from "../../../../model/Link";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ELIMINAR_LINKS, TR_LINKS, TR_NO_EXISTEN_LINKS} from "../../../../I18n/constants";
import {ROUTE_LINK_ADD} from "../../../../routing/Routes";
import CardList from "../../../../components/card/CardList";
import {PagerBackendProps} from "../../../../components/Pager";
import TaskLinkList from "../../../../ws/links/TaskLinkList";
import LinkListAdminTable from "./LinkListAdminTable";
import TaskLinkDelete from "../../../../ws/links/TaskLinkDelete";

interface LinksAdminViewProps{
    links: Link [],
    loading: boolean,
    errors: string,
    pager: PagerBackendProps
}

export default class LinkListAdminView extends Component<LinksAdminViewProps> {
    public render(): ReactNode {
        const {links, loading, errors, pager} = this.props;

        return (
                <CardList loading={loading}
                          pager={pager}
                          title={I18nUtils.tr(TR_LINKS)}
                          sort={{column: 'name'}}
                          TaskList={TaskLinkList}
                          emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_LINKS)}}
                          data={links}
                          error={errors}
                          ItemsTable={LinkListAdminTable}
                          deleteOption={{
                              title: I18nUtils.tr(TR_ELIMINAR_LINKS),
                              Task: TaskLinkDelete,
                          }}
                          addRoute={ROUTE_LINK_ADD}
                />
        );
    }
}
 