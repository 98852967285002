import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Link, LinkData} from "../../model/Link";
import {generateRoute} from "../../utils/Router";

export default class TaskLinkAdd extends BaseGenericRequest<LinkData, TaskResponse<Link>> {

    private readonly data: LinkData;

    public constructor(data: LinkData) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_LINK_ADD, data));
        this.data = data;
    }

    protected getRequest(): LinkData {
        return this.data;
    }
}