import React, {Component, ReactNode} from "react";
import {formStateAutoMapToPropsFactory} from "../../../../../utils/FormUtils";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {compose} from "redux";
import {connect} from "react-redux";
import Row from "../../../../../components/Row";
import I18nUtils from "../../../../../I18n/I18nUtils";
import {goToRoute} from "../../../../../utils/Router";
import {ROUTE_ADMIN_EXPLOITATIONS} from "../../../../../routing/Routes";
import {TR_REVERSION} from "../../../../../I18n/constants";
import FormTextArea from "../../../../../components/form/FormTextArea";
import FormCardFooter from "../../../../../components/form/FormCardFooter";

const FORM_NAME = "ExploitationReversionFormAdmin";

interface ExploitationReversionFormAdminProps{
    initialValues?: Partial<ExploitationReversionFormAdminData>,
    onSubmit: (data: ExploitationReversionFormAdminData) => void,
    loading: boolean
}

export interface ExploitationReversionFormAdminData {
    reversion: string
}

enum ExploitationReversionFields {
    REVERSION = "reversion"
}

interface ExploitationReversionFormAdminState {
    showModal: boolean
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationReversionFormAdminData>(FORM_NAME);

type Props = ExploitationReversionFormAdminProps & typeof mapFormStateToProps & InjectedFormProps<ExploitationReversionFormAdminData>;


class ExploitationReversionFormAdmin extends Component<Props, ExploitationReversionFormAdminState> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    public render(): ReactNode {
        const {onSubmit, pristine, invalid, handleSubmit, initialValues} = this.props;
        return (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Field
                            name={ExploitationReversionFields.REVERSION}
                            label={I18nUtils.tr(TR_REVERSION)}
                            component={FormTextArea}
                            type={"text"}
                            rows={10}
                            col={{md: 12, lg: 12}}
                        />
                    </Row>

                    <FormCardFooter
                        invalid={invalid}
                        pristine={pristine}
                        isUpdate={!!initialValues}
                        cancelHandler={() => goToRoute(ROUTE_ADMIN_EXPLOITATIONS)}
                    />
                </form>
            </>
        );
    }
}

export default compose(
    reduxForm<ExploitationReversionFormAdminData, ExploitationReversionFormAdminProps>({
        form: FORM_NAME,
        enableReinitialize: true
    }),
    connect(mapFormStateToProps as any)
)(ExploitationReversionFormAdmin);