import React, {Component, ReactNode} from "react";
import classNames from "classnames";
import {WrappedFieldProps} from "redux-form";
import FormCol, {FormColProps} from "../form/FormCol";

interface DateRangeInputFormProps extends WrappedFieldProps{
    label?: string,
    name: string,
    disabled?: boolean,
    col: FormColProps,
    showError?: boolean,
    obligatory?: boolean
}

export default class DatePicker extends Component<DateRangeInputFormProps> {
    public render(): ReactNode {
        const {input, meta, label, disabled, col, showError, obligatory} = this.props;

        const classFormLine = classNames('form-line', {
            focused: meta.touched && meta.error,
            error: meta.touched && meta.error
        });
        return (
            <FormCol {...col}>
                <div className={'form-group'}>
                    <div className={classFormLine}>
                        {label ? <label className='main-label'>{label || ''}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label> : null}
                        <input
                            className={"form-control"}
                            {...input}
                            disabled={disabled || false}
                            type={"date"}
                        />
                    </div>
                    <label className="error">{(meta.touched || showError) ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }
}
 