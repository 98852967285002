import * as React from 'react';
import {Component, ReactNode, ReactNodeArray} from 'react';
import imgDefaultUser from "../../../../res/img/default-user.png";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ADMINISTRACION,
    TR_ALERTAS,
    TR_DASHBOARDS,
    TR_DOCUMENTACION,
    TR_EXPLOTACIONES,
    TR_FORMULARIOS,
    TR_GRUPOS_DE_USUARIOS,
    TR_INICIO,
    TR_LANZADOR_APPS,
    TR_LINKS,
    TR_LOGS,
    TR_MENU,
    TR_NOTIFICACIONES,
    TR_PAVAPARK,
    TR_PERFIL,
    TR_RESULTADOS_DE_FORMULARIOS,
    TR_SALIR,
    TR_TIPOS_DE_EXPLOTACION,
    TR_USUARIOS,
    TR_ZONAS,
} from "../../../../I18n/constants";
import Icon from "../../../../components/Icon";
import DropDown, {DropDownOption} from '../../../../components/dropdown/DropDown';
import LogoutModal from "../../LogoutModal";
import AuthManager from "../../../../utils/AuthManager";
import MenuItem from "./MenuItem";
import {
    ROUTE_ADMIN_ALERT_NOTIFICATIONS,
    ROUTE_ADMIN_ALERTS,
    ROUTE_ADMIN_BUSINESS_TYPES,
    ROUTE_ADMIN_CURRENT_USER_DETAIL,
    ROUTE_ADMIN_DASHBOARDS,
    ROUTE_ADMIN_DOCUMENTS,
    ROUTE_ADMIN_EXPLOITATION_DETAIL,
    ROUTE_ADMIN_EXPLOITATIONS,
    ROUTE_ADMIN_FORMS,
    ROUTE_ADMIN_LOG,
    ROUTE_ADMIN_USER_GROUPS,
    ROUTE_ADMIN_USERS,
    ROUTE_ADMIN_ZONES, ROUTE_ALERT_NOTIFICATION_USER_LIST,
    ROUTE_APP_LAUNCHER,
    ROUTE_DASHBOARD,
    ROUTE_DOCUMENTATION,
    ROUTE_FORM,
    ROUTE_FORM_RESULT_ALL_LIST,
    ROUTE_HOME,
    ROUTE_LINKS,
    ROUTE_LOGIN,
} from "../../../../routing/Routes";
import {matchPath, RouteComponentProps, withRouter} from "react-router";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {MenuIcon} from "../../../../Config";
import User from "../../../../model/User";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import TaskUserActionsList from "../../../../ws/useractions/TaskUserActionsList";
import {compose} from "redux";
import {connect} from "react-redux";
import {UserActionTypes} from "../../../../model/UserAction";
import UserRole, {UserRoleValue} from "../../../../model/UserRole";
import {TR_NOTIFICACIONES_DE_USUARIO} from "../../../../I18n/translations/constants";

export interface SideBarProps {
    user: User;
}

const mapStateToProps = CurrentUserReducer.autoMapToProps();

type Props = SideBarProps & RouteComponentProps & typeof mapStateToProps;

const day = new Date();

class SideBar extends Component<Props, { showLogoutModal: boolean, expandedId: string }> {

    constructor(props: any) {
        super(props);
        this.state = {
            showLogoutModal: false,
            expandedId: ''
        };
        this.doLogout = this.doLogout.bind(this);
    }

    public componentDidMount(): void {
        new TaskUserActionsList().execute();
    }

    private handleResolveUserAction(id: string, type: UserActionTypes): void {
        // Por ahora solo hay implementadas explotaciones, si se añaden más tipos se irían añadiendo casos al switch
        switch (type) {
            case UserActionTypes.EXPLOITATION:
                const targetPath = generateRoute(ROUTE_ADMIN_EXPLOITATION_DETAIL, {id});
                goToRoute(targetPath);
                break;
        }
    }

    private isUserHeadOfService(): boolean {
        const {roles} = this.props.user;

        let isHeadOfService: boolean = false;

        roles.forEach((rol: UserRole) => {
            if (rol.role.id === UserRoleValue.HEAD_SERVICE) {
                isHeadOfService = true;
            }
        })

        return isHeadOfService;
    }

    private doLogout(): void {
        AuthManager.logout();
        goToRoute(ROUTE_LOGIN);
        // recargamos por temas de seguridad
        window.location.reload();
    }

    private isActive(routes: string[], exact: boolean = false): boolean {
        const {pathname} = this.props.location;
        let active = false;
        routes.forEach((route: string) => {
            const match = matchPath(pathname, route);
            if (match) {
                active = exact ?
                    match.url === pathname :
                    match.url === match.path
            }
        });
        return active;
    }

    private getSelectedSubItemIndex(adminRoutes: string[]): number {
        const {pathname} = this.props.location;
        return adminRoutes.findIndex((route) => {
            const match = matchPath(pathname, route);
            return (match !== null && match.url === match.path);
        });
    }

    private handleUserDetail(): void {
        goToRoute(ROUTE_ADMIN_CURRENT_USER_DETAIL, {id: this.props.user.id});
    }

    public render(): ReactNode {
        const {user} = this.props;

        if (!this.props.user) {
            return null;
        }
        const userOptions: DropDownOption[] = [
            {text: I18nUtils.tr(TR_PERFIL), icon: 'person_outline', onClick: () => this.handleUserDetail()},
            {divider: true},
            {text: I18nUtils.tr(TR_SALIR), icon: 'exit_to_app', onClick: () => this.setState({showLogoutModal: true})},
        ];
        let userRoles: string[] = [];
        this.props.user.roles.map((role) => userRoles.push(role.role.id));

        let adminSubItems: Array<{ title: string, route: string }>;

        const userGroupsRoles: string[] = [];
        user.groups.map((group) => group.roles.map((role) => userGroupsRoles.push(role.role.id)));

        if ((this.isUserHeadOfService() || userGroupsRoles.includes(UserRoleValue.HEAD_SERVICE)) && !this.props.user.admin) {
            adminSubItems = [
                {title: I18nUtils.tr(TR_EXPLOTACIONES), route: ROUTE_ADMIN_EXPLOITATIONS}
            ]
        } else {
            adminSubItems = [
                {title: I18nUtils.tr(TR_USUARIOS), route: ROUTE_ADMIN_USERS},
                {title: I18nUtils.tr(TR_GRUPOS_DE_USUARIOS), route: ROUTE_ADMIN_USER_GROUPS},
                {title: I18nUtils.tr(TR_DASHBOARDS), route: ROUTE_ADMIN_DASHBOARDS},
                {title: I18nUtils.tr(TR_FORMULARIOS), route: ROUTE_ADMIN_FORMS},
                {title: I18nUtils.tr(TR_ALERTAS), route: ROUTE_ADMIN_ALERTS},
                {title: I18nUtils.tr(TR_EXPLOTACIONES), route: ROUTE_ADMIN_EXPLOITATIONS},
                {title: I18nUtils.tr(TR_TIPOS_DE_EXPLOTACION), route: ROUTE_ADMIN_BUSINESS_TYPES},
                {title: I18nUtils.tr(TR_ZONAS), route: ROUTE_ADMIN_ZONES},
                {title: I18nUtils.tr(TR_DOCUMENTACION), route: ROUTE_ADMIN_DOCUMENTS},
                {title: I18nUtils.tr(TR_LOGS), route: ROUTE_ADMIN_LOG},
                {title: I18nUtils.tr(TR_LINKS), route : ROUTE_LINKS},
                {title: I18nUtils.tr(TR_NOTIFICACIONES_DE_USUARIO), route : ROUTE_ALERT_NOTIFICATION_USER_LIST},
                {title: I18nUtils.tr(TR_LINKS), route: ROUTE_LINKS},
            ];
        }

        const adminRoutes = Object.values(adminSubItems).map((subItem) => subItem.route);

        return (
            <aside className="sidebar">
                <div className="user-info" style={{backgroundColor: '#0063a2'}}>
                    <div className="image">
                        <img src={imgDefaultUser} width="48" height="48" alt="User"/>
                    </div>
                    <div className="info-container">
                        <div className="name" data-toggle="dropdown" aria-haspopup="true"
                             aria-expanded="false">{this.props.user.name} {this.props.user.last_name}</div>
                        <div className="email">{this.props.user.email}</div>
                        <DropDown className={'user-helper-dropdown'} options={userOptions} pullRight={true}>
                            <Icon icon={'keyboard_arrow_down'}/>
                        </DropDown>
                    </div>
                </div>

                <div className={"sidebar-body"}>
                    {this.renderUserActions()}

                    <div className="menu">
                        <ul className="list">
                            <li className="header">{I18nUtils.tr(TR_MENU).toUpperCase()}</li>

                            <MenuItem title={I18nUtils.tr(TR_INICIO)}
                                      icon={MenuIcon.HOME}
                                      active={this.isActive([ROUTE_HOME], true)}
                                      route={ROUTE_HOME}
                            />
                            <MenuItem title={I18nUtils.tr(TR_ADMINISTRACION)}
                                      icon={'business_center'}
                                      active={this.isActive(adminRoutes)}
                                      expanded={this.isActive(adminRoutes) || this.state.expandedId === TR_ADMINISTRACION}
                                      onExpand={(expanded: boolean) =>
                                          this.setState({expandedId: expanded ? TR_ADMINISTRACION : ''})}
                                      subItems={adminSubItems}
                                      selectedSubItemIndex={this.getSelectedSubItemIndex(adminRoutes)}
                                      visible={this.isUserHeadOfService() || userGroupsRoles.includes(UserRoleValue.HEAD_SERVICE)}
                            />
                            <MenuItem title={I18nUtils.tr(TR_DASHBOARDS)}
                                      icon={'dashboard'}
                                      active={this.isActive([ROUTE_DASHBOARD])}
                                      route={ROUTE_DASHBOARD}
                            />
                            <MenuItem title={I18nUtils.tr(TR_FORMULARIOS)}
                                      icon={'format_align_left'}
                                      active={this.isActive([ROUTE_FORM])}
                                      route={ROUTE_FORM}
                                      visible={!userRoles.includes(UserRoleValue.DIRECTOR)}
                            />
                            <MenuItem title={I18nUtils.tr(TR_DOCUMENTACION)}
                                      icon={MenuIcon.DOCUMENTATION}
                                      active={this.isActive([ROUTE_DOCUMENTATION])}
                                      route={ROUTE_DOCUMENTATION}
                            />
                            <MenuItem title={I18nUtils.tr(TR_LANZADOR_APPS)}
                                      icon={MenuIcon.LAUNCHER}
                                      active={this.isActive([ROUTE_APP_LAUNCHER])}
                                      route={ROUTE_APP_LAUNCHER}
                            />
                            <MenuItem title={I18nUtils.tr(TR_RESULTADOS_DE_FORMULARIOS)}
                                      icon={MenuIcon.FORM}
                                      active={this.isActive([ROUTE_FORM_RESULT_ALL_LIST])}
                                      route={ROUTE_FORM_RESULT_ALL_LIST}
                            />
                            <MenuItem title={I18nUtils.tr(TR_NOTIFICACIONES)}
                                      icon={MenuIcon.ALERT}
                                      active={this.isActive([ROUTE_ADMIN_ALERT_NOTIFICATIONS])}
                                      route={ROUTE_ADMIN_ALERT_NOTIFICATIONS}
                                      visible={userRoles.includes(UserRoleValue.ADMIN) || userRoles.includes(UserRoleValue.SUPER_ADMIN) || userRoles.includes(UserRoleValue.DIRECTOR)}
                            />
                        </ul>
                    </div>

                    <div className="legal">
                        <div className="copyright">
                            <a href="http://www.pavapark.com/">&copy; {day.getFullYear()} {I18nUtils.tr(TR_PAVAPARK)}</a>
                        </div>
                    </div>
                </div>

                <LogoutModal
                    show={this.state.showLogoutModal}
                    onClose={() => this.setState({showLogoutModal: false})}
                    onLogout={this.doLogout}
                />
            </aside>
        );
    }

    private renderUserActions(): ReactNode {
        const {userActions} = this.props;

        if (userActions) {
            const renderedUserActions: ReactNodeArray = [];

            userActions.map(userAction => {
                renderedUserActions.push(
                    <div className={"action"}
                         onClick={() => {
                             this.handleResolveUserAction(userAction.object_id, userAction.type)
                         }}>
                        <i className={"material-icons"}>{MenuIcon.ALERT}</i>
                        {userAction.text}
                    </div>
                )
            })

            if (renderedUserActions.length > 0) {
                return <div className={"pending-user-actions"}>{renderedUserActions}</div>;
            }
        }

        return null;
    }

}

export default compose(
    connect(mapStateToProps),
    withRouter
)(SideBar) as unknown as React.ComponentType<SideBarProps>;
