import {ReducerBuilder} from "co-redux-builders";
import User from "../../../../model/User";
import TaskUserList from "../../../../ws/user/TaskUserList";

interface InitialState {
    userListLoading: boolean,
    userList: User[],
}

const INITIAL_STATE: InitialState = {
    userListLoading: false,
    userList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        userListLoading: true
    }))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        userListLoading: false
    }))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, userList: payload.data};
        })

    .build();