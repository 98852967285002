export default class Config {

    public static BASE_URL = "https://beeparktest.cuatroochenta.com:8080";
    public static PAGER = {elementsPage: 10};
    public static AUTH_COOKIE_APP_NAME = 'BP_AUTH_COOKIE';
    public static MAX_FILE_SIZE_MB = 15;
    public static MIN_PASSWORD_LENGTH = 8;
    public static INACTIVITY_TIMEOUT_MIN = 60;
    public static GOOGLE_API_KEY = 'AIzaSyA-g3IETpfEHtHpvfr4-Hq2hXkeW8DbAmM';
    public static DEFAULT_MAP_POSITION = {
        lat: 40.41390113139636,
        lng: -3.704974429443382
    }
};

switch (process.env.REACT_APP_ENV) {
    case "docker-local":
        Config.BASE_URL = "http://beeparklocalapi.pavapark.com";
        break;
    case "docker-pre":
        Config.BASE_URL = "https://beeparktestapi.pavapark.com";
        break;
    case "docker-prod":
        Config.BASE_URL = "https://beeparkapi.pavapark.com";
        break;
    case "pre":
        Config.BASE_URL = "https://beeparktest.cuatroochenta.com:8080";
        break;
    case "prod":
        Config.BASE_URL = "https://beepark.pavapark.com:8080";
        break;
    case "local":
        Config.BASE_URL = "http://localhost:3001";
        break;
    default:
        Config.BASE_URL = "https://beeparktest.cuatroochenta.com:8080";
        break;

}

export class MenuIcon {
    public static HOME: string = 'home';
    public static USER: string = 'person';
    public static USER_GROUP: string = 'group';
    public static EXPLOITATION: string = 'location_city';
    public static ZONE: string = 'place';
    public static BUSINESS_TYPE: string = 'category';
    public static FORM: string = 'format_align_left';
    public static DASHBOARD: string = 'dashboard';
    public static DOCUMENTATION: string = 'file_copy';
    public static LOG: string = 'av_timer';
    public static ALERT: string = 'notification_important';
    public static LINK: string = 'link';
    public static LAUNCHER: string = 'apps';
    public static COMPLETED: string = 'done';
    public static UNCOMPLETED: string = 'warning';
    public static CHART: string = 'bar_chart';
    public static ARROWS: string = 'compare_arrows';
    public static ARROW_RIGHT: string = 'arrow_forward';
}

export class AppIcon {
    public static CLOSE: string = 'close';
}
