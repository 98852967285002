import {ReducerBuilder} from "co-redux-builders";
import {Link} from "../../../../model/Link";
import {PagerBackendProps} from "../../../../components/Pager";
import TaskLinkDetail from "../../../../ws/links/TaskLinkDetail";

interface LinkDetailAdminReducer {
    link: Link,
    loading: boolean,
    errors: string,
    pager: PagerBackendProps
}

const INITIAL_STATE: LinkDetailAdminReducer = {
    link: {
        id: '',
        name: '',
        url: '',
        image: ''
    },
    loading: false,
    errors: '',
    pager: {
        current: 1,
        total_pages: 1
    }
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskLinkDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
    .onEvent(TaskLinkDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
    .onEvent(TaskLinkDetail.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, errors: payload}))
    .onEvent(TaskLinkDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState,payload) => ({...oldState, link: payload.data, errors: ''}))

    .build();