import * as React from "react";
import Screencard from '../../../../components/screen/ScreenCard';
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_DASHBOARDS} from "../../../../I18n/constants";
import {ROUTE_ADMIN_DASHBOARDS} from "../../../../routing/Routes";
import {MenuIcon} from "../../../../Config";
import DashboardListCardAdmin from "../list/DashboardListCardAdmin";

export default class DashboardListAdminScreen extends React.Component<{}> {

    public render() {

        return (
            <Screencard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_DASHBOARDS),
                    url: ROUTE_ADMIN_DASHBOARDS,
                    icon: MenuIcon.DASHBOARD,
                }
            ]}>
                <DashboardListCardAdmin/>
            </Screencard>
        )
    }
}