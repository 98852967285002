import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import Switch from '@material/react-switch';

export interface FormSwitchProps extends WrappedFieldProps {
    name: string;
    label?: string;
    labelEnabled?: string;
    labelDisabled?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    obligatory?: boolean
}

export default class FormSwitch extends Component<FormSwitchProps> {

    constructor(props: FormSwitchProps & WrappedFieldProps) {
        super(props);
    }

    public render(): ReactNode {
        const {input, meta, label, labelEnabled, labelDisabled, disabled, col, obligatory} = this.props;

        return (
            <FormCol {...col} >
                <div className="form-group">
                    {label ? <label className='main-label'>{label || ''}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label> : null}
                    <div>
                        <label className={'label-checkbox'} style={{verticalAlign: 'center'}}>
                            <span>{labelDisabled || ''}</span>
                            <Switch
                                {...input}
                                checked={input.value}
                                disabled={disabled}
                            />
                            <span>{labelEnabled || ''}</span>
                        </label>
                    </div>
                    <label className="error">{meta.touched ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }

}

