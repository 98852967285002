import * as React from 'react';
import LogUserCardAdmin from "./LogUserCardAdmin";
import I18nUtils from "../../../../I18n/I18nUtils";
import {MenuIcon} from "../../../../Config";
import ScreenCard from '../../../../components/screen/ScreenCard';
import {TR_LOG, TR_LOGS} from "../../../../I18n/constants";
import {ROUTE_ADMIN_LOG, ROUTE_ADMIN_LOG_DETAIL} from "../../../../routing/Routes";
import {loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {RouteComponentProps} from "react-router";
import TaskUserDetail from "../../../../ws/user/TaskUserDetail";

type Props =  & RouteComponentProps<{ id: string }>

export default class LogUserAdminScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskUserDetail(id), this.props);
        }
    }

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_LOGS),
                    url: ROUTE_ADMIN_LOG,
                    icon: MenuIcon.LOG,
                },
                {
                    name: I18nUtils.tr(TR_LOG),
                    url: ROUTE_ADMIN_LOG_DETAIL,
                    icon: MenuIcon.LOG,
                },
            ]}>
                <LogUserCardAdmin/>
            </ScreenCard>
        )
    }
}