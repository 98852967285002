export function isEmailValid(email: string): boolean {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export function isNifNiePersonValid(nif: string): boolean {

    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = nif.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
        return false;
    }

    const nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

    return validChars.charAt(charIndex) === letter;
}

export function isNifCompanyValid(nif: string): boolean {
    // Q 28 26004 J -> Letra - Provincia - Numeros - Letra/Numero
    const validChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'N', 'P', 'Q', 'R', 'S', 'U', 'V', 'W'];
    const str = nif.toString().toUpperCase();

    const firstLetter = str.substr(0, 1);
    if (!validChars.includes(firstLetter)) {
        return false;
    }

    const digits = str.substr(1, 7);
    let sumA = 0;
    let sumB = 0;
    for (let index = 0; index < digits.length; index++) {
        if (index % 2 != 0) {
            sumA += Int(digits.charAt(index));
        } else {
            const mult = Int(digits.charAt(index)) * 2;
            const result = Math.trunc(mult / 10);
            const rest = mult % 10;
            sumB += result + rest;
        }
    }
    const sumC = sumA + sumB;
    let unitDigit = sumC % 10;
    if (unitDigit !== 0) {
        unitDigit = 10 - unitDigit;
    }

    const relationValidLastLetter = {0: 'J', 1: 'A', 2: 'B', 3: 'C', 4: 'D', 5: 'E', 6: 'F', 7: 'G', 8: 'H', 9: 'I'};
    const validLastLetter = relationValidLastLetter[unitDigit];
    const toTestLastUnit = str.substr(-1, 1);

    if (/^[PQRSW]$/i.test(firstLetter)) { // la última unidad será una letra
        return validLastLetter === toTestLastUnit;
    } else if (/^[ABEH]$/i.test(firstLetter)) { // la última unidad será un número
        return Int(str.substr(-1, 1)) === unitDigit;
    } else { // número o letra
        return Int(str.substr(-1, 1)) === unitDigit || validLastLetter === toTestLastUnit;
    }
}

export function Int(value: any): number {
    return parseInt(String(value || -1));
}

export function isIPValid(ip: string): boolean {
    return /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/.test(ip);
}

export function truncate(text: string, length: number = 15, delimiter: string = '...'): string {
    if (text.split(" ").length > length) {
        return text.split(" ").splice(0, length).join(" ") + delimiter;
    }
    return text;
}

export function truncateMiddle(text: string, maxLength: number = 30, separator: string = '...') {
    if (text.length <= maxLength) {
        return text;
    }
    const sepLen = separator.length;
    const charsToShow = maxLength - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return text.substr(0, frontChars) +
        separator +
        text.substr(text.length - backChars);
}

export function contains(text: string, substring: string): boolean {
    return cleanText(text).indexOf(cleanText(substring)) !== -1;
}

function cleanText(text: string): string {
    const normalized = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return normalized.toLowerCase();
}

export function isValidURL(url: string): boolean {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return false;
    }
    return true;
}

export function getMBfromBytes(bytes: number, decimals: number = 1): string {
    const mb = bytes / 1024 / 1024;
    return mb.toFixed(decimals);
}

export function isValidPassword(password: string): boolean {
    // al menos un numero, una minúscula, una mayúscula y de 8 caracteres
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return regExp.test(password);
}