import * as React from 'react';
import {RouteComponentProps} from "react-router";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    ROUTE_ADMIN_ALERT_NOTIFICATION_DETAIL,
    ROUTE_ADMIN_ALERT_NOTIFICATIONS,
    ROUTE_FORM_RESULT
} from "../../../routing/Routes";
import {connect} from "react-redux";
import {BreadcrumbItem} from "../../../components/breadcrumb/BreadcrumbManager";
import {MenuIcon} from "../../../Config";
import ScreenCard from "../../../components/screen/ScreenCard";
import NotificationFormScreenAdminReducer from "./NotificationFormScreenAdminReducer";
import NotificationFormAdminScreenActions from "./NotificationFormAdminScreenActions";
import TaskAlertNotificationDetail from "../../../ws/alertNotification/TaskAlertNotificationDetail";
import {loadItemDetailsOrGoBack} from "../../../utils/FormUtils";
import {generateRoute} from "../../../utils/Router";
import NotificationFormDetailCardAdmin, {NotificationCardFormAdminData} from "../formdetail/NotificationFormDetailCardAdmin";
import {TR_NOTIFICACION, TR_NOTIFICACIONES, TR_PENDIENTE, TR_RESUELTA} from "../../../I18n/constants";

const mapStateToProps = NotificationFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = NotificationFormAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & RouteComponentProps<{ id: string }> & typeof mapActionsToProps;

class NotificationFormAdminScreen extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    public componentDidMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskAlertNotificationDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearNotificationFormAdminScreenReducer();
    }

    public render(): React.ReactNode {
        const {match} = this.props;
        const alertNotificationId = match.params.id;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_NOTIFICACIONES),
            url: ROUTE_ADMIN_ALERT_NOTIFICATIONS,
            icon: MenuIcon.ALERT
        }];

        if (alertNotificationId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NOTIFICACION),
                url: generateRoute(ROUTE_ADMIN_ALERT_NOTIFICATION_DETAIL, {id: alertNotificationId}),
                icon: MenuIcon.USER
            });
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderUserForm()}
            </ScreenCard>
        );
    }

    private renderUserForm(): React.ReactNode {
        const {notificationFormScreenLoading, alertNotification} = this.props;

        const initialValues: NotificationCardFormAdminData = {
            solved: alertNotification ? I18nUtils.tr(alertNotification.solved ? TR_RESUELTA : TR_PENDIENTE) : '',
            solveDate: alertNotification && alertNotification.solve_date && alertNotification.solve_date.length !== 0 ?
                new Date(alertNotification.solve_date).toLocaleString() : '',
            responsibleUser: alertNotification && alertNotification.users && alertNotification.users.length !== 0 ?
                alertNotification.users.map((user) => (`${user.name} ${user.last_name}`)).join(', '): '',
            observations: alertNotification ? alertNotification.observations : '',
            creationDate: alertNotification && alertNotification.creation_date && alertNotification.creation_date.length !== 0 ?
                new Date(alertNotification.creation_date).toLocaleString() : '',
            alertName: alertNotification && alertNotification.alert ? alertNotification.alert.name : '',
            alertDescription: alertNotification && alertNotification.alert ? alertNotification.alert.description : '',
            alertTypeName: alertNotification && alertNotification.alert && alertNotification.alert.alert_type ? alertNotification.alert.alert_type.name : '',
            alertSourceName: alertNotification && alertNotification.alert && alertNotification.alert.alert_source ? alertNotification.alert.alert_source.name : '',
            alertFormRoute: alertNotification && alertNotification.form_result && alertNotification.form_result.form_id
            && alertNotification.form_result.form_id.length !== 0 ?
                generateRoute(ROUTE_FORM_RESULT, {formId: alertNotification.form_result.form_id}) : undefined,
            alertDocumentUrl: alertNotification && alertNotification.alert && alertNotification.alert.document ? alertNotification.alert.document.url : '',
            formResultDatetime: alertNotification && alertNotification.form_result && alertNotification.form_result.datetime &&
            alertNotification.form_result.datetime.length !== 0 ? new Date(alertNotification.form_result.datetime).toLocaleString() : '',
            formResultDatetimeValue: alertNotification && alertNotification.form_result && alertNotification.form_result.datetime_value
            && alertNotification.form_result.datetime_value.length !== 0 ? new Date(alertNotification.form_result.datetime_value).toLocaleString() : '',
            formResultUser: alertNotification && alertNotification.form_result && alertNotification.form_result.user ?
                `${alertNotification.form_result.user.name} ${alertNotification.form_result.user.last_name}` : '',
            formResultExploitation: alertNotification && alertNotification.form_result && alertNotification.form_result.exploitation ?
                alertNotification.form_result.exploitation.name : '',
            // @ts-ignore
            formResultFormFields: alertNotification && alertNotification.form_result && alertNotification.form_result.form &&
            alertNotification.form_result.form.form_fields && alertNotification.form_result.form.form_fields.length !== 0 ?
                alertNotification.form_result.form.form_fields : [],
            formResultFormValues: alertNotification && alertNotification.form_result && alertNotification.form_result.values ?
                alertNotification.form_result.values : {},
        };

        return (
            <NotificationFormDetailCardAdmin
                initialValues={initialValues}
                parentLoading={notificationFormScreenLoading}
            />

        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(NotificationFormAdminScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
