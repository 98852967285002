import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import DocumentModel from "../../model/Document";

interface DocumentCustomCriteriaProps {
    exploitation_ids?: string[],
    user_ids?: string[],
    start_date?: string,
    end_date?: string,
    fav?: boolean,
}

export type DocumentCriteriaProps = TaskCriteriaRequest<DocumentCustomCriteriaProps>

export default class TaskDocumentList extends BaseGenericRequest<DocumentCriteriaProps, TaskResponse<DocumentModel[]>> {

    private readonly data: DocumentCriteriaProps;

    public constructor(criteria: DocumentCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_DOCUMENT_LIST);
        this.data = criteria;
    }

    protected getRequest(): DocumentCriteriaProps {
        return this.data;
    }

}