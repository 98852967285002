import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import FormModel, {FormModelData} from "../../model/Form";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";

export default class TaskFormAdd extends BaseGenericRequest<FormModelData, TaskResponse<FormModel>> {

    private readonly data: FormModelData;

    public constructor(data: FormModelData) {
        super(METHOD.METHOD_POST, Urls.URL_FORM_ADD);
        this.data = data;
    }

    protected getRequest(): FormModelData {
        return this.data;
    }
}