import * as React from 'react';
import {Modal} from "react-bootstrap";
import {BaseModalProps} from "../../../../base/modal/BaseModal";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ACEPTAR,
    TR_CAMBIAR_CONTRASENA,
    TR_CANCELAR, TR_LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES,
    TR_LA_CONTRASENA_NO_PUEDE_ESTAR_VACIA,
    TR_LAS_CONTRASENAS_NO_COINCIDEN,
    TR_NUEVA_CONTRASENA, TR_POR_RAZONES_DE_SEGURIDAD_ES_NECESARIO_CAMBIAR_LA_CONTRASENA,
    TR_REPITE_LA_CONTRASENA
} from "../../../../I18n/constants";
import Row from "../../../../components/Row";
import {reduxForm, Field, FormErrors, InjectedFormProps} from "redux-form";
import FormInput from "../../../../components/form/FormInput";
import Col from "../../../../components/Col";
import Button from "../../../../components/Button";
import FormSubmit from "../../../../components/form/FormSubmit";
import {isValidPassword} from "../../../../utils/StringUtils";
import AlertComponent from "../../../../base/alerts/AlertComponent";

export interface EditPasswordModalData {
    password: string,
    repassword: string
}

interface EditPasswordModalProps extends BaseModalProps {
    onSubmit: (data: EditPasswordModalData) => void,
    forceChange?: boolean,
}

type Props = EditPasswordModalProps & BaseModalProps & InjectedFormProps<EditPasswordModalData>;

class EditPasswordModal extends React.Component<Props> {

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.show !== prevProps.show) {
            if (this.props.show) {
                this.props.reset();
            }
        }
    }

    public render(): React.ReactNode {
        const {
            show, onSubmit, handleSubmit, onClose, pristine, invalid, forceChange = false
        } = this.props;

        return (
            <Modal onHide={forceChange ?
                () => AlertComponent.info(I18nUtils.tr(TR_POR_RAZONES_DE_SEGURIDAD_ES_NECESARIO_CAMBIAR_LA_CONTRASENA)) :
                onClose
            } show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>{I18nUtils.tr((TR_CAMBIAR_CONTRASENA))}</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Modal.Body>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NUEVA_CONTRASENA)}
                                name={"password"}
                                component={FormInput}
                                type={"password"}
                                col={{md: 12, lg: 12}}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_REPITE_LA_CONTRASENA)}
                                name={"repassword"}
                                component={FormInput}
                                type={"password"}
                                col={{md: 12, lg: 12}}
                            />
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            {forceChange ?
                                <Col xs={6} sm={6} md={9} lg={9}/> :
                                <>
                                    <Col xs={0} sm={0} md={6} lg={6}/>
                                    <Col sm={6} md={3} lg={3}>
                                        <Button
                                            text={I18nUtils.tr(TR_CANCELAR).toUpperCase()}
                                            onClick={onClose}
                                            block={true}
                                            className={'btn-lg'}
                                        />
                                    </Col>
                                </>
                            }
                            <Field
                                label={I18nUtils.tr(TR_ACEPTAR).toUpperCase()}
                                name={'submit'}
                                // @ts-ignore
                                component={FormSubmit}
                                col={{sm: 6, md: 3, lg: 3}}
                                disabled={pristine || invalid}
                            />
                        </Row>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}

function validate(values: EditPasswordModalData) {
    const errors: FormErrors<EditPasswordModalData> = {};

    if (!values.password || values.password.length === 0) {
        errors.password = I18nUtils.tr(TR_LA_CONTRASENA_NO_PUEDE_ESTAR_VACIA)
    } else if (!isValidPassword(values.password)) {
        errors.password = I18nUtils.tr(TR_LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES)
    }
    if (values.password !== values.repassword) {
        errors.repassword = I18nUtils.tr(TR_LAS_CONTRASENAS_NO_COINCIDEN);
    }
    return errors;
}

export default reduxForm<EditPasswordModalData, EditPasswordModalProps>({
    validate,
    form: EditPasswordModal.name + 'Form',
    enableReinitialize: true
})(EditPasswordModal as any) as unknown as React.ComponentType<EditPasswordModalProps>;