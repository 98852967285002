import {METHOD, ReduxAwareGenericRequest} from "co-generic-request";
import {ReduxStorageUtils} from "co-redux-builders";
import AuthManager from "../utils/AuthManager";
import I18nUtils from "../I18n/I18nUtils";
import {TR_GENERIC_WS_ERROR} from "../I18n/constants";
import {TaskCriteriaRequest} from "./TaskCriteriaRequest";
import TaskResponse from "./TaskResponse";

export type TaskGenericList = new() => BaseGenericRequest<TaskCriteriaRequest<any>, TaskResponse<any[]>>

export default abstract class BaseGenericRequest<Request = {}, Response = {}>
    extends ReduxAwareGenericRequest<Request, Response> {

    protected constructor(method: METHOD, url: string) {
        super(method, url, I18nUtils.tr(TR_GENERIC_WS_ERROR), ReduxStorageUtils.dispatch);
        if (AuthManager.isLogged()) {
            this.addHeader("Authorization", `Bearer ${AuthManager.getAuthToken()}`);
        }
        this.addHeader("Accept", "application/json");
        this.addHeader("Content-type", "application/json");
        this.enableLogs(false);
    }

    protected isResponseSuccess(response: { status: number; _bodyText?: string }): boolean {
        let success = super.isResponseSuccess(response);
        const responseBody = JSON.parse(response._bodyText || '{}');
        success = success || responseBody.success;
        return success;
    }

    protected parseResponse(responseObj: any): Response {
        if (this.errorMessages[responseObj.code]) {
            throw new Error(this.errorMessages[responseObj.code]);
        }
        if (!responseObj.success) {
            throw new Error(responseObj.message);
        }
        return super.parseResponse(responseObj);
    }

    protected parseError(errorObj: string): string {
        try {
            return JSON.parse(errorObj).message || super.parseError(errorObj);
        } catch (ignored) {
            return super.parseError(errorObj);
        }
    }

}
