import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import UserGroup from "../../model/UserGroup";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";

type UserGroupCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskUserGroupList extends BaseGenericRequest<UserGroupCriteriaProps, TaskResponse<UserGroup[]>> {

    private readonly data: UserGroupCriteriaProps;

    public constructor(criteria: UserGroupCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_USER_GROUP_LIST);
        this.data = criteria;
    }

    protected getRequest(): UserGroupCriteriaProps {
        return this.data;
    }

}