import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import Exploitation, {ExploitationRequest} from "../../model/Exploitation";

export default class TaskExploitationAdd extends BaseGenericRequest<ExploitationRequest, TaskResponse<Exploitation>> {

    private readonly data: ExploitationRequest;

    public constructor(data: ExploitationRequest) {
        super(METHOD.METHOD_POST, Urls.URL_EXPLOITATION_ADD);
        this.data = data;
    }

    protected getRequest(): ExploitationRequest {
        return this.data;
    }
}