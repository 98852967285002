import * as React from 'react';
import {ROUTE_ADMIN_BUSINESS_TYPES} from "../../../../routing/Routes";
import BusinessTypeListCardAdmin from "./BusinessTypeListCardAdmin";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_TIPOS_DE_EXPLOTACION} from "../../../../I18n/constants";
import {MenuIcon} from "../../../../Config";
import ScreenCard from '../../../../components/screen/ScreenCard';

export default class BusinessTypeListAdminScreen extends React.Component<{}> {

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_TIPOS_DE_EXPLOTACION),
                    url: ROUTE_ADMIN_BUSINESS_TYPES,
                    icon: MenuIcon.BUSINESS_TYPE,
                },
            ]}
            >
                <BusinessTypeListCardAdmin/>
            </ScreenCard>
        )
    }
}