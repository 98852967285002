import React, {ReactNode} from "react";
import {RouteComponentProps} from "react-router";
import ScreenCard from "../../../../components/screen/ScreenCard";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_LINK, TR_LINKS} from "../../../../I18n/constants";
import {ROUTE_LINK_DETAIL, ROUTE_LINKS} from "../../../../routing/Routes";
import {MenuIcon} from "../../../../Config";
import LinkDetailDataContainer from "./LinkDetailAdminDataContainer";
import {generateRoute} from "../../../../utils/Router";

export default class LinkDetailAdminScreen extends React.Component<RouteComponentProps<{id: string}>> {
    public render(): ReactNode {
        const {id} = this.props.match.params;
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_LINKS),
                    url: ROUTE_LINKS,
                    icon: MenuIcon.LINK
                },
                {
                    name: I18nUtils.tr(TR_LINK),
                    url: generateRoute(ROUTE_LINK_DETAIL, {id: id}),
                    icon: MenuIcon.LINK
                }
            ]}>
                <LinkDetailDataContainer id={id}/>
            </ScreenCard>
        );
    }
}
 