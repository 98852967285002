import * as React from "react";
import {Component, ReactNode} from "react";
import {connect} from "react-redux";
import CompareGraphicsActions from "./CompareGraphicsActions";
import CompareGraphicsReducer from "./CompareGraphicsReducer";
import CompareGraphicsView from "./CompareGraphicsView"
import TaskDashboardDetail from "../../ws/dashboard/TaskDashboardDetail";
import {DashboardCompareChartsRequest, DashboardDetail} from "../../model/Dashboard";
import TaskDashboardCompare from "../../ws/dashboard/TaskDashboardCompare";

interface CompareGraphicsDataContainerProps {
	chartId: string,
	dashboardId: string
}

const mapStateToProps = CompareGraphicsReducer.autoMapToProps();
const mapActionsToProps = CompareGraphicsActions.autoMapToProps();

type Props = CompareGraphicsDataContainerProps & typeof mapStateToProps & typeof mapActionsToProps;

class CompareGraphicsDataContainer extends Component<Props> {

	public componentDidMount(): void {
		new TaskDashboardDetail(this.props.dashboardId, {})
			.onSuccess((response) => {

				const dashboard: DashboardDetail = response.data as DashboardDetail;
				const submitData: DashboardCompareChartsRequest = {
					items : {
						"1": {
							zone: dashboard.filter.zone.value,
							business_type: dashboard.filter.business_type.value,
							start_time: dashboard.filter.time.value.start,
							end_time: dashboard.filter.time.value.end,
						},
						"2" : {
							zone: dashboard.filter.zone.value,
							business_type: dashboard.filter.business_type.value,
							start_time: dashboard.filter.time.value.start,
							end_time: dashboard.filter.time.value.end,
						}
					}
				}

				const {dashboardId, chartId} = this.props;

				new TaskDashboardCompare(dashboardId, chartId, submitData).execute()
			})

			.execute();
	}

	public componentWillUnmount(): void {
		this.props.dispatchClearReducer();
	}

	private handleApplyChartsFilters = (filters: DashboardCompareChartsRequest): void => {
		const {dashboardId, chartId} = this.props;

		new TaskDashboardCompare(dashboardId, chartId, filters).execute()
	}

	public render(): ReactNode {
		const {dashboard, loading, errors, charts, chartId} = this.props;
		let chart;

		if (dashboard) {
			chart = dashboard.items.filter(chart => chart.id === chartId);
			chart = chart[0].name;
		}

		return (
			<CompareGraphicsView
				dashboard={dashboard}
				title={chart || ''}
				charts={charts}
				applyFilters={this.handleApplyChartsFilters}
				loading={loading}
				errors={errors}
			/>
		);
	}
}
export default connect(mapStateToProps, mapActionsToProps)(CompareGraphicsDataContainer as unknown as React.ComponentType<CompareGraphicsDataContainerProps>);