import {ReducerBuilder} from "co-redux-builders";
import FormResultAllListCardActions from "./FormResultAllListCardActions";
import {PagerBackendProps} from "../../../components/Pager";
import FormResult from "../../../model/FormResult";
import TaskFormResultListAll, {FormResultAllCriteriaProps} from "../../../ws/formResult/TaskFormResultListAll";

interface InitialState {
    formResultsloading: boolean,
    formResults: FormResult[],
    error: string,
    pager: PagerBackendProps,
    criteria: FormResultAllCriteriaProps,
}

const INITIAL_STATE: InitialState = {
    formResultsloading: false,
    formResults: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1
    },
    criteria: {},
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskFormResultListAll.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, formResultsloading: true}
    })
    .onEvent(TaskFormResultListAll.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, formResultsloading: false}
    })
    .onEvent(TaskFormResultListAll.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskFormResultListAll.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            formResults: payload.data,
            pager: payload.pager || oldState.pager,
        }
    })

    .onEvent(FormResultAllListCardActions.onSetCriteria, (oldState, payload) => ({
        ...oldState,
        criteria: payload.criteria,
    }))

    .onEvent(FormResultAllListCardActions.clearFormResultAllListCardReducer, (() => INITIAL_STATE))

    .build()