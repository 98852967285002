import * as React from 'react';
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_DASHBOARDS, TR_DISENO_DEL_DASHBOARD} from "../../../../I18n/constants";
import {
    ROUTE_ADMIN_DASHBOARD_LAYOUT,
    ROUTE_ADMIN_DASHBOARDS,
} from "../../../../routing/Routes";
import {MenuIcon} from "../../../../Config";
import {RouteComponentProps} from "react-router";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import {generateRoute} from "../../../../utils/Router";
import ScreenCard from "../../../../components/screen/ScreenCard";
import DashboardLayoutAdminCard from "./DashboardLayoutAdminCard";

type Props = RouteComponentProps<{ id: string }>

export default class DashboardLayoutAdminScreen extends React.Component<Props> {
    public render(): React.ReactNode {
        const breadcrumbs: BreadcrumbItem[] = [
            {
                name: I18nUtils.tr(TR_DASHBOARDS),
                url: ROUTE_ADMIN_DASHBOARDS,
                icon: MenuIcon.DASHBOARD
            },
            {
                name: I18nUtils.tr(TR_DISENO_DEL_DASHBOARD),
                url: generateRoute(ROUTE_ADMIN_DASHBOARD_LAYOUT, {id: this.props.match.params.id}),
                icon: MenuIcon.DASHBOARD
            }];

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                <DashboardLayoutAdminCard />
            </ScreenCard>
        )
    }
}
