import * as React from "react";
import {AutocompleteOption} from "../../../../components/form/FormAutocomplete";
import {UserSimply} from "../../../../model/User";
import FormPermissionSectionAdmin, {
    ExploitationAutocompleteOptions,
    FormPermissionCardAdminHelpers,
} from "../../forms/formdetail/cards/permission/FormPermissionSectionAdmin";
import {Field, FieldArray, FormErrors, getFormSyncErrors, InjectedFormProps, reduxForm} from "redux-form";
import {ReducerBuilder} from "co-redux-builders";
import DashboardFormAdminScreenReducer from "./DashboardFormAdminScreenReducer";
import Card from "../../../../components/card/Card";
import I18nUtils from "../../../../I18n/I18nUtils";
import {FieldArrayErrors, formStateAutoMapToPropsFactory} from "../../../../utils/FormUtils";
import {
    TR_ACTIVO,
    TR_DASHBOARD,
    TR_ESTADO,
    TR_FILTRAR_POR_TIEMPO,
    TR_FILTRAR_POR_TIPO_DE_SERVICIO,
    TR_FILTRAR_POR_ZONA,
    TR_INACTIVO,
    TR_NO_SE_HA_INTRODUCIDO_NINGUNA_GRAFICA,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVO_DASHBOARD,
    TR_PREDETERMINADO_OBLIGATORIO,
    TR_TIPO_DE_EXPLOTACION,
    TR_TIPO_DE_EXPLOTACION_OBLIGATORIO,
    TR_URL_OBLIGATORIA,
    TR_ZONA,
    TR_ZONA_OBLIGATORIA
} from "../../../../I18n/constants";
import CardHeader from "../../../../components/card/CardHeader";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormSwitch from "../../../../components/form/FormSwitch";
import {connect} from "react-redux";
import {compose} from "redux";
import ItemDashboardFieldArray from "./ItemDashboardFieldArray";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_ADMIN_DASHBOARDS} from "../../../../routing/Routes";
import {goToRoute} from "../../../../utils/Router";
import {isValidURL} from "../../../../utils/StringUtils";
import {RoleError} from "../../userGroups/form/ExploitationRoleFieldArray";
import {UserRoleFieldArrayOption} from "../../../../model/UserRole";
import Role from "../../../../model/Role";
import {DashboardItemData} from "../../../../model/DashboardItem";
import FormCheckbox from "../../../../components/form/FormCheckbox";
import Zone, {ZoneAutocompleteBuilder} from "../../../../model/Zone";
import BusinessType, {BusinessTypeAutocompleteBuilder} from "../../../../model/BusinessType";
import FormSelect from "../../../../components/form/FormSelect";

const FORM_NAME = 'DashboardCardFormAdmin';

export type UserAutoCompleteOptions = Array<AutocompleteOption<UserSimply>>;

interface SynchronousError {
    users: string[],
    roles: RoleError[],
    exploitations: string[],
}

export interface DashboardCardFormAdminData {
    name: string,
    enabled: boolean,
    filter_by_zone: boolean,
    filter_by_business_type: boolean,
    filter_by_time: boolean,
    default_zone: string,
    default_business_type: string,
    items: DashboardItemData[],
    roles: UserRoleFieldArrayOption[],
    exploitations: ExploitationAutocompleteOptions[],
    users: UserAutoCompleteOptions[],
}

export enum DashboardFormAdminFields {
    FORM_NAME = "name",
    FORM_ENABLED = "enabled",
    FORM_FILTER_BY_ZONE = "filter_by_zone",
    FORM_FILTER_BY_BUSINESS_TYPE = "filter_by_business_type",
    FORM_FILTER_BY_TIME = "filter_by_time",
    FORM_DEFAULT_ZONE = "default_zone",
    FORM_DEFAULT_BUSINESS_TYPE = "default_business_type",
    FORM_ITEMS = "items"
}

interface DashboardCardFormAdminExtProps {
    onSubmit: (data: DashboardCardFormAdminData) => void,
    initialValues?: Partial<DashboardCardFormAdminData>,
    roleList: Role[],
    parentLoading: boolean,
    zoneList: Array<Zone>,
    businessTypeList: Array<BusinessType>
}

interface DashboardCardFormAdminProps {
    onSubmit: (data: DashboardCardFormAdminData) => void,
    initialValues?: Partial<DashboardCardFormAdminData>,
    synchronousError: SynchronousError,
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<DashboardCardFormAdminData>(FORM_NAME);

const mapStateToPropsGeneric = (state: any) => {
    return {synchronousError: getFormSyncErrors(DashboardCardFormAdmin.name)(state)}
};

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    mapFormStateToProps,
    DashboardFormAdminScreenReducer.autoMapToProps(),
    mapStateToPropsGeneric);

type Props = DashboardCardFormAdminProps
    & typeof mapStateToProps
    & InjectedFormProps<DashboardCardFormAdminData>
    & DashboardCardFormAdminExtProps;

class DashboardCardFormAdmin extends React.Component<Props> {

    private hasErrors(fieldArrayErrors: string[]): boolean {
        const filteredFieldArrayErrors = fieldArrayErrors.filter((error) => error);
        return !!filteredFieldArrayErrors.find((error) => error.length !== 0);
    };

    private validatePermissionSection = (): boolean => {
        const {synchronousError} = this.props;

        const usersHasErrors = synchronousError.users ?
            this.hasErrors(synchronousError.users) : false;

        const roleTypesHasErrors = synchronousError.roles ?
            this.hasErrors(synchronousError.roles.map((role) => role.role_id)) : false;

        const roleExploitationsHasErrors = synchronousError.roles ?
            this.hasErrors(synchronousError.roles.map((role) => role.exploitation)) : false;

        return usersHasErrors || roleTypesHasErrors || roleExploitationsHasErrors;
    };

    public render(): React.ReactNode {
        const {
            handleSubmit, loading, pristine, initialValues, onSubmit,
            parentLoading, invalid, zoneList, businessTypeList, formState
        } = this.props;

        const zoneOptions = zoneList.map(zone => ZoneAutocompleteBuilder.buildOption(zone));
        const businessTypeOptions = businessTypeList.map(businessType => BusinessTypeAutocompleteBuilder.buildOption(businessType));

        return (
            <Card loading={loading || parentLoading}>
                <CardHeader title={I18nUtils.tr(initialValues ? TR_DASHBOARD : TR_NUEVO_DASHBOARD)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={DashboardFormAdminFields.FORM_NAME}
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                                obligatory={true}
                            />
                            <Field
                                label={I18nUtils.tr(TR_ESTADO)}
                                name={DashboardFormAdminFields.FORM_ENABLED}
                                component={FormSwitch}
                                labelEnabled={I18nUtils.tr(TR_ACTIVO)}
                                labelDisabled={I18nUtils.tr(TR_INACTIVO)}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_FILTRAR_POR_ZONA)}
                                name={DashboardFormAdminFields.FORM_FILTER_BY_ZONE}
                                component={FormCheckbox}
                                col={{md: 2, lg: 2}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_FILTRAR_POR_TIPO_DE_SERVICIO)}
                                name={DashboardFormAdminFields.FORM_FILTER_BY_BUSINESS_TYPE}
                                component={FormCheckbox}
                                col={{md: 2, lg: 2}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_FILTRAR_POR_TIEMPO)}
                                name={DashboardFormAdminFields.FORM_FILTER_BY_TIME}
                                component={FormCheckbox}
                                col={{md: 2, lg: 2}}
                            />
                        </Row>

                        <Row>
                            <div style={{display: formState && formState.values && formState.values.filter_by_zone ? 'block' : 'none'}}>
                                <Field
                                    label={I18nUtils.tr(TR_ZONA)}
                                    name={DashboardFormAdminFields.FORM_DEFAULT_ZONE}
                                    component={FormSelect}
                                    options={zoneOptions}
                                    blankOptionText={' '}
                                    col={{md: 2, lg: 2}}
                                />
                            </div>

                            <div style={{display: formState && formState.values && formState.values.filter_by_business_type ? 'block' : 'none'}}>
                                <Field
                                    label={I18nUtils.tr(TR_TIPO_DE_EXPLOTACION)}
                                    name={DashboardFormAdminFields.FORM_DEFAULT_BUSINESS_TYPE}
                                    component={FormSelect}
                                    options={businessTypeOptions}
                                    blankOptionText={' '}
                                    col={{md: 2, lg: 2}}
                                />
                            </div>
                        </Row>

                        <FieldArray
                            label={'items'}
                            name={DashboardFormAdminFields.FORM_ITEMS}
                            // @ts-ignore
                            component={ItemDashboardFieldArray}
                            rerenderOnEveryChange={true}
                        />

                        <FormPermissionSectionAdmin
                            className={'m-t-20'}
                            invalid={this.validatePermissionSection()}
                        />

                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_DASHBOARDS)}/>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

type DashboardItemError = {
    [P in keyof DashboardItemData]: string
}

function validateItemsDashboard(values: DashboardCardFormAdminData): FieldArrayErrors {
    const itemErrors: DashboardItemError[] = [];

    const hasDefaultItem = !!values.items.find((dashboardItem) => dashboardItem.default);

    if (!hasDefaultItem) {
        return {_error: I18nUtils.tr(TR_PREDETERMINADO_OBLIGATORIO)}
    }

    values.items.forEach((itemDashboard: DashboardItemData, index: number) => {

        itemDashboard[index] = {
            name: '',
            url: ''
        };
        if (!itemDashboard.name || itemDashboard.name.length === 0) {
            itemDashboard[index].name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
        }
        if (!itemDashboard.url || !isValidURL(itemDashboard.url)) {
            itemDashboard[index].url = I18nUtils.tr(TR_URL_OBLIGATORIA);
        }
        itemErrors[index] = itemDashboard[index];
    });

    return itemErrors;
}

function validate(values: DashboardCardFormAdminData, props: DashboardCardFormAdminExtProps) {
    const errors: FormErrors<DashboardCardFormAdminData, FieldArrayErrors> = {
        ...FormPermissionCardAdminHelpers.validate(values, props)
    };

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.items || values.items.length === 0) {
        errors.items = {_error: I18nUtils.tr(TR_NO_SE_HA_INTRODUCIDO_NINGUNA_GRAFICA)};
    } else {
        errors.items = validateItemsDashboard(values);
    }
    if (values.filter_by_zone && (!values.default_zone || values.default_zone === '-1')) {
        errors.default_zone = I18nUtils.tr(TR_ZONA_OBLIGATORIA);
    }
    if (values.filter_by_business_type && (!values.default_business_type || values.default_business_type === '-1')) {
        errors.default_business_type = I18nUtils.tr(TR_TIPO_DE_EXPLOTACION_OBLIGATORIO);
    }
    console.warn('DASHBOARD ERRORS', errors)
    return errors;
}

export default compose(
    reduxForm<DashboardCardFormAdminData, DashboardCardFormAdminExtProps, FieldArrayErrors>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(DashboardCardFormAdmin) as React.ComponentType<DashboardCardFormAdminExtProps>;
