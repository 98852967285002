import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from 'classnames';
import FormInputOption from "./FormInputOption";

export interface FormSelectProps extends WrappedFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    options: FormInputOption[],
    disabledBorderBottom?: boolean,
    blankOptionText: string,
    obligatory?: boolean
}

export default class FormSelect extends Component<FormSelectProps> {

    constructor(props: FormSelectProps & WrappedFieldProps) {
        super(props);
    }

    public render(): ReactNode {
        const {input, meta, name, label, disabled, col, options, blankOptionText, disabledBorderBottom = false, obligatory} = this.props;
        const classSelectComponent =
            classNames(
                'form-control',
                'show-tick',
                'bootstrap-select',
                {'bootstrap-select-error': meta.touched && meta.error},
                disabledBorderBottom ? 'no-border-bottom' : ''
            );

        return (
            <FormCol {...col} >
                <div className="form-group">
                    {label ? <label className={'main-label'}>{label}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label> : null}
                    <select className={classSelectComponent} name={name} {...input} disabled={disabled || false}>
                        {blankOptionText ? <option key={-1} value={-1}>{blankOptionText}</option> : null}
                        {options.map((option: FormInputOption, index) => {
                            return (<option key={index} value={option.value}>{option.name}</option>);
                        })}
                    </select>
                    <label className="error">{meta.touched ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }

}

