import React, {Component, ReactNode} from "react";
import {formStateAutoMapToPropsFactory} from "../../../../../utils/FormUtils";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Row from "../../../../../components/Row";
import {connect} from "react-redux";
import {compose} from "redux";
import FormInput from "../../../../../components/form/FormInput";
import I18nUtils from "../../../../../I18n/I18nUtils";
import {
    TR_ALERTA_FESTIVOS,
    TR_ALERTA_VACACIONES,
    TR_CALENDARIO_SERVICIO_FESTIVOS,
    TR_METODOS_PAGO,
    TR_PLAZAS,
    TR_PLAZAS_OFRECIDAS,
    TR_PLAZAS_OFRECIDAS_INCORRECTAS,
    TR_PLAZAS_REALES,
    TR_PLAZAS_REALES_INCORRECTAS
} from "../../../../../I18n/constants";
import FormDragFile from "../../../../../components/form/FormDragFile";
import FormTextArea from "../../../../../components/form/FormTextArea";
import {goToRoute} from "../../../../../utils/Router";
import {ROUTE_ADMIN_EXPLOITATIONS} from "../../../../../routing/Routes";
import FormCardFooter from "../../../../../components/form/FormCardFooter";
import Alert from "../../../../../model/Alert";
import AlertField from "../fields/AlertField";
import AlertFormModal from "../../../../../components/modal/AlertFormModal";
import {AutocompleteOption} from "../../../../../components/form/FormAutocomplete";
import {ExploitationAutocompleteBuilder, ExploitationSimply} from "../../../../../model/Exploitation";
import {AlertFormAdminData} from "../../../alerts/form/AlertFormAdmin";
import DateFormatter from "../../../../../utils/DateFormatter";
import {UserAutocompleteBuilder} from "../../../../../model/User";
import {UserRoleAutocompleteBuilder} from "../../../../../model/UserRole";
import ExploitationFormAdminActions from "../ExploitationFormAdminActions";

const FORM_NAME = "ExploitationServiciesFormAdmin";

interface ExploitationServicesFormAdminProps{
    initialValues?: Partial<ExploitationServicesFormAdminData>,
    onSubmit: (values: ExploitationServicesFormAdminData) => void,
    loading: boolean,
    exploitationData?: AutocompleteOption<ExploitationSimply>,
    onCancelDeleteAlerts: (ids: string []) => void
}

export interface ExploitationServicesFormAdminData {
    sealedPlaces: number,
    realPlaces: number,
    offeredPlaces: number,
    scheduleImage: string [],
    paymentMethods: string,
    holidaysAlert?: Alert,
    ratesReviewAlert?: Alert
}

enum ServicesFields {
    SEALED_PLACES = "sealedPlaces",
    OFFERED_PLACES = "offeredPlaces",
    REAL_PLACES = "realPlaces",
    SCHEDULE_IMAGE = "scheduleImage",
    PAYMENT_METHODS = "paymentMethods",
    HOLIDAYS_ALERT = "holidaysAlert",
    RATES_REVIEW_ALERT = "ratesReviewAlert"
}

interface ExploitationServicesFormAdminState {
    showAlertFormModal: boolean,
    fieldName: string,
    alertTitle: string,
    currentAlert?: Alert,
    toDeleteAlertList: Array<string>
}


const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationServicesFormAdminData>(FORM_NAME);

type Props = ExploitationServicesFormAdminProps & typeof mapFormStateToProps & InjectedFormProps<ExploitationServicesFormAdminData>;

class ExploitationServiciesFormAdmin extends Component<Props, ExploitationServicesFormAdminState> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            showAlertFormModal: false,
            fieldName: ServicesFields.HOLIDAYS_ALERT,
            alertTitle: I18nUtils.tr(TR_ALERTA_VACACIONES),
            currentAlert: undefined,
            toDeleteAlertList: []
        }
    }

    private getToDeleteAlertsId = (): string [] => {
        const {formState, initialValues} = this.props;
        const ids: string [] = [];

        if (formState) {
            const {values} = formState;

            const holidayAlertId = values && values.holidaysAlert && values.holidaysAlert.id || undefined;
            const ratesReviewAlertId = values && values.ratesReviewAlert && values.ratesReviewAlert.id || undefined;

            if (holidayAlertId && (initialValues.holidaysAlert === undefined)) {
                ids.push(holidayAlertId);
            }
            if (ratesReviewAlertId && (initialValues.ratesReviewAlert === undefined)) {
                ids.push(ratesReviewAlertId)
            }

            const unassignedAlerts = this.state.toDeleteAlertList.filter(alertId => alertId !== holidayAlertId && alertId !== ratesReviewAlertId);

            unassignedAlerts.forEach(unassignedAlert => ids.push(unassignedAlert));
        }

        return ids;
    }

    private handleValuesModalAlert () {
        const {exploitationData} = this.props;
        const {currentAlert} = this.state;

        const defaultValues: Partial<AlertFormAdminData> = {
            name: this.state.alertTitle,
                roles: [{
                role_id: "3",
                exploitation: exploitationData ? [exploitationData] : []
            }]
        }

        if (currentAlert) {
            return {
                ...defaultValues,
                link: currentAlert.link,
                description: currentAlert.description,
                date: DateFormatter.formatInputDate(currentAlert.datetime),
                time: DateFormatter.formatTime(currentAlert.datetime),
                exploitations: currentAlert.receivers.exploitations.map(exploitation => [ExploitationAutocompleteBuilder.buildOption(exploitation)]) || [],
                users: currentAlert.receivers.users.map(user => [UserAutocompleteBuilder.buildOption(user)]) || [],
                roles: currentAlert.receivers.roles.map((userRole) => UserRoleAutocompleteBuilder.buildFieldArrayOption(userRole)) || [],
                frequency: currentAlert.alert_frequency.id,
                state: currentAlert.alert_state.id,
                email: currentAlert.notify_by_email,
                name: currentAlert.name
            }
        }

        return defaultValues;
    }

    public render(): ReactNode {
        const {invalid, pristine, initialValues, onSubmit, handleSubmit, formState, onCancelDeleteAlerts} = this.props;
        return (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Field
                            name={ServicesFields.SEALED_PLACES}
                            component={FormInput}
                            type={"number"}
                            min={1}
                            step={1}
                            label={I18nUtils.tr(TR_PLAZAS)}
                            col={{col: 4, lg: 4}}
                        />

                        <Field
                            name={ServicesFields.REAL_PLACES}
                            component={FormInput}
                            type={"number"}
                            min={1}
                            step={1}
                            label={I18nUtils.tr(TR_PLAZAS_REALES)}
                            col={{col: 4, lg: 4}}
                        />

                        <Field
                            name={ServicesFields.OFFERED_PLACES}
                            component={FormInput}
                            type={"number"}
                            min={1}
                            step={1}
                            label={I18nUtils.tr(TR_PLAZAS_OFRECIDAS)}
                            col={{col: 4, lg: 4}}
                        />
                    </Row>

                    <Row>
                        <Field
                            name={ServicesFields.HOLIDAYS_ALERT}
                            component={AlertField}
                            label={I18nUtils.tr(TR_ALERTA_VACACIONES)}
                            alert={formState && formState.values ? formState.values.holidaysAlert : undefined}
                            onDeleteAlert={() => {
                                const {formState} = this.props;
                                const alert = formState && formState.values && formState.values.holidaysAlert;
                                const id = alert ? alert.id : undefined;

                                if (id) {
                                    this.setState({toDeleteAlertList: [...this.state.toDeleteAlertList, id]})
                                    ExploitationFormAdminActions.dispatchAddAlertToQueue.asConnectedAction(id);
                                }

                                this.props.change(ServicesFields.HOLIDAYS_ALERT, null);
                            }}
                            onShowModal={() => this.setState({
                                showAlertFormModal: true,
                                fieldName: ServicesFields.HOLIDAYS_ALERT,
                                alertTitle: I18nUtils.tr(TR_ALERTA_VACACIONES),
                                currentAlert: formState && formState.values ? formState.values.holidaysAlert : undefined
                            })}
                            col={{md: 6, lg: 6}}
                        />

                        <Field
                            name={ServicesFields.RATES_REVIEW_ALERT}
                            component={AlertField}
                            label={I18nUtils.tr(TR_ALERTA_FESTIVOS)}
                            alert={formState && formState.values ? formState.values.ratesReviewAlert : undefined}
                            onDeleteAlert={() => {
                                const {formState} = this.props;
                                const alert = formState && formState.values && formState.values.ratesReviewAlert;
                                const id = alert ? alert.id : undefined;

                                if (id) {
                                    this.setState({toDeleteAlertList: [...this.state.toDeleteAlertList, id]})
                                    ExploitationFormAdminActions.dispatchAddAlertToQueue.asConnectedAction(id);
                                }

                                this.props.change(ServicesFields.RATES_REVIEW_ALERT, null);
                            }}
                            onShowModal={() => this.setState({
                                showAlertFormModal: true,
                                fieldName: ServicesFields.RATES_REVIEW_ALERT,
                                alertTitle: I18nUtils.tr(TR_ALERTA_FESTIVOS),
                                currentAlert: formState && formState.values ? formState.values.ratesReviewAlert : undefined
                            })}
                            col={{md: 6, lg: 6}}
                        />
                    </Row>

                    <Row>
                        <Field
                            label={I18nUtils.tr(TR_CALENDARIO_SERVICIO_FESTIVOS)}
                            name={ServicesFields.SCHEDULE_IMAGE}
                            component={FormDragFile}
                            preview={true}
                            multiple={false}
                            col={{md: 12, lg: 12}}
                        />
                    </Row>

                    <Row>
                        <Field
                            name={ServicesFields.PAYMENT_METHODS}
                            label={I18nUtils.tr(TR_METODOS_PAGO)}
                            component={FormTextArea}
                            col={{md: 12, lg: 6}}
                        />
                    </Row>

                    <FormCardFooter
                        invalid={invalid}
                        pristine={pristine}
                        isUpdate={!!initialValues}
                        cancelHandler={() => {
                            onCancelDeleteAlerts(this.getToDeleteAlertsId());
                            goToRoute(ROUTE_ADMIN_EXPLOITATIONS);
                        }}/>
                </form>

                <AlertFormModal
                    onSubmitAlert={(response) => {
                        this.props.change(this.state.fieldName, response.data);
                        this.setState({showAlertFormModal: false});
                    }}
                    show={this.state.showAlertFormModal}
                    onClose={() => this.setState({showAlertFormModal: false})}
                    defaultValues={this.handleValuesModalAlert()}
                />
            </>
        );
    }
}

function validate (values: ExploitationServicesFormAdminData): FormErrors<ExploitationServicesFormAdminData> {
    const errors: FormErrors<ExploitationServicesFormAdminData> = {};

    if (values.realPlaces && values.realPlaces <= 0){
        errors.realPlaces = I18nUtils.tr(TR_PLAZAS_REALES_INCORRECTAS);
    }
    if (values.offeredPlaces && values.offeredPlaces <= 0){
        errors.offeredPlaces = I18nUtils.tr(TR_PLAZAS_OFRECIDAS_INCORRECTAS);
    }

    return errors;
}

export default compose(
    reduxForm<ExploitationServicesFormAdminData, ExploitationServicesFormAdminProps>({
        form: FORM_NAME,
        validate,
        enableReinitialize: true
    }),
    connect(mapFormStateToProps as any)
)(ExploitationServiciesFormAdmin);
