import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import AlertFrequency from "../../model/AlertFrequency";

export default class TaskAlertFrequencyList extends BaseGenericRequest<{}, TaskResponse<AlertFrequency[]>> {

    public constructor() {
        super(METHOD.METHOD_POST, Urls.URL_ALERT_FREQUENCY_LIST);
    }
}