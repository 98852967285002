import * as React from "react";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_TIPO_DE_EXPLOTACION, TR_ZONA} from "../../../../I18n/constants";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import Exploitation from "../../../../model/Exploitation";
import {ROUTE_ADMIN_EXPLOITATION_DETAIL} from "../../../../routing/Routes";

interface ExploitationListTableProps {
    data: Exploitation[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ExploitationListTable extends React.Component<ExploitationListTableProps> {
    private gotoItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_EXPLOITATION_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((exploitation: Exploitation) => {
                    return (
                        <tr key={exploitation.id}
                            onClick={() => this.gotoItemDetail(exploitation.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(exploitation.id) : null}
                            <td>
                                {exploitation.business_type ? exploitation.business_type.name : ''}
                            </td>
                            <td>
                                {exploitation.zone ? exploitation.zone.name : ''}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_TIPO_DE_EXPLOTACION)}
                    columnName={'business_type'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_ZONA)}
                    columnName={'zone'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
