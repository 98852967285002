import {ReducerBuilder} from "co-redux-builders";
import Alert from "../../../../model/Alert";
import TaskAlertDetail from "../../../../ws/alert/TaskAlertDetail";
import AlertState from "../../../../model/AlertState";
import AlertFrequency from "../../../../model/AlertFrequency";
import TaskAlertStateList from "../../../../ws/alertState/TaskAlertStateList";
import TaskAlertFrequencyList from "../../../../ws/alertFrequency/TaskAlertFrequencyList";
import AlertFormScreenAdminActions from "./AlertFormScreenAdminActions";
import Exploitation from "../../../../model/Exploitation";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import User from "../../../../model/User";
import TaskUserList from "../../../../ws/user/TaskUserList";
import {AlertType} from "../../../../model/AlertType";
import {AlertScope} from "../../../../model/AlertScope";
import TaskAlertTypeList from "../../../../ws/alertype/TaskAlertTypeList";
import TaskAlertScopesList from "../../../../ws/alertscope/TaskAlertScopesList";
import FormModel from "../../../../model/Form";
import TaskFormList from "../../../../ws/form/TaskFormList";
import TaskDocumentList from "../../../../ws/document/TaskDocumentList";
import DocumentModel from "../../../../model/Document";
import TaskAlertNotificationUserList from "../../../../ws/alertnotificationuser/TaskAlertNotificationUserList";

interface State {
    loading: boolean,
    alert?: Alert,

    exploitationsLoading: boolean,
    exploitations: Exploitation[],

    alertStates: AlertState[],
    alertFrequencies: AlertFrequency[],

    userList: User [],
    usersLoading: boolean,

    alertTypes: AlertType[],
    alertScopes: AlertScope[],
    forms: FormModel[],
    documents: DocumentModel[]
}

const INITIAL_STATE: State = {
    loading: false,
    alert: undefined,

    exploitationsLoading: false,
    exploitations: [],

    alertFrequencies: [],
    alertStates: [],

    userList: [],
    usersLoading: false,

    alertTypes: [],
    alertScopes: [],
    forms: [],
    documents: []
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskAlertDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskAlertDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskAlertDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, alert: payload.data};
    })

    .onEvent(TaskAlertStateList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskAlertStateList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskAlertStateList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, alertStates: payload.data};
    })

    .onEvent(TaskAlertFrequencyList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskAlertFrequencyList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskAlertFrequencyList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, alertFrequencies: payload.data};
    })

    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationsLoading: true
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationsLoading: false
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, exploitations: payload.data};
    })

    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, usersLoading: true}))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, usersLoading: false}))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, userList: payload.data}))

    .onEvent(TaskAlertTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, alertTypes: payload.data}))

    .onEvent(TaskAlertScopesList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, alertScopes: payload.data}))

    .onEvent(TaskFormList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, forms: payload.data}))

    .onEvent(TaskDocumentList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, documents: payload.data}))

    .onEvent(AlertFormScreenAdminActions.dispatchReloadNotifications, (oldState) => {
        new TaskAlertNotificationUserList().execute();
        return {...oldState};
    })

    .onEvent(AlertFormScreenAdminActions.clearReducer, () => INITIAL_STATE)

    .build();