import React, {Component, ReactNode} from "react";
import FormCol, {FormColProps} from "../../../../../components/form/FormCol";
import Icon from "../../../../../components/Icon";
import Row from "../../../../../components/Row";
import CancelModal from "../../../../../components/modal/CancelModal";
import I18nUtils from "../../../../../I18n/I18nUtils";
import {
    TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTE_DOCUMENTO,
    TR_SE_PERDERAN_TODOS_LOS_DATOS
} from "../../../../../I18n/constants";
import DocumentModel from "../../../../../model/Document";

interface DocumentFieldProps{
    label: string,
    document?: DocumentModel,
    col: FormColProps,
    onDeleteDocument: (document: DocumentModel) => void,
    showModal:() => void,
    obligatory?: boolean
}

interface DocumentFieldState {
    showDocumentDeleteConfirmModal: boolean
}

export default class DocumentField extends Component<DocumentFieldProps, DocumentFieldState> {
    constructor(props: Readonly<DocumentFieldProps>) {
        super(props);
        this.state = {
            showDocumentDeleteConfirmModal: false
        }
    }

    public render(): ReactNode {
        const {col, label, onDeleteDocument, document, obligatory} = this.props;
        return (
            <>
                <FormCol {...col}>
                    <Row className={'m-l-0 m-r-0'}>
                        <label>{label}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label>
                    </Row>
                    {this.renderDocumentFieldBody()}
                </FormCol>

                <CancelModal
                    title={I18nUtils.tr(TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTE_DOCUMENTO)}
                    message={I18nUtils.tr(TR_SE_PERDERAN_TODOS_LOS_DATOS)}
                    onConfirmAction={() => {
                        onDeleteDocument(document as DocumentModel);
                        this.setState({showDocumentDeleteConfirmModal: false});
                    }}
                    show={this.state.showDocumentDeleteConfirmModal}
                    onClose={() => this.setState({showDocumentDeleteConfirmModal: false})}
                />
            </>
        );
    }

    private renderDocumentFieldBody(): ReactNode {
        const {document, showModal} = this.props;

        if(document) {
            return(
                <>
                    <label
                        className={"document-info-label"}
                        onClick={() => showModal()}
                    >
                        {document.name}
                    </label>
                    <Row className={'m-l-0 m-r-0'}>
                        <a className={'icon-field-remove'}
                           onClick={() => this.setState({showDocumentDeleteConfirmModal: true})}>
                            <Icon icon={'remove_circle'}/>
                        </a>
                    </Row>
                </>
            );
        }
        return (
            <Row className={'m-l-0 m-r-0'}>
                <a className={'icon-field-add'}
                   onClick={() => showModal()}>
                    <Icon icon={'add_circle'}/>
                </a>
            </Row>
        );
    }
}