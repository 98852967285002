import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import User from "../../model/User";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";

type UserCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskUserList extends BaseGenericRequest<UserCriteriaProps, TaskResponse<User[]>> {

    private readonly data: UserCriteriaProps;

    public constructor(criteria: UserCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_USER_LIST);
        this.data = criteria;
    }

    protected getRequest(): UserCriteriaProps {
        return this.data;
    }

}