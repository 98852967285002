import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import AlertSource from "../../model/AlertSource";

export default class TaskAlertSourceList extends BaseGenericRequest<{}, TaskResponse<AlertSource[]>> {

    public constructor() {
        super(METHOD.METHOD_POST, Urls.URL_ALERT_SOURCE_LIST);
    }
}