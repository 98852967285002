import {ReducerBuilder} from "co-redux-builders";
import SideBarActions from "./SideBarActions";

const INITAL_STATE = {
    sidebarDocked: true,
    sidebarOpen: false,
};


export default ReducerBuilder.newBuilder(INITAL_STATE)
    .onEvent(SideBarActions.toggleSideBar, (oldState) => ({...oldState, sidebarOpen: !oldState.sidebarOpen}))
    .onEvent(SideBarActions.onSetSideBar, (oldState, payload) => ({...oldState, sidebarOpen: payload.open}))
    .onEvent(SideBarActions.onChangeMediaQuery, (oldState, payload) => ({...oldState, sidebarDocked: payload.mediaQueryList.matches}))
    .build();
