import * as React from "react";
import FormCol from "../../../../components/form/FormCol";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_BUSCANDO,
    TR_BUSCAR,
    TR_CANAL,
    TR_ESTADO,
    TR_FECHA_ENVIO,
    TR_FECHA_RESOLUCION,
    TR_FILTROS,
    TR_NO_SE_HAN_ENCONTRADO_RESULTADOS,
    TR_NOMBRE,
    TR_PENDIENTE,
    TR_RESPONSABLE,
    TR_RESUELTA,
    TR_TIPO_DE_NOTIFICATION,
} from "../../../../I18n/constants";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import FormInputOption from "../../../../components/form/FormInputOption";
import Row from "../../../../components/Row";
import {AlertNotificationRequest} from "../../../../model/AlertNotification";
import NotificationListFilterReducer from "./NotificationListFilterReducer";
import NotificationListFilterActions from "./NotificationListFilterActions";
import {connect} from "react-redux";
import FormMultiSelect from "../../../../components/form/FormMultiSelect";
import {debounce} from "lodash";
import TaskUserList from "../../../../ws/user/TaskUserList";
import {CardSectionCollapsed} from "../../../../components/CardSectionCollapsed";
import TaskAlertSourceList from "../../../../ws/alertSource/TaskAlertSourceList";
import TaskAlertNotificationListFilter from "../../../../ws/alertNotification/TaskAlertNotificationListFilter";
import TaskAlertTypeList from "../../../../ws/alertype/TaskAlertTypeList";
import DateRangeInput from "../../../../components/date/DateRangeInput";

const mapStateToProps = NotificationListFilterReducer.autoMapToProps();
const mapActionsToProps = NotificationListFilterActions.autoMapToProps();

interface NotificationListFilterProps {
    onCriteriaHandler: (criteria: Partial<AlertNotificationRequest>) => void,
    criteria: Partial<AlertNotificationRequest>,
}

type Props = NotificationListFilterProps & typeof mapStateToProps & typeof mapActionsToProps;

class NotificationListFilter extends React.Component<Props> {

    public componentDidMount(): void {
        new TaskAlertTypeList().execute();
        new TaskAlertSourceList().execute();
    }

    public componentWillUnmount(): void {
        this.onFilterDebounced.cancel();
    }

    private onFilterList = (newCriteria?: Partial<AlertNotificationRequest>): void => {
        const {onCriteriaHandler, criteria} = this.props;
        onCriteriaHandler({...criteria, ...newCriteria});
    };

    private onFilterDebounced = debounce(this.onFilterList, 500);

    private onNotificationNameSearch = (newSearch: string): void => {
        new TaskAlertNotificationListFilter({search: newSearch, limit: 0}).execute();
    };

    private onStateSelect = (stateValue: string): void => {
        let newCriteria = stateValue === '-1' ? {solved: undefined} : {solved: stateValue === '1'};
        this.onFilterDebounced(newCriteria)
    };

    private onNotificationTypeSelect = (alertTypeId: string): void => {
        const {onSelectAlertTypeId, alertTypeSelectedIds} = this.props;
        const selectedTypeIds = alertTypeSelectedIds.includes(alertTypeId) ?
            alertTypeSelectedIds.filter((id) => id !== alertTypeId) : [...alertTypeSelectedIds, alertTypeId];
        this.onFilterDebounced({
            alert_type_ids: selectedTypeIds.length !== 0 ? selectedTypeIds : undefined
        });
        onSelectAlertTypeId(alertTypeId);
    };

    private onNotificationSourceSelect = (alertSourceId: string): void => {
        const {onSelectAlertSourceId, alertSourceSelectedIds} = this.props;
        const selectedSourceIds = alertSourceSelectedIds.includes(alertSourceId) ?
            alertSourceSelectedIds.filter((id) => id !== alertSourceId) : [...alertSourceSelectedIds, alertSourceId];
        this.onFilterDebounced({
            alert_scope_ids: selectedSourceIds.length !== 0 ? selectedSourceIds : undefined
        });
        onSelectAlertSourceId(alertSourceId);
    };

    private onSendDateChange = (startDate?: Date, endDate?: Date): void => {
        const newCriteria = {
            start_creation_date: startDate ? startDate.toISOString() : undefined,
            end_creation_date: endDate ? endDate.toISOString() : undefined,
        };
        this.onFilterDebounced(newCriteria);
    };

    private onSolveDateChange = (startDate?: Date, endDate?: Date): void => {
        const newCriteria = {
            start_solve_date: startDate ? startDate.toISOString() : undefined,
            end_solve_date: endDate ? endDate.toISOString() : undefined,
        };
        this.onFilterDebounced(newCriteria);
    };

    private onUserSearch = (newSearch: string): void => {
        new TaskUserList({search: newSearch, limit: 0}).execute();
    };

    private getInputOptions = (list: any): FormInputOption[] => {
        return list.map((item) => ({value: item.id, name: item.name}))
    };

    public render(): React.ReactNode {
        const {
            alerts, alertsLoading,
            alertTypes, alertTypeSelectedIds, users, usersLoading, criteria, alertSources,
            alertSourceSelectedIds
        } = this.props;

        const notificationOptions: FormInputOption[] = alerts.map((notification) => ({
            value: notification.id,
            name: notification.alert ? notification.alert.name : ''
        }));
        const stateOptions: FormInputOption[] = [
            {value: '0', name: I18nUtils.tr(TR_PENDIENTE)},
            {value: '1', name: I18nUtils.tr(TR_RESUELTA)}];
        const alertTypeOptions = this.getInputOptions(alertTypes);
        const userOptions = this.getInputOptions(users);
        const alertSourceOptions = this.getInputOptions(alertSources);

        const searchPlaceholder = I18nUtils.tr(TR_BUSCAR);

        return (
            <CardSectionCollapsed
                title={I18nUtils.tr(TR_FILTROS)}
                initialCollapsed={!criteria}
                className={'card-filters'}
            >
                <Row>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_NOMBRE)}</label>
                                <AsyncTypeahead
                                    placeholder={searchPlaceholder}
                                    promptText={searchPlaceholder}
                                    options={notificationOptions}
                                    onChange={(selected) => {
                                        if (selected && selected[0])
                                            this.onFilterDebounced({name: selected[0].name})
                                    }}
                                    onInputChange={
                                        (newText: string) => newText === '' && this.onFilterDebounced({name: undefined})
                                    }
                                    labelKey={'name'}
                                    filterBy={() => true}
                                    isLoading={false}
                                    onSearch={this.onNotificationNameSearch}
                                    searchText={alertsLoading ?
                                        I18nUtils.tr(TR_BUSCANDO) :
                                        I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                                />
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <label>{I18nUtils.tr(TR_ESTADO)}</label>
                            <select
                                className={'form-control show-tick bootstrap-select'}
                                onChange={(event) => this.onStateSelect(event.target.value)}
                            >
                                <option key={'-1'} value={'-1'}/>
                                {stateOptions.map((option: FormInputOption, index) => {
                                    return (<option key={index} value={option.value}>{option.name}</option>);
                                })}
                            </select>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <label>{I18nUtils.tr(TR_TIPO_DE_NOTIFICATION)}</label>
                            <FormMultiSelect selectHandler={this.onNotificationTypeSelect}
                                             options={alertTypeOptions}
                                             selectedOptions={alertTypeSelectedIds}
                            />
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <label>{I18nUtils.tr(TR_CANAL)}</label>
                            <FormMultiSelect selectHandler={this.onNotificationSourceSelect}
                                             options={alertSourceOptions}
                                             selectedOptions={alertSourceSelectedIds}
                            />
                        </div>
                    </FormCol>
                </Row>
                <Row>
                    <FormCol md={4} lg={4}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA_ENVIO)}</label>
                                <DateRangeInput
                                    startDate={criteria && criteria.start_creation_date ? criteria.start_creation_date as any : undefined}
                                    endDate={criteria && criteria.end_creation_date ? criteria.end_creation_date as any : undefined}
                                    onChange={(startDate, endDate) => this.onSendDateChange(startDate, endDate)}
                                    onClear={() => this.onSendDateChange(undefined, undefined)}
                                />

                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={4} lg={4}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA_RESOLUCION)}</label>
                                <DateRangeInput
                                    startDate={criteria && criteria.start_solve_date ? criteria.start_solve_date as any : undefined}
                                    endDate={criteria && criteria.end_solve_date ? criteria.end_solve_date as any : undefined}
                                    onChange={(startDate, endDate) => this.onSolveDateChange(startDate, endDate)}
                                    onClear={() => this.onSolveDateChange(undefined, undefined)}
                                />
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_RESPONSABLE)}</label>
                                <AsyncTypeahead
                                    placeholder={searchPlaceholder}
                                    promptText={searchPlaceholder}
                                    options={userOptions}
                                    onChange={(selected: FormInputOption[]) => {
                                        if (selected && selected[0]) {
                                            this.onFilterDebounced({solved_user_ids: [selected[0].value]})
                                        }
                                    }}
                                    onInputChange={
                                        (newText: string) => newText === '' && this.onFilterDebounced({solved_user_ids: undefined})
                                    }
                                    labelKey={'name'}
                                    filterBy={() => true}
                                    isLoading={false}
                                    onSearch={this.onUserSearch}
                                    searchText={usersLoading ?
                                        I18nUtils.tr(TR_BUSCANDO) :
                                        I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                                />
                            </div>
                        </div>
                    </FormCol>
                </Row>

            </CardSectionCollapsed>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(NotificationListFilter as unknown as React.ComponentType<NotificationListFilterProps>);
