import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import Alert from "../../model/Alert";

interface AlertCustomCriteriaProps {
    exploitation_ids?: string[],
}

type AlertCriteriaProps = TaskCriteriaRequest<AlertCustomCriteriaProps>

export default class TaskAlertList extends BaseGenericRequest<AlertCriteriaProps, TaskResponse<Alert[]>> {

    private readonly data: AlertCriteriaProps;

    public constructor(criteria: AlertCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_ALERT_LIST);
        this.data = criteria;
    }

    protected getRequest(): AlertCriteriaProps {
        return this.data;
    }

}