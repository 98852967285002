import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import {NativeRadioControl, Radio} from "@material/react-radio";
import FormInputOption from "./FormInputOption";

export interface FormRadioGroupProps extends WrappedFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    options: FormInputOption[],
}

export default class FormRadioGroup extends Component<FormRadioGroupProps> {

    constructor(props: FormRadioGroupProps & WrappedFieldProps) {
        super(props);
    }

    public render(): ReactNode {
        const {input, meta, name, label, disabled, col, options} = this.props;
        return (
            <FormCol {...col} >
                <div>
                    <label className={'main-label'}>{label}</label>
                    <div>
                        {options.map((option: FormInputOption) => {
                            return (
                                <Radio label={option.name} key={option.value} disabled={disabled} name={name}>
                                    <NativeRadioControl
                                        {...input}
                                        checked={input.value === option.value}
                                        onChange={(e) => input.onChange(option.value)}
                                    />
                                </Radio>
                            );
                        })}
                    </div>
                </div>
                <label className="error">{meta.touched ? meta.error : ""}</label>
            </FormCol>
        );
    }

}

