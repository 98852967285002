import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import FormResult, {FormResultData} from "../../model/FormResult";
import {generateRoute} from "../../utils/Router";

export default class TaskFormResultAdd extends BaseGenericRequest<FormResultData, TaskResponse<FormResult>> {

    private readonly data: FormResultData;

    public constructor(data: FormResultData, formId: string) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_FORM_RESULT_ADD, {formId}));
        this.data = data;
    }

    protected getRequest(): FormResultData {
        return this.data;
    }
}
