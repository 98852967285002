import {ReducerBuilder} from "co-redux-builders";
import Exploitation from "../../../../model/Exploitation";
import TaskExploitationDetail from "../../../../ws/exploitation/TaskExploitationDetail";
import BusinessType from "../../../../model/BusinessType";
import Zone from "../../../../model/Zone";
import TaskBusinessTypeList from "../../../../ws/businessType/TaskBusinessTypeList";
import TaskZoneList from "../../../../ws/zone/TaskZoneList";
import User from "../../../../model/User";
import TaskUserList from "../../../../ws/user/TaskUserList";
import {CompanyType} from "../../../../model/CompanyType";
import TaskCompanyTypeList from "../../../../ws/companytype/TaskCompanyTypeList";
import TaskAlertDelete from "../../../../ws/alert/TaskAlertDelete";
import TaskDocumentDelete from "../../../../ws/document/TaskDocumentDelete";
import ExploitationFormAdminActions from "./ExploitationFormAdminActions";

interface InitialState {
    loadingExploitation: boolean,
    exploitation?: Exploitation,

    businessTypeList: BusinessType[],

    zoneList: Zone[],

    // Base form
    loadingUsers: boolean,
    users: User[]

    // Characteristics form
    companies: CompanyType [],
    loadingCompanies: boolean,

    alertsDeleteQueue: Array<string>,
    documentDelete?: string
}

const INITIAL_STATE: InitialState = {
    loadingExploitation: false,
    exploitation: undefined,

    businessTypeList: [],

    zoneList: [],

    loadingUsers: false,
    users: [],

    companies: [],
    loadingCompanies: false,

    alertsDeleteQueue: [],
    documentDelete: undefined
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskExploitationDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loadingExploitation: true
    }))
    .onEvent(TaskExploitationDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loadingExploitation: false
    }))
    .onEvent(TaskExploitationDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, exploitation: payload.data};
        })

    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loadingExploitation: true
    }))
    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loadingExploitation: false
    }))
    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, businessTypeList: payload.data};
        })

    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loadingExploitation: true
    }))
    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loadingExploitation: false
    }))
    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, zoneList: payload.data};
    })

    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loadingUsers: true}))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loadingUsers: false}))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState,payload) => ({...oldState, users: payload.data}))

    .onEvent(TaskCompanyTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loadingCompanies: true}))
    .onEvent(TaskCompanyTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loadingCompanies: false}))
    .onEvent(TaskCompanyTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, companies: payload.data}))

    .onEvent(ExploitationFormAdminActions.clearReducer, () => INITIAL_STATE)

    .onEvent(ExploitationFormAdminActions.dispatchAddAlertToQueue, (oldState, payload) => {
        const newState = {...oldState};

        newState.alertsDeleteQueue = [...newState.alertsDeleteQueue, payload]

        return newState;
    })

    .onEvent(ExploitationFormAdminActions.dispatchSetDeleteDocument, (oldState, payload) =>
        ({...oldState, documentDelete: payload}))

    .onEvent(TaskAlertDelete.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState) => ({...oldState, alertsDeleteQueue: []}))

    .onEvent(TaskDocumentDelete.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) =>
        ({...oldState, documentDelete: undefined}))

    .build();