import UserRole, {UserRoleData} from "./UserRole";
import {UserGroupSimply} from "./UserGroup";
import {AutocompleteOption} from "../components/form/FormAutocomplete";

export interface UserSimply {
    id: string,
    name: string,
    last_name: string,
    email: string,
}

export default interface User extends UserSimply {
    password?: string,
    roles: UserRole[],
    groups: UserGroupSimply[],
    admin: boolean,
}

export interface LinkData {
    name: string,
    last_name: string,
    email: string,
    password?: string,
    groups: string[],
    roles: UserRoleData[],
}

export class UserAutocompleteBuilder {

    public static buildOption(user: User | UserSimply): AutocompleteOption<User | UserSimply> {
        return ({name: `${user.name} ${user.last_name}`, value: user.id, data: user});
    }

}