import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import Icon from "../Icon";
import Config, {MenuIcon} from "../../Config";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_ARRASTRA_LOS_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS,
    TR_ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO,
    TR_FICHERO_CARGADO_CORRECTAMENTE
} from "../../I18n/constants";
import FileProgress from "../FileProgress";
import FileInfo from "../FileInfo";
import Urls from "../../ws/Urls";
import AuthManager from "../../utils/AuthManager";
import {METHOD} from "co-generic-request";
import AlertComponent from "../../base/alerts/AlertComponent";

export interface FormDragFileProps extends WrappedFieldProps {
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    showError?: boolean,
    maxMBSize?: number,
    multiple?: boolean,
    fileTypes?: string[],
    fileIcon?: string,
    preview?: boolean,    // Vista previa del primer archivo (solo imágenes)
    obligatory?: boolean
}

interface DropZoneFile extends File {
    path: string
}

interface State {
    uploadingFiles: DropZoneFile[],
    touched: boolean,

    [fileIndex: number]: number,
}

export default class FormDragFile extends Component<FormDragFileProps, State> {

    public state: State = {
        uploadingFiles: [],
        touched: false,
    };

    private requests: XMLHttpRequest[] = [];

    public componentWillUnmount(): void {
        this.requests.forEach((request) => request.abort())
    }

    private sendFile = (file: DropZoneFile, index: number): void => {
        const {input: {onChange}, multiple} = this.props;

        const data = new FormData();
        data.append('file', file, file.name);

        const request = this.requests[index];

        request.upload.onprogress = (event) => {
            const percent = +((event.loaded / event.total) * 100).toFixed(2);

            this.setState({[index]: percent});
        };
        request.onreadystatechange = () => {
            if (request.readyState !== 4) {
                return;
            }

            if (request.status !== 0) {
                const response = JSON.parse(request.response);
                if (response.success) {
                    // importante que lea el value desde esta línea!!!
                    onChange(multiple ? [response.data.url, ...this.props.input.value] : [response.data.url]);
                    AlertComponent.success(I18nUtils.tr(TR_FICHERO_CARGADO_CORRECTAMENTE))
                } else {
                    AlertComponent.error(response.message);
                }
            }
        };

        request.open(METHOD.METHOD_POST, Urls.URL_FILE_UPLOAD);
        if (AuthManager.isLogged()) {
            request.setRequestHeader("Authorization", `Bearer ${AuthManager.getAuthToken()}`);
        }
        request.setRequestHeader("Accept", "application/json");
        request.send(data);
    };

    private onDrop = (acceptedFiles: DropZoneFile[]): void => {
        const newState: State = {
            uploadingFiles: [],
            touched: false,
        };

        // resetea el array de peticiones ajax
        this.requests.length = 0;

        // iniciamos el estado individual antes de la carga
        acceptedFiles.forEach((file, index) => {
            this.requests.push(new XMLHttpRequest());
            newState.uploadingFiles.push(file);
            newState[index] = 0;
        });

        this.setState(newState);

        acceptedFiles.forEach(this.sendFile);
    };

    private onRemoveFile = (toRemoveUrl: string): void => {
        const {input: {onChange, value}} = this.props;
        if (Array.isArray(value)) {
            onChange(value.filter((url) => url !== toRemoveUrl));
        }
        this.setState({touched: true});
    };

    private isUploadingFile = (): boolean => {
        return !!this.requests.find((request) => request.readyState !== 4);
    };

    public render(): ReactNode {
        const {meta, label, col, showError, input: {value}, multiple, preview, obligatory} = this.props;

        const hasFiles = this.isUploadingFile() || (Array.isArray(value) && value.length !== 0);

        return (
            <FormCol {...col} >
                <div className={'form-group'}>
                    {label ? <label className='main-label'>{label || ''}<label
                        className={'obligatory'}>{(obligatory ? "*" : "")}</label></label> : null}

                    {
                        preview ? this.renderFilePreview() : null
                    }

                    {hasFiles && !multiple ?
                        this.renderFiles() :
                        this.renderDropZone(hasFiles)
                    }

                    <label className="error">{(this.state.touched || showError) ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }

    private renderFilePreview(): ReactNode {
        const {input: {value}} = this.props;

        if (value.length > 0 && value[0]) {
            return (
                <div>
                    <img src={value[0]} className={"img-preview"}/>
                </div>
            )
        }

        return null;
    }

    private renderDropZone = (hasFiles: boolean): React.ReactNode => {
        const {disabled, maxMBSize = Config.MAX_FILE_SIZE_MB, fileTypes = [], multiple = false} = this.props;

        const maxSize = maxMBSize * 1024 * 1024;

        return (
            <Dropzone onDrop={this.onDrop}
                      disabled={disabled || this.isUploadingFile()}
                      maxSize={maxSize}
                      multiple={multiple}
                      accept={fileTypes.join(',')}
                      onFileDialogCancel={() => this.setState({touched: true})}
            >
                {({getRootProps, getInputProps}) => {
                    return (
                        <div className={
                            classNames("dropzone", {
                                disabled,
                            })}>
                            <div {...getRootProps()} className={'dz-root'}>
                                <input {...getInputProps()}/>
                                {hasFiles ?
                                    this.renderFiles() :
                                    this.renderDZMessage()}
                            </div>
                        </div>
                    )
                }}
            </Dropzone>
        )
    };

    private renderDZMessage = (): React.ReactNode => {
        const {fileIcon = MenuIcon.DOCUMENTATION, multiple = false} = this.props;
        return (
            <div className={'dz-message'}>
                <Icon icon={fileIcon}/>
                <p>{I18nUtils.tr(multiple ?
                    TR_ARRASTRA_LOS_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS :
                    TR_ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO)}</p>
            </div>
        )
    };

    private renderFiles = (): React.ReactNode => {
        const {input: {value}, multiple = false, fileIcon = MenuIcon.DOCUMENTATION, disabled = false} = this.props;
        const {uploadingFiles} = this.state;

        return (
            <div className={'dz-files'}>
                {Array.isArray(value) && value.map((url: string) => (
                    <FileInfo key={url}
                              url={url}
                              disabled={disabled}
                              icon={fileIcon}
                              fileName={url.replace(Urls.URL_FILE_UPLOAD + 's/', '')}
                              removeHandler={() => this.onRemoveFile(url)}
                              className={multiple ? '' : 'p-l-0 m-t-15'}
                    />
                ))}
                {this.requests.map((request, index) => {
                    return request.readyState !== 4 && <FileProgress
                        key={index}
                        icon={fileIcon}
                        cancelHandler={(event) => this.requests[index].abort()}
                        percentLoaded={this.state[index] || 0}
                        size={uploadingFiles[index].size}/>
                })}
            </div>
        )
    }

}

