import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import FormResult from "../../model/FormResult";
import {generateRoute} from "../../utils/Router";

export interface FormResultCustomCriteria {
    user_id?: string,
}

type FormResultCriteriaProps = TaskCriteriaRequest<FormResultCustomCriteria>

export default class TaskFormResultList extends BaseGenericRequest<FormResultCriteriaProps, TaskResponse<FormResult[]>> {

    private readonly data: FormResultCriteriaProps;

    public constructor(criteria: FormResultCriteriaProps = {}, formId: string) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_FORM_RESULT_LIST, {formId}));
        this.data = criteria;
    }

    protected getRequest(): FormResultCriteriaProps {
        return this.data;
    }

}