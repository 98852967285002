import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Zone from "../../model/Zone";

export default class TaskZoneDetail extends BaseGenericRequest<{}, TaskResponse<Zone>> {

    public constructor(zoneId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_ZONE_DETAIL, {uuid: zoneId}));
    }
}
