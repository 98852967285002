import {ReducerBuilder} from "co-redux-builders";

const INITAL_STATE = {

};


export default ReducerBuilder.newBuilder(INITAL_STATE)
    // .onEvent(LoginActions.setUser, (oldState, payload) => {
    //     return buildNewState({...oldState, users: payload.users});
    // })
    // .onEvent(LoginActions.setPassword, (oldState, payload) => {
    //     return buildNewState({...oldState, password: payload.password});
    // })
    // .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
    // .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
    // .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, error: payload}))
    // .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
    //     if (payload.success === false) {
    //         return ({...oldState, error: payload.message})
    //     }
    //     return ({...oldState})
    // })
    .build();
