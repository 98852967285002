import React from "react";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {AlertNotificationUser} from "../../../../model/AlertNotificationUser";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_DESCRIPCION,
    TR_FECHA,
    TR_INTRODUZCA_OBSERVACIONES_PERTINENTES,
    TR_RESOLVER,
    TR_RESOLVER_NOTIFICACIONES_USUARIO,
    TR_USUARIO,
    TR_VER
} from "../../../../I18n/constants";
import Button from "../../../../components/Button";
import SolveNotificationUserModal from "../../../../components/modal/SolveNotificationUserModal";
import DateFormatter from "../../../../utils/DateFormatter";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {ROUTE_FORM_RESULT_ADD} from "../../../../routing/Routes";
import FormResultFormScreenActions from "../../../form/formResult/FormResultFormScreenActions";
import AlertNotificationUserReducer from "./AlertNotificationUserReducer";
import AlertNotificationUserActions from "./AlertNotificationUserActions";
import {connect} from "react-redux";

interface AlertNotificationUserTableProps {
    data: AlertNotificationUser[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

interface AlertNotificationUserTableState {
    showSolveModal: boolean,
    id: string,
    formResultId?: string
}

const mapStateToProps = AlertNotificationUserReducer.autoMapToProps();
const mapActionsToProps = AlertNotificationUserActions.autoMapToProps();

type Props = AlertNotificationUserTableProps & typeof mapStateToProps & typeof mapActionsToProps;

class AlertNotificationUserTable extends React.Component<Props, AlertNotificationUserTableState> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            showSolveModal: false,
            id: '',
            formResultId: undefined
        }
    }

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((notification: AlertNotificationUser) => {
                    const centeredTd = {
                        display: "flex",
                        justifyContent: "center"
                    };

                    return (
                        <tr key={notification.id}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(notification.id) : null}
                            <td>
                                {notification.user.name}
                            </td>
                            <td>
                                {notification.alert_notification.alert.description}
                            </td>
                            <td>
                                {DateFormatter.formatInputDate(notification.alert_notification.creation_date)}
                            </td>
                            {
                                !notification.alert_notification.solved &&
                                <td style={centeredTd}>
                                    <Button
                                        className={"btn-sm btn-primary"}
                                        text={I18nUtils.tr(TR_RESOLVER)}
                                        onClick={() => {
                                            const linkedForm = notification.alert_notification.alert.form;
                                            this.props.setNotificationId(notification.id);

                                            if (linkedForm) {
                                                FormResultFormScreenActions.goToRouteAlertNotificationUser.asConnectedAction();
                                                goToRoute(generateRoute(ROUTE_FORM_RESULT_ADD, {formId: linkedForm.id}));
                                            } else {
                                                this.props.openModal();
                                            }
                                        }}
                                    />
                                </td>
                            }

                            {
                                notification.alert_notification.alert.link &&
                                <td style={centeredTd}>
                                    <button className={"btn waves-effect btn-sm btn-secondary"}>
                                        <a
                                            style={{
                                                color: "black",
                                                textDecoration: "none",
                                            }}
                                            href={notification.alert_notification.alert.link}
                                            target={'_blank'}>
                                            {I18nUtils.tr(TR_VER)}
                                        </a>
                                    </button>
                                </td>
                            }

                        </tr>
                    );
                })}
                </tbody>
                <SolveNotificationUserModal
                    title={I18nUtils.tr(TR_RESOLVER_NOTIFICACIONES_USUARIO)}
                    message={I18nUtils.tr(TR_INTRODUZCA_OBSERVACIONES_PERTINENTES)}
                    textConfirmButton={I18nUtils.tr(TR_RESOLVER)}
                    show={this.props.showResolveModal}
                    onClose={() => this.props.closeModal()}
                />
            </table>
        );
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_USUARIO)}
                    columnName={'user'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_DESCRIPCION)}
                    columnName={'description'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'creation_date'}
                    {...commonProps}
                />
            </tr>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(AlertNotificationUserTable as unknown as React.ComponentType<AlertNotificationUserTableProps>);
 