import * as React from 'react';
import ZoneListCardAdminReducer from "./ZoneListCardAdminReducer";
import {connect} from "react-redux";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ELIMINAR_ZONAS,
    TR_NO_EXISTEN_ZONAS,
    TR_ZONAS,
} from "../../../../I18n/constants";
import TaskZoneList from "../../../../ws/zone/TaskZoneList";
import {ROUTE_ADMIN_ZONE_ADD} from "../../../../routing/Routes";
import ZoneListTable from "./ZoneListTable";
import CardList from "../../../../components/card/CardList";
import ZoneListAdminScreenActions from "./ZoneListCardAdminActions";
import TaskZoneDelete from "../../../../ws/zone/TaskZoneDelete";

const mapStateToProps = ZoneListCardAdminReducer.autoMapToProps();
const mapActionsToProps = ZoneListAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class ZoneListCardAdmin extends React.Component<Props> {
    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render() {
        const {zones, error, loading, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_ZONAS)}
                      sort={{column: 'name'}}
                      TaskList={TaskZoneList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_ZONAS)}}
                      data={zones}
                      error={error}
                      ItemsTable={ZoneListTable}
                      addRoute={ROUTE_ADMIN_ZONE_ADD}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_ZONAS),
                          Task: TaskZoneDelete,
                      }}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ZoneListCardAdmin as unknown as React.ComponentType<{}>);

