import * as React from 'react';
import {Component, ReactNode} from 'react';
import classNames from 'classnames';

export interface RowProps {
    clearfix?: boolean,
    className?: string,
}

export default class Row extends Component<RowProps> {

    public render(): ReactNode {
        const classComponent = classNames('row', this.props.className, { clearfix: this.props.clearfix});
        return (
            <div className={classComponent}>
                {this.props.children}
            </div>
        );
    }

}

