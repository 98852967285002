import BaseGenericRequest from "../BaseGenericRequest";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import {sha256} from "js-sha256";
import Urls from "../Urls";
import Password from "../../model/Password";
import TaskResponse from "../TaskResponse";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE,
    TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES
} from "../../I18n/constants";

export default class TaskUserPasswordEdit extends BaseGenericRequest <Password, TaskResponse<any>> {

    private readonly data: Password;

    public constructor(password: Password, userId?: string, token?: string) {
        super(
            METHOD.METHOD_POST,
            userId ?
                generateRoute(Urls.URL_PASSWORD_EDIT, {uuid: userId}) :
                Urls.URL_CURRENT_PASSWORD_EDIT
        );

        this.setErrorMessage(404, I18nUtils.tr(TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE));
        this.setErrorMessage(490, I18nUtils.tr(TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES));

        if (token) {
            this.addHeader("Authorization", `Bearer ${token}`);
        }
        this.data = password;

    }

    protected getRequest(): Password {
        return {
            ...this.data,
            password: sha256(this.data.password)
        };
    }
}