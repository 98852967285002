import {ReducerBuilder} from "co-redux-builders";
import UserGroup from "../../../../model/UserGroup";
import TaskUserGroupDetail from "../../../../ws/userGroup/TaskUserGroupDetail";
import UserGroupFormAdminScreenActions from "./UserGroupFormAdminScreenActions";

interface InitialState {
    userGroupFormScreenLoading: boolean,
    userGroup?: UserGroup,
}

const INITIAL_STATE: InitialState = {
    userGroupFormScreenLoading: false,
    userGroup: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUserGroupDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        userGroupFormScreenLoading: true
    }))
    .onEvent(TaskUserGroupDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        userGroupFormScreenLoading: false
    }))
    .onEvent(TaskUserGroupDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, userGroup: payload.data};
        })

    .onEvent(UserGroupFormAdminScreenActions.clearReducer, ((oldState, payload) => INITIAL_STATE))

    .build();