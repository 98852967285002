import * as React from "react";
import {ReactNode} from "react";
import AlertNotificationUserDataContainer from "./AlertNotificationUserDataContainer";
import I18nUtils from "../../../../I18n/I18nUtils";
import {ROUTE_ALERT_NOTIFICATION_USER_LIST} from "../../../../routing/Routes";
import {MenuIcon} from "../../../../Config";
import LoggedScreen from "../../../../base/screens/LoggedScreen";
import ScreenContent from "../../../../components/screen/ScreenContent";
import Col from "../../../../components/Col";
import Row from "../../../../components/Row";

export default class AlertNotificationUserScreen extends LoggedScreen {

	constructor(props: {}, context: any) {
		super(props, context);
		this.addBreadcrumbScreen(
			I18nUtils.tr("Notificaciones de usuario"),
			ROUTE_ALERT_NOTIFICATION_USER_LIST,
			MenuIcon.ALERT);
	}

	public renderScreen(): ReactNode {
		return (
			<ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<AlertNotificationUserDataContainer/>
					</Col>
				</Row>
			</ScreenContent>
		);
	}
}