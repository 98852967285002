import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import Zone from "../../model/Zone";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";

type ZoneCriteriaProps = TaskCriteriaRequest<{}>

export default class TaskZoneList extends BaseGenericRequest<ZoneCriteriaProps, TaskResponse<Zone[]>> {

    private readonly data: ZoneCriteriaProps;

    public constructor(criteria: ZoneCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_ZONE_LIST);
        this.data = criteria;
    }

    protected getRequest(): ZoneCriteriaProps {
        return this.data;
    }

}