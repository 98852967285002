import {ReducerBuilder} from "co-redux-builders";
import LauncherAppsActions from "./AppLauncherListActions";
import TaskLinkList from "../../../ws/links/TaskLinkList";
import {Link} from "../../../model/Link";

interface AppLauncherListReducer {
    links: Link [],
    loading: boolean,
    errors: string,
}

const INITIAL_STATE: AppLauncherListReducer = {
    links: [],
    loading: false,
    errors: '',
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskLinkList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
    .onEvent(TaskLinkList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
    .onEvent(TaskLinkList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, errors: payload}))
    .onEvent(TaskLinkList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState,payload) => ({...oldState, links: payload.data, errors: ''}))

    // ComponentWillUnmount
    .onEvent(LauncherAppsActions.dispatchClearReducer, () => INITIAL_STATE)

    .build();