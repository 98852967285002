import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {FormScope} from "../../model/FormScope";

export default class TaskFormScopeTypeList extends BaseGenericRequest<{}, TaskResponse<FormScope []>> {

    public constructor() {
        super(METHOD.METHOD_GET, Urls.URL_FORM_SCOPES);
    }
}