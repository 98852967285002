import * as React from "react";
import Th from "../../../components/table/Th";
import I18nUtils from "../../../I18n/I18nUtils";
import {OnSortHandler, SortState} from "../../../utils/Sort";
import {generateRoute, goToRoute} from "../../../utils/Router";
import {ROUTE_ADMIN_FORM_RESULT_DETAIL, ROUTE_FORM_RESULT, ROUTE_FORM_RESULT_DETAIL} from "../../../routing/Routes";
import FormResult from "../../../model/FormResult";
import {TR_EXPLOTACION, TR_FECHA_ENVIO, TR_FECHA_VALOR} from "../../../I18n/constants";
import {RouteComponentProps, withRouter} from "react-router";

interface FormResultListTableProps {
    data: FormResult[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

type Props = FormResultListTableProps & RouteComponentProps<{formId: string }>

class FormResultListTable extends React.Component<Props> {

    private gotoItemDetail = (formId: string, itemId: string): void => {
        const rawRoute = this.props.match.path === ROUTE_FORM_RESULT ?
            ROUTE_FORM_RESULT_DETAIL :
            ROUTE_ADMIN_FORM_RESULT_DETAIL;
        goToRoute(generateRoute(rawRoute, {formId, id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((formResult: FormResult) => {
                    return (
                        <tr key={formResult.id}
                            onClick={() => this.gotoItemDetail(formResult.form_id, formResult.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(formResult.id) : null}
                            <td>{formResult.datetime ? new Date(formResult.datetime).toLocaleString() : ''}</td>
                            <td>{formResult.datetime_value ? new Date(formResult.datetime_value).toLocaleString() : ''}</td>
                            <td>{ formResult.exploitation ? formResult.exploitation.name : ''}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_FECHA_ENVIO)}
                    columnName={'datetime'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA_VALOR)}
                    columnName={'datetime_value'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_EXPLOTACION)}
                    columnName={'exploitation'}
                    {...commonProps}
                />
            </tr>
        )
    }
}

export default withRouter(FormResultListTable);