import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import DocumentModel, {DocumentModelData} from "../../model/Document";

export default class TaskDocumentEdit extends BaseGenericRequest<DocumentModelData, TaskResponse<DocumentModel>> {

    private readonly data: DocumentModelData;

    public constructor(documentId: string, documentData: DocumentModelData) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_DOCUMENT_EDIT, {uuid: documentId}));
        this.data = documentData;
    }

    protected getRequest(): DocumentModelData {
        return this.data;
    }
}