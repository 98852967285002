import * as React from 'react';
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ANADE_TUS_FORMULARIOS_FAVORITOS,
    TR_FORMULARIOS,
    TR_MIS_FORMULARIOS, TR_NO_EXISTEN_FORMULARIOS
} from "../../../I18n/constants";
import {connect} from "react-redux";
import CardList, {EmptyListOptions} from "../../../components/card/CardList";
import TaskFormList from "../../../ws/form/TaskFormList";
import FormListUserTable from "./FormListUserTable";
import FormListCardAdminReducer from "../../admin/forms/list/FormListCardAdminReducer";
import FormListCardAdminActions from "../../admin/forms/list/FormListCardAdminActions";
import {goToRoute} from "../../../utils/Router";
import {ROUTE_FORM} from "../../../routing/Routes";

const mapStateToProps = FormListCardAdminReducer.autoMapToProps();
const mapActionsToProps = FormListCardAdminActions.autoMapToProps();

interface FormListCardProps {
    showFavorites?: boolean,
}

type Props = typeof mapStateToProps & typeof mapActionsToProps & FormListCardProps

class FormListCard extends React.Component<Props> {
    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onAddFavorites = (): void => {
        goToRoute(ROUTE_FORM);
    };

    public render() {
        const {forms, error, loading, pager, showFavorites = false} = this.props;

        const emptyListOptions: EmptyListOptions = showFavorites ?
            {
                message: I18nUtils.tr(TR_ANADE_TUS_FORMULARIOS_FAVORITOS),
                addHandler: this.onAddFavorites,
            } :
            {
                message: I18nUtils.tr(TR_NO_EXISTEN_FORMULARIOS)
            };

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(showFavorites ? TR_FORMULARIOS : TR_MIS_FORMULARIOS)}
                      sort={{column: 'name'}}
                      TaskList={TaskFormList}
                      emptyListOptions={emptyListOptions}
                      data={forms}
                      error={error}
                      ItemsTable={FormListUserTable}
                      addToFavorites={!showFavorites}
                      removeFromFavorites={showFavorites}
                      customCriteria={showFavorites ? {
                          fav: true
                      } : {}}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(FormListCard as unknown as React.ComponentType<FormListCardProps>);
