import * as React from "react";
import {ReactNode} from "react";
import LinkListAdminDataContainer from "./LinkListAdminDataContainer";
import ScreenCard from "../../../../components/screen/ScreenCard";
import I18nUtils from "../../../../I18n/I18nUtils";
import {ROUTE_LINKS} from "../../../../routing/Routes";
import {TR_LINKS} from "../../../../I18n/constants";
import {MenuIcon} from "../../../../Config";

export default class LinkListAdminScreen extends React.Component {
    public render(): ReactNode {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_LINKS),
                    url: ROUTE_LINKS,
                    icon: MenuIcon.LINK
                }
            ]}>
                <LinkListAdminDataContainer/>
            </ScreenCard>
        );
    }
}
 