import * as React from "react";
import Dashboard from "../../../../model/Dashboard";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {ROUTE_ADMIN_DASHBOARD_DETAIL, ROUTE_ADMIN_DASHBOARD_LAYOUT,} from "../../../../routing/Routes";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import ActiveLabel from "../../../../components/label/ActiveLabel";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_DESHABILITADO,
    TR_HABILITADO,
    TR_NOMBRE,
    TR_USUARIO_CREADOR,
    TR_VER_DISENO,
} from "../../../../I18n/constants";
import Th from "../../../../components/table/Th";
import Button from "../../../../components/Button";

interface DashboardListTableProps {
    data: Dashboard[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export class DashboardListTable extends React.Component<DashboardListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_DASHBOARD_DETAIL, {id: itemId}));
    };

    private goToDashBoardLayout = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_DASHBOARD_LAYOUT, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((dashboard: Dashboard) => {
                    return (
                        <tr key={dashboard.id}
                            onClick={() => this.goToItemDetail(dashboard.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(dashboard.id) : null}
                            <td>
                                <ActiveLabel
                                    text={dashboard.enabled ? I18nUtils.tr(TR_HABILITADO) : I18nUtils.tr(TR_DESHABILITADO)}
                                    active={dashboard.enabled}/>
                            </td>
                            <td>
                                {dashboard.name}
                            </td>
                            <td>{`${dashboard.user_creator.name} ${dashboard.user_creator.last_name}`}</td>
                            <td>
                                <Button
                                    text={I18nUtils.tr(TR_VER_DISENO)}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.goToDashBoardLayout(dashboard.id);
                                    }}
                                    type={'button'}
                                    block={true}
                                    className={'btn-sm btn-primary'}
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_HABILITADO)}
                    columnName={'enabled'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_USUARIO_CREADOR)}
                    columnName={'user_creator'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
