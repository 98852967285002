import {UserSimply} from "./User";
import FormModel from "./Form";
import {ExploitationSimply} from "./Exploitation";

export default interface FormResult {
    id: string,
    form_id: string,
    datetime: string,
    datetime_value: string,
    user: UserSimply,
    values?: FormResultData,
    form: FormModel,
    exploitation: ExploitationSimply,
    form_fields?: FormResultFormFields[],
}

export interface FormResultFormFields {
    default_value: FormResultValue
    form_field_type: formFieldTypeValue,
    key: string,
    label: string,
    order: number,
    required: boolean,
    validator: UserSimply,
}

export enum formFieldTypeValue {
    TEXT = '1',
    TEXT_AREA = '2',
    NUMBER = '3',
    EMAIL = '4',
    URL = '5',
    DATE = '6',
    TIME = '7',
    DATETIME = '8',
    IMAGE = '9',
    VIDEO = '10',
    AUDIO = '11',
    SELECT = '12',
    CHECKBOX = '13',
    CHECKBOX_GROUP = '14',
    RADIO_GROUP = '15',
    SWITCH = '16',
    FILE = '17'
}

export type FormResultData = FormResultDynamicVariables & FormFieldDic;

export interface FormResultDynamicVariables {
    datetime_value?: string,
    exploitation_id?: string,
    description?: string
}

export interface FormFieldDic {
    [formFieldKey: string]:	FormResultValue
}

export interface FormResultCheckFrequencyResponse {
    has_been_written: boolean
}

export type FormResultValue = string | number | boolean