import React, { useEffect, useState } from 'react';
import IFrame from '../../../components/IFrame';
import { DashboardDetailFilterData } from "../../../model/Dashboard"
import TaskDashboardDetail from "../../../ws/dashboard/TaskDashboardDetail";
import Col from '../../../components/Col';
import Row from '../../../components/Row';

const IFramesScreen = (props) => {
    const [dashboards, setDashboards] = useState({
        name: "",
        items: [{id: "", name: "", url_iframe: ""}]
    })
    const itemsPerPage = 18;
    let id = props.id || props.match.params.id;
    let request = props.request || props.match.params.request;
    let currPage = props.page || props.match.params.page;
    request = decodeURIComponent(request);
    request = JSON.parse(request) as DashboardDetailFilterData;

    let task = new TaskDashboardDetail(id, request);

    useEffect(() => {
        async function getDashboards() {
            //
            try {
                let response = await task.executeAsPromise()
                //

                let data = {
                    name: response.data.name,
                    items: response.data.items.map((item) => ({id: item.id, name: item.name, url_iframe: item.url_iframe, order: item.order})).sort((a:any,b: any) => (a.order - b.order))
                }

                let tmpItems;
                //
                tmpItems = data.items.slice((currPage - 1) * itemsPerPage, itemsPerPage * currPage);
                //
                data.items = tmpItems;
                //
                //
                setDashboards(data)
                //
            } catch (error) {
                console.log(error);
            }
            //
        }
        getDashboards();
    }, [])

    return (
        <>
            <Row>
                <Col md={12}>
                    <h1>{dashboards.name}</h1>
                </Col>
            </Row>
            {dashboards.items.map(
                (item) => (
                    <Row>
                        <Col md={12} style={{display: "inline-block"}}>
                            <IFrame url={item.url_iframe} title={item.name} width={"100%"} height={"100%"} />
                        </Col>
                    </Row>
                )
            )}
        </>
    )
}

export default IFramesScreen;