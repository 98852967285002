import * as React from "react";
import {connect} from "react-redux";
import UnloggedScreen from "../../../base/screens/UnloggedScreen";
import AuthReducer from "./LoginReducer";
import AuthActions from "./LoginActions";
import imgLogo from "../../../res/img/logo.png";
import Icon from "../../../components/Icon";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ABRIR_SESION,
    TR_CONTRASENA,
    TR_DATOS_DE_AUTENTICACION_INCORRECTOS,
    TR_EL_MAIL_NO_ES_VALIDO,
    TR_EL_USUARIO_NO_EXISTE,
    TR_EMAIL_O_NOMBRE_DE_USUARIO,
    TR_INTRODUCE_TU_CONTRASENA,
    TR_LOGIN,
    TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE,
    TR_OLVIDASTE_TU_CONTRASENA,
    TR_RECUERDAME,
    TR_TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA,
    TR_USUARIO_BLOQUEADO_NO_SE_PUEDE_HACER_LOGIN_DURANTE_LAS_SIGUIENTES_2_HORAS,
    TR_USUARIO_NO_ENCONTRADO
} from "../../../I18n/constants";
import Card from "../../../components/card/Card";
import CardBody from "../../../components/card/CardBody";
import Row from "../../../components/Row";
import Col from "../../../components/Col";
import Button from "../../../components/Button";
import AuthManager, {changeUserPassword} from "../../../utils/AuthManager";
import TaskLogin from "../../../ws/auth/TaskLogin";
import {ReduxStorageUtils} from "co-redux-builders";
import {push} from "react-router-redux";
import {ROUTE_HOME} from "../../../routing/Routes";
import AlertComponent from "../../../base/alerts/AlertComponent";
import RecoverPasswordModal from './RecoverPasswordModal';
import {executeItemTask} from "../../../utils/FormUtils";
import TaskRecoverPwd from "../../../ws/auth/TaskRecoverPwd";
import {goToRoute} from "../../../utils/Router";
import EditPasswordModal, {EditPasswordModalData} from '../../admin/users/formdetail/EditPasswordModal';

const mapStateToProps = AuthReducer.autoMapToProps();
const mapActionsToProps = AuthActions.autoMapToProps();

interface State {
    showRecoverPwd: boolean,
    rememberMe: boolean,
    showErrorMsg: boolean,
    showEditPasswordModal: boolean,
}

type Props = typeof mapStateToProps & typeof mapActionsToProps

class LoginScreen extends UnloggedScreen<Props, State> {

    public state: State = {
        showRecoverPwd: false,
        rememberMe: false,
        showErrorMsg: false,
        showEditPasswordModal: false,
    };

    public componentDidMount() {
        document.body.classList.add('login-page');
    }

    public componentWillUnmount() {
        document.body.classList.remove('login-page');
    }

    private onShowRecoverPwd = (): void => {
        this.setState({showRecoverPwd: true})
    };
    private onHideRecoverPwdModal = (): void => {
        this.setState({showRecoverPwd: false})
    };
    private onRememberMe = (): void => {
        this.setState({rememberMe: !this.state.rememberMe})
    };
    private doLogin = () => {
        const {email, password, allowLogin, setToken} = this.props;

        if (allowLogin) {
            new TaskLogin(email, password).onSuccess((response) => {
                if (response.success) {
                    setToken(response.data);

                    if (response.code === 210) {
                        this.setState({
                            showEditPasswordModal: true,
                        })
                    } else {
                        AuthManager.setAuthToken(response.data, this.state.rememberMe);
                        ReduxStorageUtils.dispatch(push(ROUTE_HOME))
                    }
                } else {
                    switch (response.code) {
                        case 400:
                            AlertComponent.error(I18nUtils.tr(TR_EL_USUARIO_NO_EXISTE));
                            break;
                        case 401:
                            AlertComponent.error(I18nUtils.tr(TR_DATOS_DE_AUTENTICACION_INCORRECTOS));
                            break;
                        case 404:
                            AlertComponent.error(I18nUtils.tr(TR_DATOS_DE_AUTENTICACION_INCORRECTOS));
                            break;
                        case 423:
                            AlertComponent.error(I18nUtils.tr(TR_USUARIO_BLOQUEADO_NO_SE_PUEDE_HACER_LOGIN_DURANTE_LAS_SIGUIENTES_2_HORAS));
                            break;
                        default:
                            AlertComponent.error(I18nUtils.tr(TR_DATOS_DE_AUTENTICACION_INCORRECTOS));
                    }

                }
            }).execute();
        } else {
            this.setState({
                showErrorMsg: true,
            })
        }
    };

    private handleKeyboard = (event) => {
        if (event.key === 'Enter') {
            this.doLogin();
        }
    };

    private onRecoverPassword = (email: string): void => {
        executeItemTask(
            new TaskRecoverPwd(email),
            () => goToRoute(ROUTE_HOME),
            I18nUtils.tr(TR_TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA),
            I18nUtils.tr(TR_USUARIO_NO_ENCONTRADO)
        )
    };

    private onHideEditPasswordModal = (): void => {
        this.setState({
            showEditPasswordModal: false,
        })
    };

    private onChangePassword = (passwordData: EditPasswordModalData): void => {
        changeUserPassword(
            passwordData.password,
            undefined,
            () => {
                AuthManager.setAuthToken(this.props.token, this.state.rememberMe);
                ReduxStorageUtils.dispatch(push(ROUTE_HOME));
            },
            this.props.token);
    };

    public renderScreen(): React.ReactNode {
        const {isValidPassword, isValidUser, allowLogin} = this.props;
        const {showErrorMsg, showEditPasswordModal} = this.state;

        return (
            <div className="login-box">
                <div className="logo">
                    <a>
                        <img src={imgLogo} alt="Pavasal" className="img-responsive center-block m-b-15"/>
                    </a>
                    <small>{I18nUtils.tr(TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE)}</small>
                </div>
                <Card loading={this.props.loading}>
                    <CardBody>
                        <div className="msg">{I18nUtils.tr(TR_ABRIR_SESION)}</div>
                        <div className="input-group">
                            <span className="input-group-addon"><Icon icon={'person'}/></span>
                            <div className="form-line">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={I18nUtils.tr(TR_EMAIL_O_NOMBRE_DE_USUARIO)}
                                    required={true}
                                    autoFocus={true}
                                    value={this.props.email}
                                    onChange={(e) => this.props.setUsername(e.target.value)}
                                    onKeyPress={this.handleKeyboard}
                                />
                            </div>
                        </div>
                        {showErrorMsg && <div>
                            <p className={'error-message'}>{isValidUser ? '' :
                                I18nUtils.tr(TR_EL_MAIL_NO_ES_VALIDO)}</p>
                        </div>}

                        <div className="input-group">
                            <span className="input-group-addon"><Icon icon={'lock'}/></span>
                            <div className="form-line">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder={I18nUtils.tr(TR_CONTRASENA)}
                                    required={true}
                                    value={this.props.password}
                                    onChange={(e) => this.props.setPassword(e.target.value)}
                                    onKeyPress={this.handleKeyboard}
                                />
                            </div>
                        </div>
                        {showErrorMsg && <div>
                            <p className={'error-message'}>{isValidPassword ? '' :
                                I18nUtils.tr(TR_INTRODUCE_TU_CONTRASENA)}</p>
                        </div>}

                        <Row>
                            <Col xs={8} className={'p-t-5 p-l-20'}>
                                <input type="checkbox"
                                       name="rememberme"
                                       id="rememberme"
                                       className="filled-in chk-col-blue-grey"
                                       checked={this.state.rememberMe}
                                       onChange={(e) => this.onRememberMe()}
                                />
                                <label htmlFor="rememberme">{I18nUtils.tr(TR_RECUERDAME)}</label>
                            </Col>
                            <Col xs={4}>
                                <Button
                                    className={'bg-blue-grey'}
                                    text={I18nUtils.tr(TR_LOGIN)}
                                    onClick={this.doLogin}
                                    block={true}
                                    disabled={!allowLogin && showErrorMsg}
                                />
                            </Col>
                        </Row>
                        <Row className={'m-b-5 m-t-15'}>
                            <Col xs={12} className={'align-right'}>
                                <a onClick={(e) => this.onShowRecoverPwd()}>{I18nUtils.tr(TR_OLVIDASTE_TU_CONTRASENA)}</a>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <RecoverPasswordModal show={this.state.showRecoverPwd}
                                      onClose={this.onHideRecoverPwdModal}
                                      onSubmit={this.onRecoverPassword}
                />
                <EditPasswordModal show={showEditPasswordModal}
                                   onClose={this.onHideEditPasswordModal}
                                   onSubmit={this.onChangePassword}
                                   forceChange={true}
                />
            </div>
        );
    }

}

export default connect(mapStateToProps, mapActionsToProps)(LoginScreen);