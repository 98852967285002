import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Alert from "../../model/Alert";

export default class TaskAlertDetail extends BaseGenericRequest<{}, TaskResponse<Alert>> {

    public constructor(alertId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_ALERT_DETAIL, {uuid: alertId}));
    }
}
