import * as React from 'react';
import {compose} from "redux";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_CLAVE_ELK,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO, TR_NUEVO_TIPO_DE_EXPLOTACION, TR_OBLIGATORIO, TR_TIPO_DE_EXPLOTACION,
} from "../../../../I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import {connect} from "react-redux";
import BusinessTypeAdminScreenReducer from "./BusinessTypeFormAdminScreenReducer";
import {goToRoute} from "../../../../utils/Router";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_ADMIN_BUSINESS_TYPES} from "../../../../routing/Routes";

const FORM_NAME = 'BusinessTypeCardFormAdmin';

export interface BusinessTypeCardFormAdminData {
    name: string,
    elk_value: string,
}

enum BusinessTypeCardFormAdminFields {
    NAME = "name",
    ELK_VALUE = "elk_value",
}

interface BusinessTypeCardFormAdminProps {
    businessTypeId?: string,
    readonly?: boolean,
    onSubmit: (data: BusinessTypeCardFormAdminData) => void,
    initialValues?: Partial<BusinessTypeCardFormAdminData>,
}

const mapStateToProps = BusinessTypeAdminScreenReducer.autoMapToProps();

type Props = BusinessTypeCardFormAdminProps & typeof mapStateToProps & InjectedFormProps<BusinessTypeCardFormAdminData>

class BusinessTypeCardFormAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, businessTypeFormScreenLoading, pristine
        } = this.props;

        return (
            <Card loading={businessTypeFormScreenLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_TIPO_DE_EXPLOTACION : TR_NUEVO_TIPO_DE_EXPLOTACION)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={BusinessTypeCardFormAdminFields.NAME}
                                // @ts-ignore
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_CLAVE_ELK)}
                                name={BusinessTypeCardFormAdminFields.ELK_VALUE}
                                // @ts-ignore
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                                
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_BUSINESS_TYPES)}/>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: BusinessTypeCardFormAdminData) {
    const errors: FormErrors<BusinessTypeCardFormAdminData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.elk_value || values.elk_value.length === 0) {
        errors.elk_value = I18nUtils.tr(TR_OBLIGATORIO);
    }
    return errors;
}

export default compose(
    reduxForm<BusinessTypeCardFormAdminData, BusinessTypeCardFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(BusinessTypeCardFormAdmin);
