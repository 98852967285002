import {ReducerBuilder} from "co-redux-builders";
import Dashboard from "../../../../model/Dashboard";
import {PagerBackendProps} from "../../../../components/Pager";
import TaskDashboardList from "../../../../ws/dashboard/TaskDashboardList";
import DashboardListcardAdminActions from "./DashboardListcardAdminActions";
import TaskDashboardDelete from "../../../../ws/dashboard/TaskDashboardDelete";

interface InitialState {
    loading: boolean,
    dashboards: Dashboard[],
    error: string,
    pager: PagerBackendProps,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    dashboards: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1
    },
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskDashboardDelete.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskDashboardDelete.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })

    .onEvent(TaskDashboardList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskDashboardList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskDashboardList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskDashboardList.REDUX_ACTION_OBJECT_ON_SUCCESS, ((oldState, payload) => {
        return {
            ...oldState,
            dashboards: payload.data,
            pager: payload.pager || oldState.pager
        }
    }))

    .onEvent(DashboardListcardAdminActions.clearReducer, ((oldState, payload) => INITIAL_STATE))

    .build()