import {UserSimply} from "./User";
import LogType from "./LogType";
import I18nUtils from "../I18n/I18nUtils";
import {
    TR_DASHBOARD,
    TR_DOCUMENTO,
    TR_EXPLOTACION,
    TR_FORMULARIO, TR_GRUPO_DE_USUARIOS,
    TR_LOGIN, TR_RESULTADO_DE_FORMULARIO,
    TR_TIPO_DE_EXPLOTACION, TR_USUARIO,
    TR_ZONA
} from "../I18n/constants";
import {
    ROUTE_ADMIN_BUSINESS_TYPE_DETAIL,
    ROUTE_ADMIN_DASHBOARD_DETAIL,
    ROUTE_ADMIN_DOCUMENT_DETAIL,
    ROUTE_ADMIN_EXPLOITATION_DETAIL,
    ROUTE_ADMIN_FORM_DETAIL, ROUTE_ADMIN_USER_DETAIL,
    ROUTE_ADMIN_USER_GROUP_DETAIL,
    ROUTE_ADMIN_ZONE_DETAIL
} from "../routing/Routes";
import {generateRoute} from "../utils/Router";

export default interface Log {
    id: string,
    user: UserSimply,
    log_type: LogType,
    entity_id: string,
    date: string,
    change_logs: Array<ChangeLog>
}

export interface ChangeLog {
    column_name: string,
    old_value: string,
    new_value: string,
    date: string
}

export class LogEntity {
    public static readonly LOGIN = new LogEntity('0', I18nUtils.tr(TR_LOGIN), '');
    public static readonly DASHBOARD = new LogEntity('1', I18nUtils.tr(TR_DASHBOARD), ROUTE_ADMIN_DASHBOARD_DETAIL);
    public static readonly DOCUMENT = new LogEntity('2', I18nUtils.tr(TR_DOCUMENTO), ROUTE_ADMIN_DOCUMENT_DETAIL);
    public static readonly BUSINESS_TYPE = new LogEntity('3', I18nUtils.tr(TR_TIPO_DE_EXPLOTACION), ROUTE_ADMIN_BUSINESS_TYPE_DETAIL);
    public static readonly ZONE = new LogEntity('4', I18nUtils.tr(TR_ZONA), ROUTE_ADMIN_ZONE_DETAIL);
    public static readonly EXPLOITATION = new LogEntity('5', I18nUtils.tr(TR_EXPLOTACION), ROUTE_ADMIN_EXPLOITATION_DETAIL);
    public static readonly FORM = new LogEntity('6', I18nUtils.tr(TR_FORMULARIO), ROUTE_ADMIN_FORM_DETAIL);
    public static readonly FORM_RESULT = new LogEntity('7', I18nUtils.tr(TR_RESULTADO_DE_FORMULARIO), '');
    public static readonly GROUP = new LogEntity('8', I18nUtils.tr(TR_GRUPO_DE_USUARIOS), ROUTE_ADMIN_USER_GROUP_DETAIL);
    public static readonly USER = new LogEntity('9', I18nUtils.tr(TR_USUARIO), ROUTE_ADMIN_USER_DETAIL);

    // private to disallow creating other instances of this type
    private constructor(
        public readonly value: string,
        public readonly name: string,
        private readonly route: string
    ) {
    }

    public toString() {
        return this.value;
    }

    public getItemRoute(itemId: string): string | undefined {
        return this.route ? generateRoute(this.route, {id: itemId}) : undefined;
    }
}

export function getEntityDetailsFromLog(log: Log): LogEntity | undefined {
    const currentLogCategory: string = log.log_type.id.split('-')[0];

    switch (currentLogCategory) {
        case LogEntity.BUSINESS_TYPE.value:
            return LogEntity.BUSINESS_TYPE;
        case LogEntity.DASHBOARD.value:
            return LogEntity.DASHBOARD;
        case LogEntity.DOCUMENT.value:
            return LogEntity.DOCUMENT;
        case LogEntity.EXPLOITATION.value:
            return LogEntity.EXPLOITATION;
        case LogEntity.FORM.value:
            return LogEntity.FORM;
        case LogEntity.GROUP.value:
            return LogEntity.GROUP;
        case LogEntity.USER.value:
            return LogEntity.USER;
        case LogEntity.ZONE.value:
            return LogEntity.ZONE;
        case LogEntity.LOGIN.value:
            return LogEntity.LOGIN;
        case LogEntity.FORM_RESULT.value:
            return LogEntity.FORM_RESULT;
    }
    return;
}