import * as React from 'react';
import LoggedScreen from "../../../../base/screens/LoggedScreen";
import {RouteComponentProps} from "react-router";
import I18nUtils from "../../../../I18n/I18nUtils";
import {generateRoute} from "../../../../utils/Router";
import ScreenContent from "../../../../components/screen/ScreenContent";
import Row from "../../../../components/Row";
import Col from "../../../../components/Col";
import {MenuIcon} from "../../../../Config";
import {TR_EXPLOTACION, TR_EXPLOTACIONES, TR_NUEVA_EXPLOTACION} from "../../../../I18n/constants";
import {
    ROUTE_ADMIN_EXPLOITATION_ADD,
    ROUTE_ADMIN_EXPLOITATION_DETAIL,
    ROUTE_ADMIN_EXPLOITATIONS
} from "../../../../routing/Routes";
import ExploitationFormAdminDataContainer from "./ExploitationFormAdminDataContainer";


type ExploitationFormAdminScreenProps = RouteComponentProps<{ id: string }>

export default class ExploitationFormAdminScreen extends LoggedScreen<ExploitationFormAdminScreenProps> {

    constructor(props: ExploitationFormAdminScreenProps, context: any) {
        super(props, context);
        this.addBreadcrumbScreen(I18nUtils.tr(TR_EXPLOTACIONES), ROUTE_ADMIN_EXPLOITATIONS, MenuIcon.EXPLOITATION);
        if (this.props.match.params.id) {
            this.addBreadcrumbScreen(I18nUtils.tr(TR_EXPLOTACION), generateRoute(
                ROUTE_ADMIN_EXPLOITATION_DETAIL,
                {id: this.props.match.params.id}),
                MenuIcon.EXPLOITATION);
        } else {
            this.addBreadcrumbScreen(I18nUtils.tr(TR_NUEVA_EXPLOTACION), ROUTE_ADMIN_EXPLOITATION_ADD, MenuIcon.EXPLOITATION)
        }
    }

    public renderScreen(): React.ReactNode {
        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <ExploitationFormAdminDataContainer id={this.props.match.params.id}/>
                    </Col>
                </Row>
            </ScreenContent>
        );
    }

}