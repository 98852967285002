import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Exploitation,{ExploitationRequest} from "../../model/Exploitation";

export default class TaskExploitationEdit extends BaseGenericRequest<ExploitationRequest, TaskResponse<Exploitation>> {

    private readonly data: ExploitationRequest;

    public constructor(exploitationId: string, exploitationData: ExploitationRequest) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_EXPLOITATION_EDIT, {uuid: exploitationId}));
        this.data = exploitationData;
    }

    protected getRequest(): ExploitationRequest {
        return this.data;
    }
}