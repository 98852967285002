import * as React from 'react';
import FormModel from "../../../../model/Form";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import ActiveLabel from "../../../../components/label/ActiveLabel";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_CLAVE,
    TR_DESHABILITADO,
    TR_HABILITADO,
    TR_NOMBRE,
    TR_USUARIO_CREADOR, TR_VER_DATOS
} from "../../../../I18n/constants";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {ROUTE_ADMIN_FORM_DETAIL, ROUTE_ADMIN_FORM_RESULT} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import Button from "../../../../components/Button";

interface FormListTableProps {
    data: FormModel[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class FormListAdminTable extends React.Component<FormListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_FORM_DETAIL, {id: itemId}));
    };

    private goToFormResults = (formId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_FORM_RESULT, {formId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((form: FormModel) => {
                    return (
                        <tr key={form.id}
                            onClick={() => this.goToItemDetail(form.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(form.id) : null}
                            <td>
                                <ActiveLabel
                                    text={form.enabled ? I18nUtils.tr(TR_HABILITADO) : I18nUtils.tr(TR_DESHABILITADO)}
                                    active={form.enabled}/>
                            </td>
                            <td>
                                {form.name}
                            </td>
                            <td>{form.key}</td>
                            <td>
                                {form.user_creator && `${form.user_creator.name} ${form.user_creator.last_name}`}
                            </td>
                            <td>
                                <Button
                                    text={I18nUtils.tr(TR_VER_DATOS)}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.goToFormResults(form.id);
                                    }}
                                    type={'button'}
                                    block={true}
                                    className={'btn-sm btn-primary'}
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_HABILITADO)}
                    columnName={'enabled'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_CLAVE)}
                    columnName={'key'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_USUARIO_CREADOR)}
                    columnName={'user_creator'}
                    {...commonProps}
                />
            </tr>
        )
    }
}