import Zone from "../../../../model/Zone";
import * as React from "react";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {ROUTE_ADMIN_ZONE_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_NOMBRE} from "../../../../I18n/constants";
import {OnSortHandler, SortState} from "../../../../utils/Sort";

interface ZoneListTableProps {
    data: Zone[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class ZoneListTable extends React.Component<ZoneListTableProps> {

    private gotoItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_ZONE_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((zone: Zone) => {
                    return (
                        <tr key={zone.id}
                            onClick={() => this.gotoItemDetail(zone.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(zone.id) : null}
                            <td>
                                {zone.name}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
            </tr>
        )
    }
}