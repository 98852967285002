import * as React from 'react';
import {Component, ReactNode} from 'react';
import Col from "../Col";
import Row from "../Row";
import Button from "../Button";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_CANCELAR} from "../../I18n/constants";

export interface CardDeleteFooterProps {
    okTitle: string,
    cancelTitle?: string,
    okHandler: () => void,
    cancelHandler: () => void,
}

export default class CardOkCancelFooter extends Component<CardDeleteFooterProps> {

    public render(): ReactNode {
        const {okTitle, okHandler, cancelTitle = I18nUtils.tr(TR_CANCELAR), cancelHandler} = this.props;
        return (
            <Row className='p-t-20'>
                <Col sm={4} md={6} lg={8}/>
                <Col sm={4} md={3} lg={2}>
                    <Button
                        text={cancelTitle}
                        onClick={cancelHandler}
                        type={'button'}
                        block={true}
                        className={'btn-lg btn-default'}
                    />
                </Col>
                <Col sm={4} md={3} lg={2}>
                    <Button
                        text={okTitle}
                        onClick={okHandler}
                        type={'button'}
                        block={true}
                        className={'btn-primary btn-lg'}
                    />
                </Col>
            </Row>
        );
    }
}

