export enum FormFrequencies {
    NONE = '0',
    ONCE = '1',
    DAILY = '2',
    WEEKLY = '3',
    MONTHLY = '4',
    QUARTERLY = '5',
    YEARLY = '6'
}

export interface FormFrequency {
    id: string,
    name: string
}

