import React, {Component, ReactNode} from "react";
import {Link} from "../../../../model/Link";
import LinkForm, {LinkFormData} from "../../../../components/linkform/LinkForm";

interface LinkDetailAdminViewProps {
    onEdit: (link: LinkFormData) => void,
    link : Link,
    loading: boolean,
    errors: string
}

export default class LinkDetailAdminView extends Component<LinkDetailAdminViewProps> {
    public render(): ReactNode {
        const {onEdit, link, loading, errors} = this.props;
        return (
            <LinkForm
                onSubmit={onEdit}
                loading={loading}
                errors={errors}
                initialValues={{
                    name: link.name,
                    url: link.url,
                    image : [link.image]
                }}
                linkId={link.id}
            />
        );
    }
}
 