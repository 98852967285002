import * as React from "react";
import Th from "../../../components/table/Th";
import I18nUtils from "../../../I18n/I18nUtils";
import {OnSortHandler, SortState} from "../../../utils/Sort";
import DocumentModel from "../../../model/Document";
import {ExploitationAutocompleteBuilder} from "../../../model/Exploitation";
import {TR_DESCRIPCION, TR_EXPLOTACION, TR_FECHA, TR_NOMBRE, TR_URL} from "../../../I18n/constants";
/* import {truncateMiddle} from "../../../utils/StringUtils"; */
import {generateRoute, goToRoute} from "../../../utils/Router";
import {ROUTE_ADMIN_DOCUMENT_DETAIL} from "../../../routing/Routes";
import { DowloadDocument } from "../../../ws/download/DowloadDocument";
import AlertComponent from "../../../base/alerts/AlertComponent";
import {
    TR_FICHERO_CARGADO_CORRECTAMENTE
} from "../../../I18n/constants"; 

interface DocumentationListTableProps {
    data: DocumentModel[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class DocumentationListTable extends React.Component<DocumentationListTableProps> {

    private gotoItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_DOCUMENT_DETAIL, {id: itemId}))
    };

    private getDowload =  (event, itemId) =>{
        event.stopPropagation();
        /* 
        AlertComponent.success(I18nUtils.tr(TR_FICHERO_CARGADO_CORRECTAMENTE))
                } else {
                    AlertComponent.error(response.message);
        */
        DowloadDocument(itemId)
        .then( res => {
            AlertComponent.success(I18nUtils.tr(TR_FICHERO_CARGADO_CORRECTAMENTE));
            return res.blob();
        })
        .then( blob => {
          var file = window.URL.createObjectURL(blob);
          window.open(file,'_blank');
        })
        .catch(error => console.log(error)); 

    }

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((document: DocumentModel) => {
                    return (
                        <tr key={document.id}
                            onClick={() => this.gotoItemDetail(document.id)}>
                            {renderSelectTd ? renderSelectTd(document.id) : null}
                            <td>{document.name}</td>
                            {/* Puesto que se permite buscar por nombre y descripción, en esta tabla se muestra descripción*/}
                            <td>{document.description}</td>
                            <td>{document.creation_date ? new Date(document.creation_date).toLocaleString() : ''}</td>
                            <td>
                                {document.exploitation ? ExploitationAutocompleteBuilder.buildOption(document.exploitation).name : ''}
                            </td>
                            {/* <td className={"cursor-pointer"}
                                onClick={(event) => this.getDowload(event, document.id)}>
                                {document.url && document.url.length !== 0 ? truncateMiddle(document.url) : ''}
                            </td> */}
                            <td>
                            <button
                                type="submit"
                                className="btn btn-lg btn-block btn-primary waves-effect form-btn"
                                onClick={async (event) => this.getDowload(event, document.id)}
                                disabled={ false }
                            >
                                Download</button>
                            </td>

                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_DESCRIPCION)}
                    columnName={'description'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'upload'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_EXPLOTACION)}
                    columnName={'exploitation'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_URL)}
                    columnName={''}
                    {...commonProps}
                />
            </tr>
        )
    }
}