import React, {Component, ReactNode} from "react";
import {BaseModalProps} from "../../base/modal/BaseModal";
import {Modal} from "react-bootstrap";
import DocumentFormAdmin, {DocumentFormAdminData} from "../../modules/admin/documents/form/DocumentFormAdmin";

interface DocumentFormModalProps extends BaseModalProps{
    title: string,
    onSubmit: (data: DocumentFormAdminData) => void,
    initialValues?: Partial<DocumentFormAdminData>,
}

export default class DocumentFormModal extends Component<DocumentFormModalProps> {
    public render(): ReactNode {
        const {onClose, show, title, onSubmit, initialValues} = this.props;

        return (
            <Modal onHide={onClose} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DocumentFormAdmin
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}
 