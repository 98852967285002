import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import User from "../../model/User";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";

export default class TaskUserDetail extends BaseGenericRequest<{}, TaskResponse<User>> {

    public constructor(userId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_USER_DETAIL, {uuid: userId}));
    }
}
