import * as React from 'react';
import {WrappedFieldArrayProps} from "redux-form";
import {SortableContainer, SortEndHandler} from 'react-sortable-hoc';
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ANADIR_NUEVO_CAMPO
} from "../../../../I18n/constants";
import Row from "../../../../components/Row";
import Button from "../../../../components/Button";
import Col from "../../../../components/Col";
import {FormFieldTypeValue} from "../../../../model/FormFieldType";
import FormFieldCreator, {FormFieldCreatorFormData} from "./FormFieldCreator";


type Props = WrappedFieldArrayProps<FormFieldCreatorFormData>;

class FormFieldCreatorFieldArray extends React.Component<Props> {

    public render(): React.ReactNode {
        const {fields, meta} = this.props;

        return (
            <div>
                {fields.map((fieldName: string, index: number) => (<FormFieldCreator key={`field-${index}`}
                                                                                     field={fields.get(index)}
                                                                                     index={index}
                                                                                     fieldIndex={index}
                                                                                     fieldName={fieldName}
                                                                                     fields={fields}
                                                                                     meta={meta}

                />))}
                <Row className={'m-t-10'}>
                    <Col md={9} lg={9}/>
                    <Col md={3} lg={3}>
                        <Button
                            text={I18nUtils.tr(TR_ANADIR_NUEVO_CAMPO)}
                            onClick={() => {
                                fields.push({type: FormFieldTypeValue.NONE} as FormFieldCreatorFormData);
                            }}
                            block={true}
                            className={'btn-lg btn-primary'}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

const FormFieldCreatorFieldArraySortable = SortableContainer(FormFieldCreatorFieldArray);

export default class FormFieldCreatorFieldArrayCnt extends React.Component<Props> {

    private onSortEnd: SortEndHandler = (sort) => {
        const {fields} = this.props;
        if (sort.newIndex !== sort.oldIndex) {
            fields.swap(sort.oldIndex, sort.newIndex);
        }
    };

    public render() {
        const {fields, meta} = this.props;
        return (<FormFieldCreatorFieldArraySortable fields={fields}
                                                    meta={meta}
                                                    onSortEnd={this.onSortEnd}
                                                    useDragHandle
        />)
    }
}

