import * as React from 'react';
import {Field, WrappedFieldArrayProps} from "redux-form";
import {AutocompleteOption} from "../../../../components/form/FormAutocomplete";
import {UserAutocompleteBuilder, UserSimply} from "../../../../model/User";
import UserFieldArrayReducer from "./UserFieldArrayReducer";
import {connect} from "react-redux";
import FormAsyncAutocomplete from "../../../../components/form/FormAsyncAutocomplete";
import {UserAutocompleteOptions} from "./UserGroupCardFormAdmin";
import CardFieldArray from "../../../../components/card/CardFieldArray";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_USUARIOS} from "../../../../I18n/constants";
import TaskUserList from "../../../../ws/user/TaskUserList";
import Col from "../../../../components/Col";

const mapStateToProps = UserFieldArrayReducer.autoMapToProps();

type Props = WrappedFieldArrayProps<UserAutocompleteOptions> & typeof mapStateToProps;

class UserFieldArray extends React.Component<Props> {

    private onSearchUser = (search: string): void => {
        new TaskUserList({search, limit: 0}).execute();
    };

    private selectUser = (index: number, newUser: UserSimply): void => {
        this.props.fields.remove(index);
        this.props.fields.insert(index, [UserAutocompleteBuilder.buildOption(newUser)]);
    };

    public render(): React.ReactNode {

        const {fields, userListLoading, userList, meta} = this.props;

        const userOptions = userList.map(
            (user) => UserAutocompleteBuilder.buildOption(user)
        );

        return (
            <CardFieldArray fields={fields}
                            meta={meta}
                            title={''}
                            fieldsHeader={this.renderHeader()}
                            addHandler={() => fields.push([])}
            >
                {fields.map((fieldName: string, index: number) => {
                    return (
                            // @ts-ignore
                            <Field
                                key={index}
                                name={fieldName}
                                component={FormAsyncAutocomplete}
                                isLoading={userListLoading}
                                options={userOptions}
                                onSearch={(search) => this.onSearchUser(search)}
                                onSelected={(selected: Array<AutocompleteOption<UserSimply>>) => {
                                    if (selected && selected[0]) {
                                        this.selectUser(index, selected[0].data);
                                    }
                                }}
                                col={{className: 'm-b--10 m-t--15 p-r-10'}}
                                disabled={false}
                            />
                    )
                })}
            </CardFieldArray>
        )
    }

    private renderHeader = (): React.ReactNode => {
        return (
            <Col className={'m-b-0 m-l-15'}>
                <label>{I18nUtils.tr(TR_USUARIOS)}</label>
            </Col>
        )
    }
}

export default connect(mapStateToProps)(UserFieldArray) as unknown as React.ComponentType<any>;