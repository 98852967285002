import * as React from 'react';
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ANADE_TUS_DOCUMENTOS_FAVORITOS,
    TR_DOCUMENTACION,
    TR_NO_EXISTEN_DOCUMENTOS,
} from "../../../I18n/constants";
import DocumentListCardAdminReducer from "../../admin/documents/list/DocumentListCardAdminReducer";
import DocumentListAdminScreenActions from "../../admin/documents/list/DocumentListcardAdminActions";
import CardList, {EmptyListOptions} from "../../../components/card/CardList";
import TaskDocumentList from "../../../ws/document/TaskDocumentList";
import DocumentationListTable from "./DocumentationListTable";
import {goToRoute} from "../../../utils/Router";
import {ROUTE_ADMIN_DOCUMENT_ADD, ROUTE_DOCUMENTATION} from "../../../routing/Routes";
import DocumentationListFilter from "./listFilters/DocumentationListFilter";
import {connect} from "react-redux";

const mapStateToProps = DocumentListCardAdminReducer.autoMapToProps();
const mapActionsToProps = DocumentListAdminScreenActions.autoMapToProps();

interface DocumentationListCardProps {
    showFavorites?: boolean,
}

type Props = typeof mapStateToProps & typeof mapActionsToProps & DocumentationListCardProps

class DocumentationListCard extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onAddFavorites = (): void => {
        goToRoute(ROUTE_DOCUMENTATION);
    };

    public render() {
        const {documents, error, loading, pager, showFavorites = false, onSetCriteria, criteria} = this.props;
        const emptyListOptions: EmptyListOptions = showFavorites ?
            {
                message: I18nUtils.tr(TR_ANADE_TUS_DOCUMENTOS_FAVORITOS),
                addHandler: this.onAddFavorites,
            } :
            {
                message: I18nUtils.tr(TR_NO_EXISTEN_DOCUMENTOS)
            };

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_DOCUMENTACION)}
                      sort={{column: 'name'}}
                      TaskList={TaskDocumentList}
                      emptyListOptions={emptyListOptions}
                      data={documents}
                      error={error}
                      ItemsTable={DocumentationListTable}
                      addToFavorites={!showFavorites}
                      removeFromFavorites={showFavorites}
                      customCriteria={{...criteria, fav: showFavorites}}
                      addRoute={ROUTE_ADMIN_DOCUMENT_ADD}
            >
                <DocumentationListFilter criteriaHandler={onSetCriteria}
                                         criteria={criteria}
                />
            </CardList>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(DocumentationListCard as unknown as React.ComponentType<DocumentationListCardProps>);