import * as React from 'react';
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import {Modal} from "react-bootstrap";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_NO, TR_SI} from "../../I18n/constants";
import Row from "../Row";
import Col from "../Col";
import Button from '../Button';

interface CancelModalProps extends BaseModalProps {
    title: string,
    message: string,
    onConfirmAction: () => void,
}

export default class CancelModal extends BaseModal<CancelModalProps> {

    public render(): React.ReactNode {
        const {onClose, show, title, message, onConfirmAction} = this.props;

        return (
            <Modal onHide={onClose} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col xs={0} sm={0} md={6} lg={6}/>
                        <Col sm={6} md={3} lg={3}>
                            <Button
                                text={I18nUtils.tr(TR_NO).toUpperCase()}
                                onClick={() => onClose()}
                                block={true}
                                className={'btn-lg'}
                            />
                        </Col>
                        <Col sm={6} md={3} lg={3}>
                            <Button
                                text={I18nUtils.tr(TR_SI).toUpperCase()}
                                onClick={() => {
                                    onConfirmAction();
                                    onClose();
                                }}
                                block={true}
                                className={'btn-lg btn-primary'}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}