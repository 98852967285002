import * as React from 'react';
import {ROUTE_ADMIN_EXPLOITATIONS} from "../../../../routing/Routes";
import ZoneListCardAdmin from "./ExploitationListCardAdmin";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_EXPLOTACIONES} from "../../../../I18n/constants";
import {MenuIcon} from "../../../../Config";
import ScreenCard from '../../../../components/screen/ScreenCard';

export default class ExploitationListAdminScreen extends React.Component<{}> {

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_EXPLOTACIONES),
                    url: ROUTE_ADMIN_EXPLOITATIONS,
                    icon: MenuIcon.EXPLOITATION,
                },
            ]}>
                <ZoneListCardAdmin/>
            </ScreenCard>
        )
    }
}
