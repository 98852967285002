import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import FormModel from "../../model/Form";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";

export default class TaskFormDetail extends BaseGenericRequest<{}, TaskResponse<FormModel>> {

    public constructor(formId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_FORM_DETAIL, {uuid: formId}))
    }
}