import * as React from 'react';
import {ROUTE_ADMIN_ZONES} from "../../../../routing/Routes";
import ZoneListCardAdmin from "./ZoneListCardAdmin";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ZONAS} from "../../../../I18n/constants";
import {MenuIcon} from "../../../../Config";
import ScreenCard from '../../../../components/screen/ScreenCard';

export default class ZoneListAdminScreen extends React.Component<{}> {

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_ZONAS),
                    url: ROUTE_ADMIN_ZONES,
                    icon: MenuIcon.ZONE,
                },
            ]}>
                <ZoneListCardAdmin/>
            </ScreenCard>
        )
    }
}