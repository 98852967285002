import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {generateRoute} from "../../utils/Router";
import {AlertNotificationUser, AlertNotificationUserSolveRequest} from "../../model/AlertNotificationUser";

export default class TaskAlertAdd extends BaseGenericRequest<AlertNotificationUserSolveRequest, TaskResponse<AlertNotificationUser>> {

    private readonly data: AlertNotificationUserSolveRequest;

    public constructor(data: AlertNotificationUserSolveRequest, uuid: string) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_ALERT_NOTIFICATION_USER_SOLVE, {uuid}));
        this.data = data;
    }

    protected getRequest(): AlertNotificationUserSolveRequest {
        return this.data;
    }
}