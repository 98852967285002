import * as React from 'react';
import {Component, ReactNode, ReactNodeArray} from 'react';
import {DashboardDetail, DashboardDetailFilterData} from "../../../model/Dashboard";
import Col from "../../../components/Col";
import Select from "../../../components/Select";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_FECHA, TR_LIMPIAR_FILTROS, TR_TIPO_DE_EXPLOTACION, TR_ZONA} from "../../../I18n/constants";
import FormInputOption from "../../../components/form/FormInputOption";
import DateRangeInput from "../../../components/date/DateRangeInput";
import Button from "../../../components/Button";

export interface DashboardFilterProps {
    filter: DashboardDetail['filter'],
    onFilter: (request: DashboardDetailFilterData) => void,
}

export default class DashboardFilter extends Component<DashboardFilterProps> {

    private buildFilter(data: DashboardDetailFilterData): DashboardDetailFilterData {
        const {filter} = this.props;
        const current: DashboardDetailFilterData = {
            zone: filter.zone.value || undefined,
            business_type: filter.business_type.value || undefined,
            start_time: filter.time.value.start || undefined,
            end_time: filter.time.value.end || undefined,
        };
        return {
            ...current,
            ...data,
        };
    }

    public render(): ReactNodeArray {
        return [
            this.renderZoneFilter(),
            this.renderBusinessTypeFilter(),
            this.renderTimeFilter(),
            this.renderClearFilter(),
        ];
    }

    private renderZoneFilter(): ReactNode {
        const {filter} = this.props;
        if (!filter || !filter.zone || !filter.zone.enabled) {
            return null;
        }
        const options: FormInputOption[] = filter.zone.options.map((option) => ({value: option, name: option}));
        return (
            <Col lg={3} md={3} key={1}>
                <Select
                    label={I18nUtils.tr(TR_ZONA)}
                    value={filter.zone.value || ''}
                    options={options}
                    onChange={(e) => this.props.onFilter(this.buildFilter({zone: e.target.value}))}
                />
            </Col>
        )
    }

    private renderBusinessTypeFilter(): ReactNode {
        const {filter} = this.props;
        if (!filter || !filter.business_type || !filter.business_type.enabled) {
            return null;
        }
        const options: FormInputOption[] = filter.business_type.options.map((option) => ({value: option, name: option}));
        return (
            <Col lg={3} md={3} key={2}>
                <Select
                    label={I18nUtils.tr(TR_TIPO_DE_EXPLOTACION)}
                    value={filter.zone.value || ''}
                    options={options}
                    onChange={(e) => this.props.onFilter(this.buildFilter({business_type: e.target.value}))}
                />
            </Col>
        )
    }

    private renderTimeFilter(): ReactNode {
        const {filter} = this.props;
        if (!filter || !filter.time || !filter.time.enabled) {
            return null;
        }
        return (
            <Col lg={3} md={3} key={3}>
                <div className={'form-group'}>
                    <div className={'form-line'}>
                        <label>{I18nUtils.tr(TR_FECHA)}</label>
                        <DateRangeInput
                            startDate={filter.time.value.start}
                            endDate={filter.time.value.end}
                            onChange={(startDate, endDate) => this.props.onFilter(this.buildFilter({start_time: startDate, end_time: endDate}))}
                        />
                    </div>
                </div>
            </Col>
        )
    }

    private renderClearFilter(): ReactNode {
        // const {filter} = this.props;
        return (
            <Col lg={3} md={3} key={4} className={'pull-right m-t-40'}>
                <Button
                    text={I18nUtils.tr(TR_LIMPIAR_FILTROS)}
                    onClick={() => this.props.onFilter({})}
                    className={'btn-primary'}
                    block={true}
                />
            </Col>
        )
    }

}

