import * as React from 'react';
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import {Modal} from "react-bootstrap";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_CANCELAR, TR_GUARDAR, TR_SALIR_TODOS_MODOS} from "../../I18n/constants";
import Row from "../Row";
import Col from "../Col";
import Button from '../Button';

interface ExitFormModalProps extends BaseModalProps {
    title: string,
    message: string,
    onConfirmAction: () => void,
    onExit: () => void,
}

export default class ExitFormModal extends BaseModal<ExitFormModalProps> {

    public render(): React.ReactNode {
        const {onClose, show, title, message, onConfirmAction, onExit} = this.props;

        return (
            <Modal onHide={onClose} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col sm={3} md={3} lg={3}>
                            <Button
                                text={I18nUtils.tr(TR_CANCELAR).toUpperCase()}
                                onClick={() => onClose()}
                                block={true}
                                className={'btn-lg btn-default'}
                            />
                        </Col>
                        <Col sm={5} md={5} lg={5}>
                        <Button
                                text={I18nUtils.tr(TR_SALIR_TODOS_MODOS).toUpperCase()}
                                onClick={() => {
                                    onExit();
                                    onClose();
                                }}
                                block={true}
                                className={'btn-lg'}
                            />
                        </Col>
                        <Col sm={4} md={4} lg={4}>
                            <Button
                                text={I18nUtils.tr(TR_GUARDAR).toUpperCase()}
                                onClick={() => {
                                    onConfirmAction();
                                    onClose();
                                }}
                                block={true}
                                className={'btn-lg btn-primary'}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}