import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {FavoriteData, FavoriteEntity} from "../../model/Favorite";

export default class TaskFavoriteRemove extends BaseGenericRequest<FavoriteData, TaskResponse<{}>> {

    private readonly data: FavoriteData;

    public constructor(itemsIds: string[], favoriteEntity: FavoriteEntity) {
        super(METHOD.METHOD_POST, Urls.URL_FAVORITE_REMOVE);
        this.data = {
            dashboards: [],
            forms: [],
            documents: [],
        };

        this.data[favoriteEntity] = itemsIds;
    }

    protected getRequest(): FavoriteData {
        return this.data;
    }
}