import * as React from "react";
import {goToRoute} from "../../../../utils/Router";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../I18n/I18nUtils";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import Log, {ChangeLog, getEntityDetailsFromLog} from "../../../../model/Log";
import {
    TR_ENTIDAD,
    TR_FECHA, TR_INFORMACION,
    TR_TIPO
} from "../../../../I18n/constants";
import {Link} from "react-router-dom";
import {MenuIcon} from "../../../../Config";
import ChangeLogsModal from "../../../../components/modal/ChangeLogsModal";

interface DocumentListTableProps {
    data: Log[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

interface LogUserTableState {
    showModal: boolean,
    changedLogs: Array<ChangeLog>,
    logDate: string
}

export default class LogUserTable extends React.Component<DocumentListTableProps, LogUserTableState> {

    constructor(props: Readonly<DocumentListTableProps>) {
        super(props);
        this.state = {
            showModal: false,
            changedLogs: [],
            logDate: ''
        }
    }

    private handleFetchChangeLogInfo(index: number): void {
        const changedLogs: ChangeLog [] = [];

        this.props.data[index].change_logs.forEach(changeLog => {
            changedLogs.push(changeLog);
        })

        this.setState({showModal: true, changedLogs, logDate: changedLogs[0].date});
    }

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;
        return (
            <>
                <table className="table table-hover">
                    <thead>{this.renderTH()}</thead>
                    <tfoot>{this.renderTH()}</tfoot>
                    <tbody>
                    {data && data.map((log: Log, index) => {
                        const currentEntity = getEntityDetailsFromLog(log);
                        const entityRoute = currentEntity && currentEntity.getItemRoute(log.entity_id);

                        return (
                            <tr key={log.id}
                                className={"tr-cursor"}
                            >
                                {renderSelectTd ? renderSelectTd(log.id) : null}

                                <td onClick={() => entityRoute && goToRoute(entityRoute)}>
                                    {new Date(log.date).toLocaleString()}
                                </td>

                                <td onClick={() => entityRoute && goToRoute(entityRoute)}>
                                    {log.log_type.name}
                                </td>

                                <td onClick={() => entityRoute && goToRoute(entityRoute)}>
                                    { currentEntity && entityRoute ?
                                        <Link to={entityRoute} onClick={(event) => event.stopPropagation()}>
                                            {currentEntity.name}
                                        </Link> : '--'}
                                </td>

                                {
                                    Array.isArray(log.change_logs) && log.change_logs.length > 0 ?
                                        <td className={"changed-log"}
                                            onClick={() => this.handleFetchChangeLogInfo(index)}
                                        >
                                            <i className={"material-icons"} style={{
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                                {MenuIcon.ARROWS}
                                            </i>
                                        </td>

                                        :

                                        <td></td>
                                }
                            </tr>
                        );
                    })}
                    </tbody>
                </table>

                <ChangeLogsModal
                    date={this.state.logDate}
                    changedLogs={this.state.changedLogs}
                    show={this.state.showModal}
                    onClose={() => this.setState({showModal: false})}
                />

            </>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_TIPO)}
                    columnName={'type'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_ENTIDAD)}
                    columnName={'entity_id'}
                />
                <Th text={I18nUtils.tr(TR_INFORMACION)}
                    columnName={'entity_id'}
                    center={true}
                />
            </tr>
        )
    }
}