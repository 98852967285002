import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";
import {METHOD} from "co-generic-request";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import {CompanyType} from "../../model/CompanyType";


export type CompanyTypeCriteria = TaskCriteriaRequest<{}>

export default class TaskCompanyTypeList extends BaseGenericRequest<CompanyTypeCriteria, TaskResponse<CompanyType[]>> {

    private readonly data: CompanyTypeCriteria;

    public constructor(criteria: CompanyTypeCriteria = {}) {
        super(METHOD.METHOD_POST, Urls.URL_COMPANY_TYPE_LIST);
        this.data = criteria;
    }

    protected getRequest(): CompanyTypeCriteria {
        return this.data;
    }
}
