import * as React from 'react';
import {RouteComponentProps} from "react-router";
import FormResultAllFormScreenActions from "./FormResultAllFormScreenActions";
import FormResultAllFormScreenReducer from "./FormResultAllFormScreenReducer";
import TaskFormResultDetail from "../../../ws/formResult/TaskFormResultDetail";
import I18nUtils from "../../../I18n/I18nUtils";
import {MenuIcon} from "../../../Config";
import {TR_RESULTADO_DE_FORMULARIO, TR_RESULTADOS_DE_FORMULARIOS} from "../../../I18n/constants";
import {ROUTE_FORM_RESULT, ROUTE_FORM_RESULT_ALL_DETAIL, ROUTE_FORM_RESULT_ALL_LIST} from "../../../routing/Routes";
import {loadItemDetailsOrGoBack} from "../../../utils/FormUtils";
import ScreenCard from "../../../components/screen/ScreenCard";
import {generateRoute} from "../../../utils/Router";
import {BreadcrumbItem} from "../../../components/breadcrumb/BreadcrumbManager";
import FormResultAllCardForm, {FormResultAllCardFormData} from "../formdetail/FormResultAllCardForm";
import {connect} from "react-redux";

const mapStateToProps = FormResultAllFormScreenReducer.autoMapToProps();
const mapActionsToProps = FormResultAllFormScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps & RouteComponentProps<{ formId: string, id: string }>;

class FormResultAllFormScreen extends React.Component<Props> {

    public componentDidMount(): void {
        const formResultId = this.props.match.params.id;
        const formId = this.props.match.params.formId;

        if (formResultId && formId) {
            loadItemDetailsOrGoBack(new TaskFormResultDetail(formResultId, formId), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearFormResultFormScreenReducer();
    }

    public render(): React.ReactNode {
        const formResultId = this.props.match.params.id;
        const formId = this.props.match.params.formId;

        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_RESULTADOS_DE_FORMULARIOS),
            url: ROUTE_FORM_RESULT_ALL_LIST,
            icon: MenuIcon.FORM
        }];
        if (formResultId) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_RESULTADO_DE_FORMULARIO),
                url: generateRoute(ROUTE_FORM_RESULT_ALL_DETAIL, {formId: formId, id: formResultId}),
                icon: MenuIcon.USER
            });
        }
        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderFormResultForm()}
            </ScreenCard>
        );

    }

    private renderFormResultForm(): React.ReactNode {
        const {formResultLoading, formResult} = this.props;

        const initialValues: FormResultAllCardFormData = {
            description: formResult && formResult.form ? formResult.form.description : '',
            formLink: formResult && formResult.form ? generateRoute(ROUTE_FORM_RESULT, {formId: formResult.form_id}) : '',
            exploitation: formResult && formResult.exploitation ? formResult.exploitation.name : '',
            name: formResult && formResult.form ? formResult.form.name : '',
            updateDate: formResult && formResult.datetime && formResult.datetime.length !== 0 ?
                new Date(formResult.datetime).toLocaleString() : '',
            user: formResult && formResult.user ? `${formResult.user.name} ${formResult.user.last_name}` : '',
        };

        return (
            <FormResultAllCardForm parentLoading={formResultLoading}
                                   initialValues={initialValues}
            />)
    }
}

export default connect(mapStateToProps, mapActionsToProps)(FormResultAllFormScreen as unknown as React.ComponentType<RouteComponentProps<{ id: string }>>);
